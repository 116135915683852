import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import { API, API_ROUTER } from "../../api/index";
import { useSelector } from "react-redux";

import s from "../../comon/ComonAdmin.module.scss";
//обнуляющие стили
import "../../comon/_null_style.css";

//компоненты, из которых состоит страница
import ProfilePanel from "../../components/AdminComponents/ProfilePanelComponent/index";
import HeaderAdmin from "../../components/AdminComponents/header-admin";
import Item from "../../components/AdminComponents/itemComponent/itemComponent";
import InfoPanel from "../../components/AdminComponents/InfoPanel/index";
import Paginator from "../../comon/Paginator";
import { addSpinner, removeSpinner } from "../../redux/actions";

//const
import { pageInfo } from "./const";
import {
  profileInfo,
  chatInfo,
  pageMenu,
  itemConstArray,
  btnTournamentsObj,
  setPaginatorDate,
} from "../../comon/comonConst";
import { FormattedMessage, useIntl } from "react-intl";
import { DateInput } from "../../components/UI";
import moment from "moment";

const TournamentsPage = (props) => {
  const dispatch = useDispatch();

  const limit = 10;
  const [itemArray, setItemArray] = useState([]);
  const [pages, setPages] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [communities, setCommunities] = useState([]);
  const [events, setEvents] = useState([]);
  const [options, setOptions] = useState([]);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [tournamentNameFilter, setTournamentNameFilter] = useState("");
  const [tournamentDateStartFilter, setTournamentDateStartFilter] = useState();

  const userData = useSelector((state) => state.userData);

  const getTournamentsData = (page = 1, limit = 10) => {
    dispatch(addSpinner());
    const urlParams = {};
    if (tournamentNameFilter) {
      urlParams["name"] = tournamentNameFilter;
    }

    if (tournamentDateStartFilter) {
      urlParams["startedFromDate"] = moment(tournamentDateStartFilter).format(
        "YYYY-MM-DD"
      );
    }
    let params = {
      ...API_ROUTER.Tournaments.getTournaments,
      pathKeys: {
        page: page,
        limit: limit,
      },
      urlParams,
    };

    API.request(params)
      .then((res) => {
        console.log(res);
        setItemArray(res.tournaments || itemConstArray);

        setPaginatorDate(
          res.paginator.lastPage,
          res.paginator.currentPage,
          setPagesCount,
          setPages,
          setCurrentPage
        );
        dispatch(removeSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeSpinner());
      });
  };

  useEffect(() => {
    getTournamentsData(sessionStorage.getItem("tournamentPage") || 1, limit);
  }, [tournamentDateStartFilter, tournamentNameFilter]);

  const getFilteredData = (page = 1, limit = 10) => {
    dispatch(addSpinner());
    let params;
    if (selectedItem?.label.includes("Community")) {
      params = {
        ...API_ROUTER.community.getCommunityTournaments,
        pathKeys: {
          page: page,
          limit: limit,
          communityId: selectedItem?.value,
        },
      };
    }

    if (selectedItem?.label.includes("Event")) {
      params = {
        ...API_ROUTER.events.getTournamentsByEvent,
        pathKeys: {
          page: page,
          limit: limit,
          eventId: selectedItem?.value,
        },
      };
    }

    API.request(params)
      .then((res) => {
        setItemArray(res.tournaments || itemConstArray);

        setPaginatorDate(
          res.paginator.lastPage,
          res.paginator.currentPage,
          setPagesCount,
          setPages,
          setCurrentPage
        );
        dispatch(removeSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeSpinner());
      });
  };

  useEffect(() => {
    getFilteredData(sessionStorage.getItem("tournamentPage") || 1, 10);
  }, [selectedItem?.value]);

  useEffect(() => {
    if (userData?.id) {
      const params = {
        ...API_ROUTER.userAdmin.getAccessRights,
        pathKeys: {
          userId: userData.id,
        },
      };
      API.request(params)
        .then((res) => {
          setCommunities(res.communities);
          setEvents(res.events);
        })
        .catch((err) => console.log(err));
    }
  }, [userData?.id]);

  const buildCommunityDependencyArr = (communities, events) => {
    const arr = communities.map((item) => {
      const communityObj = {
        community: item,
      };
      events.forEach((eventItem) => {
        if (item.communityId === eventItem.communityId) {
          communityObj.event = eventItem;
        }
      });
      return communityObj;
    });
    setOptions(arr);
    const createGroupedOptions = (options) => {
      const groupedOptions = options.map((item) => {
        const options = [];
        options.push({
          value: item.community.communityId,
          label: `Community: ${item.community.communityName}`,
          margin: 0,
        });
        if (item.event) {
          options.push({
            value: item.event.eventId,
            label: `Event: ${item.event.eventName}`,
            margin: 10,
          });
        }
        return {
          label: item.community.communityName,
          options,
        };
      });
      return groupedOptions;
    };
    setGroupedOptions(createGroupedOptions(arr));
  };

  useEffect(() => {
    buildCommunityDependencyArr(communities, events);
  }, [communities, events]);

  return (
    <div className={s.evensPageContainer}>
      <ProfilePanel pageInfo={pageInfo} profileInfo={profileInfo} />
      <ContentContainer MainBlock={true} MainCreateBlock={null}>
        <HeaderAdmin
          pageName={pageInfo.pageName}
          toROUTER={pageInfo.toROUTER}
          addBtn={pageInfo.addBtn}
          // periodContainer={pageInfo.periodContainer}
          btnInfo={pageInfo?.btnInfo}
          // pageMenu={pageMenu}
          filter={pageInfo.filter}
          select="tournament"
          setSelect={setSelectedItem}
          selectOptions={groupedOptions}
        />

        <div
          className={s.row}
          style={{ display: selectedItem?.value ? "none" : "flex" }}
        >
          <div className={s.inputContainer}>
            <div className={s.titleInput}>
              <FormattedMessage id="community.search.byName" />
            </div>
            <input
              type="text"
              placeholder={useIntl().formatMessage({
                id: "global.forms.labels.name",
              })}
              value={tournamentNameFilter}
              onChange={(e) => setTournamentNameFilter(e.target.value)}
            />
          </div>
          <div className={s.inputContainer}>
            <div className={s.titleInput}>
              <FormattedMessage id="search.ByDateStart" />
            </div>
            <DateInput
              onChange={setTournamentDateStartFilter}
              selected={tournamentDateStartFilter}
            />
          </div>
        </div>
        <div className={s.mainScrollContainer}>
          <div className={s.scrollContainer}>
            {itemArray?.map((u, i) => (
              <Item
                key={i}
                id={u.id}
                name={u.name}
                status={true}
                startedAt={u.startedAt}
                endedAt={u.endedAt}
                btnObj={btnTournamentsObj}
                hiddenBtn={btnTournamentsObj.hiddenBtn}
                domain={u.domain}
                canEdit={u.registrationEndedAt}
              />
            ))}
          </div>

          {!!pagesCount && (
            <Paginator
              limit={limit}
              pages={pages}
              getFn={selectedItem?.label ? getFilteredData : getTournamentsData}
              pagesCount={pagesCount}
              currentPage={currentPage}
              component="tournamentPage"
            />
          )}
        </div>
        {!itemArray.length && (
          <div style={{ marginBottom: 200, marginTop: 60 }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 20,
              }}
            >
              No tournaments belong to the selected option
            </p>
          </div>
        )}
      </ContentContainer>
      {!chatInfo && <InfoPanel chatInfo={chatInfo} />}
    </div>
  );
};
export default TournamentsPage;
