import React from "react";

const iconInfo = () => (
    <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.99994 0.999939C3.99994 1.55219 3.55225 1.99988 3 1.99988C2.44775 1.99988 2.00006 1.55219 2.00006 0.999939C2.00006 0.447693 2.44775 0 3 0C3.55225 0 3.99994 0.447693 3.99994 0.999939Z" fill="#201941"/>
        <path d="M3 13.9999C2.586 13.9999 2.25 13.6639 2.25 13.2499V5.5H1.25006C0.83606 5.5 0.500061 5.164 0.500061 4.75C0.500061 4.336 0.83606 4 1.25006 4H3C3.414 4 3.75 4.336 3.75 4.75V13.2499C3.75 13.6639 3.414 13.9999 3 13.9999Z" fill="#201941"/>
        <path d="M5.25 14H0.75C0.335999 14 0 13.664 0 13.25C0 12.836 0.335999 12.5 0.75 12.5H5.25C5.664 12.5 6 12.836 6 13.25C6 13.664 5.664 14 5.25 14Z" fill="#201941"/>
    </svg>
);

export default iconInfo;