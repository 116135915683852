// auth
export const USER_ONLINE = "USER_ONLINE";
export const USER_OFFLINE = "USER_OFFLINE";
export const SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL";
export const HIDE_AUTH_MODAL = "HIDE_AUTH_MODAL";
export const SHOW_REGISTER_MODAL = "SHOW_REGISTER_MODAL";
export const HIDE_REGISTER_MODAL = "HIDE_REGISTER_MODAL";
export const SHOW_AUTH_BLOCKED = "SHOW_AUTH_BLOCKED";
export const HIDE_AUTH_BLOCKED = "HIDE_AUTH_BLOCKED";

// notification modal
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const HIDE_NOTIFICATION_MODAL = "HIDE_NOTIFICATION_MODAL";

// steam data
export const SET_STEAM_DATA = "SET_STEAM_DATA";

// interface language
export const SET_INTERFACE_LANGUAGE = "SET_INTERFACE_LANGUAGE";

// messenger
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const PRESET_CHAT_MESSAGE = "PRESET_CHAT_MESSAGE";

// spinner
export const ADD_SPINNER = "ADD_SPINNER";
export const REMOVE_SPINNER = "REMOVE_SPINNER";

// user
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";

// labels
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PLATFORMS = "SET_PLATFORMS";
export const SET_DEVICES = "SET_DEVICES";
export const SET_LANGUAGES = "SET_LANGUAGES";
// scroll
export const SCROLL_TO = "SCROLL_TO";

// events data
export const SET_EVENTS_DATA = "SET_EVENTS_DATA";
