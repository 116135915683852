import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";
import Select from 'react-select';
import { addSpinner, showNotificationModal, removeSpinner } from '../../redux/actions';

import { API, API_ROUTER } from '../../api/index';

import s from '../../components/AdminComponents/CreateEvent/createEvent.module.scss';

const SITES = [
    { label: 'Passport', value: 'passport' },
    { label: 'Hogasia', value: 'hogasia' },
    { label: 'Africa', value: 'africa' },
]

const GiveAccessForm = () => {
    const { control, reset, register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch()

    const giveAccessToSite = (submitData) => {
        const data = {
            userId: submitData.user,
            site: submitData.site.value
        }
        let params = {
            ...API_ROUTER.userAdmin.setSiteAccess,
            data
        };
        dispatch(addSpinner())
        API.request(params)
            .then((res) => {
                dispatch(removeSpinner())
                dispatch(showNotificationModal(`User ${submitData.user} has been given access to ${submitData.site.value}`))
                reset()
            })
            .catch((err) => {
                dispatch(removeSpinner())
                const errors = []
                for (let key in err.data.errors) {
                    errors.push(`${key}: ${err.data.errors[key]}`)
                }
                dispatch(showNotificationModal(`Some errors occured: ${errors.join(', ')}`))
            });
    }

    return (
        <form onSubmit={handleSubmit(giveAccessToSite)}>
            <p style={{
                marginBottom: 42, fontSize: 24,
                fontWeight: 500
            }}>Give access to admin site</p>
            <div className={s.inputContainer} style={{ width: 322, marginBottom: 10 }}>
                <div className={s.text} style={{ marginRight: 10 }}>
                    <FormattedMessage id="roleManagement.title.user" tagName="span" />
                </div>
                <input
                    className={errors.user ? s.inputErr : ""}
                    {...register("user")}
                    type="text"
                    placeholder='User id'
                />
            </div>
            <div className={s.inputContainer} style={{ width: 322, marginBottom: 30 }}>
                <div className={s.text} style={{ marginRight: 10 }}>
                    <span>Site</span>
                </div>
                <div className={s.select}>
                    <Controller
                        name="site"
                        control={control}
                        rules={{ required: true }}
                        render={({
                            field
                        }) => <Select
                                onChange={(e) => {
                                    field.onChange(e)
                                }}
                                classNamePrefix='custom_select'
                                options={SITES}
                                placeholder='Select site'
                                isMulti={false}
                            />}
                    />
                </div>
            </div>
            <button type='submit'>Give access</button>
        </form>
    )
}

export default GiveAccessForm