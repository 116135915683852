import React from "react";
import s from '../../comon/ComonAdmin.module.scss';

import { FormattedMessage } from "react-intl";

export const pageInfo = {
    pageName: <FormattedMessage id="rolesManagement.title.pageName" tagName="span" />,
    filter: false,
    periodContainer: false,
    addBtn: <FormattedMessage id="roleManagement.btn.addBtn" tagName="span" />,
    toROUTER: 'addNewPerson',
}

export const titleHeader = [
    <FormattedMessage id="roleManagement.title.you" tagName="span" />,
    <FormattedMessage id="roleManagement.title.admin" tagName="span" />,
    <FormattedMessage id="roleManagement.title.manager" tagName="span" />,
    <FormattedMessage id="roleManagement.title.referee" tagName="span" />,
    <FormattedMessage id="roleManagement.title.limit" tagName="span" />,
    <FormattedMessage id="roleManagement.title.delete" tagName="span" />,
];

export const headerItem = {
    "rows": [
        {
            "yourRole": "Loading...",
            "admin": "Loading...",
            "manager": "Loading...",
            "referee": "Loading...",
            "edit": "Loading...",
            "delete": "Loading..."
        },
    ]
}