import React, { Component } from "react";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { Helmet } from "react-helmet";
import { isHOGDomain } from "./comon/isHOGDomain";

import store from "./redux/store";
import theme from "./material-theme";

import {
  getUserData,
  userOnline,
  showAuthModal,
  setInterfaceLang,
  getUserNotifications,
  showRegisterModal,
  getLanguages,
  getCountries,
} from "./redux/actions";
import { getUrlParams, isAuthenticated, ScrollToTop } from "./helpers";
import Header from "./components/header";
import AuthModal from "./components/auth-modal";
import Main from "./components/main";
import Footer from "./components/footer";
import "./App.scss";

import { translations } from "./i18n";
import { INTERFACE_LANGUAGES } from "./config";
import classNames from "classnames";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: filter 0.3s ease;
  //  background: ${(props) =>
    props.hog &&
    "linear-gradient(110deg, rgba(38, 34, 35, 1) 0% rgba(191, 140, 44, 1) 100%)"};

  &.onAuth {
    filter: blur(10px);
  }
`;

class App extends Component {
  state = {
    authModalShown: false,
  };

  componentDidMount() {
    const { login, lang, register } = getUrlParams();

    // clear team invite code
    localStorage.removeItem("team-invite");

    // show login popup
    if (login) {
      store.dispatch(showAuthModal());
    }

    // show register
    if (register) {
      store.dispatch(showRegisterModal());
      store.dispatch(showAuthModal());
    }

    // switch lang
    if (lang && INTERFACE_LANGUAGES.indexOf(lang) !== -1) {
      localStorage.setItem("interfaceLang", lang);
      store.dispatch(setInterfaceLang(lang));
    }

    // start notifications

    // get country list
    // store.dispatch(getCountries());
    // store.dispatch(getDevices());
    store.dispatch(getCountries());
    // store.dispatch(getDevices());
    store.dispatch(getLanguages());
    // subscribe store
    store.subscribe(this.handleStoreChange);

    // get base data if logged in
    if (isAuthenticated()) {
      store.dispatch(getUserData());
      store.dispatch(userOnline());
    }
  }

  handleStoreChange = () =>
    this.setState({
      authModalShown: store.getState().showAuthModal,
    });

  render() {
    const { authModalShown } = this.state;
    const { interfaceLang } = store.getState();
    let selectedLang = localStorage.getItem("interfaceLang") || "en";

    // check available lang
    if (Object.keys(translations).indexOf(selectedLang) === -1) {
      selectedLang = "en";
    }

    const body = document.getElementsByTagName("body")[0];
    if (isHOGDomain()) {
      body.style.background = "#000000";
    }

    const backgroungColor = () => {
      if (isHOGDomain() && !isAuthenticated()) {
        return "linear-gradient(110deg, rgba(38, 34, 35, 1) 0%, rgba(191, 140, 44, 1) 100%)";
      } else if (isHOGDomain() && isAuthenticated) {
        return "#000000";
      } else {
        return "#3F317C";
      }
    };

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            locale={selectedLang}
            defaultLocale={selectedLang}
            key={selectedLang}
            messages={translations[selectedLang]}
          >
            <Router>
              <ScrollToTop>
                <StyledApp
                  style={{
                    background: backgroungColor(),
                  }}
                  hog={isHOGDomain()}
                  className={authModalShown ? "onAuth" : ""}
                >
                  <Helmet>
                    <title>
                      {translations[selectedLang]
                        ? translations[selectedLang]["meta.title"]
                        : ""}
                    </title>
                  </Helmet>
                  <Header />
                  <div className={classNames({ hogasia: isHOGDomain() })}>
                    <Main />
                  </div>
                  <Footer />
                </StyledApp>
                <AuthModal />
                <ToastContainer />
              </ScrollToTop>
            </Router>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
