import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { API, API_ROUTER } from "../../../api";

import "../../../comon/CustomSelect/select.scss";

// стили для селекта
const styles = {
  container: (css) => ({ ...css, marginLeft: "10px" }),
  control: (css) => ({
    ...css,
    minHeight: "50px",
    width: "300px",
    color: "white",
  }),
  menu: (css) => ({ ...css, zIndex: 2 }),
  singleValue: (css) => ({ ...css, color: "white" }),
  groupHeading: (css) => ({ ...css, color: "orange" }),
  clearIndicator: (css) => ({ ...css, "&:hover": { color: "#be43dd" } }),
  dropdownIndicator: (css) => ({ ...css, "&:hover": { color: "orange" } }),
};

// TODO сложный селект для выбора группы по хорошему нужно переписать
const SelectGroup = ({ handleChange, selectedItem, defaultValue }) => {
  const userData = useSelector((state) => state.userData);

  const [communities, setCommunities] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [events, setEvents] = useState([]);
  const [communitySelection, setCommunitySelection] = useState([]);
  const [eventSelection, setEventSelection] = useState([]);
  const [tournamentSelection, setTounramentSelection] = useState([]);
  const [hierarchySelection, setHierarchySelection] = useState(selectedItem);
  const [hierarchySelectionCommunities, setHierarchySelectionCommunities] =
    useState([]);
  const [hierarchySelectionEvents, setHierarchySelectionEvents] = useState([]);
  const [hierarchySelectionTournaments, setHierarchySelectionTournaments] =
    useState([]);
  const [communityHierarchyPlaceholder, setCommunityHierarchyPlaceholder] =
    useState("Please, choose community to see the members...");
  const [eventHierarchyPlaceholder, setEventHierarchyPlaceholder] = useState(
    "Please, choose event to see the members..."
  );

  useEffect(() => {
    // COMMUNITIES SELECTION
    if (hierarchySelection?.type === "community") {
      // event group options
      const groupedOptionsEvent = events
        .filter((item) => {
          if (item.communityId === hierarchySelection.value) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.eventId,
            label: `Event: ${item.eventName}`,
            communityAdvanced: item.communityAdvanced,
            type: "event",
            rolesManagementType: "events",
            communityId: item.communityId,
          });
          return {
            label: item.eventName,
            options,
          };
        });
      // TOURNAMENT GROUP OPTIONS
      const groupedOptionsTournament = tournaments
        .filter((item) => {
          if (item.communityId === hierarchySelection.value) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.tournamentId,
            label: `Tournament: ${item.tournamentName}`,
            communityAdvanced: item.communityAdvanced,
            type: "tournament",
            rolesManagementType: "tournaments",

            communityId: item.communityId,
            eventId: item.eventId,
          });
          return {
            label: item.tournamentName,
            options,
          };
        });
      // COMMUNITY GROUP OPTIONS
      const groupedOptionsCommunity = communities.map((item) => {
        const options = [];
        options.push({
          value: item.communityId,
          label: `Community: ${item.communityName}`,
          communityAdvanced: item.communityAdvanced,
          type: "community",
          rolesManagementType: "communities",
        });

        return {
          label: item.communityName,
          options,
        };
      });
      // ПОДСТВЯЛЕМ СООТВЕТСТВУЮЩИЕ СЕЛЕКТЫ
      setHierarchySelectionCommunities(groupedOptionsCommunity);
      setHierarchySelectionTournaments(groupedOptionsTournament);
      setHierarchySelectionEvents(groupedOptionsEvent);
    }
    // EVENT SELECTION
    if (hierarchySelection?.type === "event") {
      // COMMUNITY PLACEHOLDER
      const communityPlaceholder = communities.find((item) => {
        if (item.communityId === hierarchySelection.communityId) {
          return true;
        }
        return false;
      });
      setCommunityHierarchyPlaceholder(
        communityPlaceholder?.communityName
          ? communityPlaceholder.communityName
          : "no community"
      );
      // TOURNAMENT GROUP OPTIONS
      const groupedOptionsTournament = tournaments
        .filter((item) => {
          if (item.eventId === hierarchySelection.value) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.tournamentId,
            label: `Tournament: ${item.tournamentName}`,
            communityAdvanced: item.communityAdvanced,
            type: "tournament",
            rolesManagementType: "tournaments",

            communityId: item.communityId,
            eventId: item.eventId,
          });
          return {
            label: item.tournamentName,
            options,
          };
        });
      // COMMUNITY GROUP OPTIONS
      const groupedOptionsCommunity = communities
        .filter((item) => {
          if (item.communityId === hierarchySelection.communityId) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.communityId,
            label: `Community: ${item.communityName}`,
            communityAdvanced: item.communityAdvanced,
            type: "community",
            rolesManagementType: "communities",
          });
          return {
            label: item.communityName,
            options,
          };
        });
      // EVENT GROUP OPTIONS
      const groupedOptionsEvent = events.map((item) => {
        const options = [];
        options.push({
          value: item.eventId,
          label: `Event: ${item.eventName}`,
          communityAdvanced: item.communityAdvanced,
          type: "event",
          rolesManagementType: "events",

          communityId: item.communityId,
        });

        return {
          label: item.eventName,
          options,
        };
      });
      // ПОДСТВЯЛЕМ СООТВЕТСТВУЮЩИЕ СЕЛЕКТЫ
      setHierarchySelectionEvents(groupedOptionsEvent);
      setHierarchySelectionCommunities(groupedOptionsCommunity);
      setHierarchySelectionTournaments(groupedOptionsTournament);
    }
    // TOURNAMENT SELECTION
    if (hierarchySelection?.type === "tournament") {
      // community group options
      const groupedOptionsCommunity = communities
        .filter((item) => {
          if (item.communityId === hierarchySelection.communityId) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.communityId,
            label: `Community: ${item.communityName}`,
            communityAdvanced: item.communityAdvanced,
            type: "community",
            rolesManagementType: "communities",
          });
          return {
            label: item.communityName,
            options,
          };
        });
      // event group options
      const groupedOptionsEvent = events
        .filter((item) => {
          if (item.eventId === hierarchySelection.eventId) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const options = [];
          options.push({
            value: item.eventId,
            label: `Event: ${item.eventName}`,
            communityAdvanced: item.communityAdvanced,
            type: "event",
            rolesManagementType: "events",

            communityId: item.communityId,
          });
          return {
            label: item.eventName,
            options,
          };
        });
      // tournament group options
      const groupedOptionsTournament = tournaments.map((item) => {
        const options = [];
        options.push({
          value: item.tournamentId,
          label: `Tournament: ${item.tournamentName}`,
          communityAdvanced: item.communityAdvanced,
          type: "tournament",
          rolesManagementType: "tournaments",

          communityId: item.communityId,
          eventId: item.eventId,
        });

        return {
          label: item.tournamentName,
          options,
        };
      });
      // ПОДСТВЯЛЕМ СООТВЕТСТВУЮЩИЕ СЕЛЕКТЫ
      setHierarchySelectionTournaments(groupedOptionsTournament);
      setHierarchySelectionEvents(groupedOptionsEvent);
      setHierarchySelectionCommunities(groupedOptionsCommunity);
      // COMMUNITY PLACEHOLDER
      const communityPlaceholder = communities.find((item) => {
        if (item.communityId === hierarchySelection.communityId) {
          return true;
        }
        return false;
      });
      setCommunityHierarchyPlaceholder(
        communityPlaceholder?.communityName
          ? communityPlaceholder.communityName
          : "no community"
      );
      // EVENT PLACEHOLDER
      const eventPlaceholder = events.find((item) => {
        if (item.eventId === hierarchySelection.eventId) {
          return true;
        }
        return false;
      });
      setEventHierarchyPlaceholder(
        eventPlaceholder?.eventName ? eventPlaceholder.eventName : "no event"
      );
    }
  }, [communities, events, hierarchySelection, tournaments]);
  // console.log(hierarchySelection, "hierarchySelection");
  // console.log(hierarchySelectionEvents, "hierarchySelectionEvents");
  // console.log(hierarchySelectionTournaments, "hierarchySelectionTournaments");
  // console.log(hierarchySelectionCommunities, "hierarchySelectionCommunities");
  // получаем данные доступа к сообществам, турнирам и событиям
  useEffect(() => {
    if (userData?.id) {
      const params = {
        ...API_ROUTER.userAdmin.getAccessRights,
        pathKeys: {
          userId: userData.id,
        },
      };
      API.request(params)
        .then((res) => {
          setCommunities(res.communities);
          setTournaments(res.tournaments);
          setEvents(res.events);
        })
        .catch((err) => console.log(err));
    }
    // задаю иерархию по умолчанию
    setHierarchySelection(selectedItem);
  }, [selectedItem, userData?.id]);

  // строим массивы для селекта
  const buildCommunityDependencyArr = (communities, events, tournaments) => {
    const groupedOptionsCommunity = communities.map((item) => {
      const options = [];
      options.push({
        value: item.communityId,
        label: `Community: ${item.communityName}`,
        communityAdvanced: item.communityAdvanced,
        type: "community",
        rolesManagementType: "communities",
      });

      return {
        label: item.communityName,
        options,
      };
    });
    setCommunitySelection(groupedOptionsCommunity);
    const groupedOptionsEvent = events.map((item) => {
      const options = [];
      options.push({
        value: item.eventId,
        label: `Event: ${item.eventName}`,
        communityAdvanced: item.communityAdvanced,
        type: "event",
        rolesManagementType: "events",

        communityId: item.communityId,
      });

      return {
        label: item.eventName,
        options,
      };
    });
    setEventSelection(groupedOptionsEvent);
    const groupedOptionsTournament = tournaments.map((item) => {
      const options = [];
      options.push({
        value: item.tournamentId,
        label: `Tournament: ${item.tournamentName}`,
        communityAdvanced: item.communityAdvanced,
        type: "tournament",
        rolesManagementType: "tournaments",

        communityId: item.communityId,
        eventId: item.eventId,
      });

      return {
        label: item.tournamentName,
        options,
      };
    });
    setTounramentSelection(groupedOptionsTournament);
  };

  useEffect(() => {
    // базовый массив для селекта (в будущем переделать и упросить код)
    buildCommunityDependencyArr(communities, events, tournaments);
  }, [communities, events, tournaments]);
  console.log(
    hierarchySelection,
    "hierarchySelection",
    hierarchySelectionCommunities,
    "hierarchySelectionCommunities",
    hierarchySelectionEvents,
    "hierarchySelectionEvents",
    hierarchySelectionTournaments,
    "hierarchySelectionTournaments"
  );
  console.log(
    communitySelection,
    "communitySelection",
    eventSelection,
    "eventSelection",
    tournamentSelection,
    "tournamentSelection"
  );
  return (
    <div style={{ display: "flex" }}>
      <Select
        // задаю значение если выбранное значение является сообществом иначе null
        value={selectedItem?.type === "community" ? selectedItem : null}
        // при изменении значения селекта вызываю функцию обработчик и задаю значение иерархии
        onChange={(e) => {
          handleChange(e);
          setHierarchySelection(e ? e : false);
        }}
        // задаю плейсхолдер в зависимости от выбранного значения иерархии только если выбран ивент или турнир
        placeholder={
          hierarchySelection?.type === "event" ||
          hierarchySelection?.type === "tournament"
            ? communityHierarchyPlaceholder
            : "Please, choose community to see the members..."
        }
        // задаю опции в зависимости от выбранного значения иерархии если иерархия не выбрана то задаю базовый массив опций
        options={
          !hierarchySelection
            ? communitySelection
            : hierarchySelectionCommunities
        }
        isClearable={true}
        classNamePrefix="custom_select"
        styles={{
          ...styles,
        }}
        defaultValue={defaultValue?.type === "community" && defaultValue}
      />
      <Select
        value={selectedItem?.type === "event" ? selectedItem : null}
        onChange={(e) => {
          handleChange(e);
          setHierarchySelection(e ? e : false);
        }}
        placeholder={
          hierarchySelection?.type === "tournament"
            ? eventHierarchyPlaceholder
            : "Please, choose event to see the members..."
        }
        options={
          !hierarchySelection ? eventSelection : hierarchySelectionEvents
        }
        isClearable={true}
        classNamePrefix="custom_select"
        styles={{
          ...styles,
        }}
        defaultValue={defaultValue?.type === "event" && defaultValue}
      />
      <Select
        value={selectedItem?.type === "tournament" ? selectedItem : null}
        onChange={(e) => {
          handleChange(e);
          setHierarchySelection(e ? e : false);
        }}
        placeholder={"Please, choose tournament to see the members..."}
        options={
          !hierarchySelection
            ? tournamentSelection
            : hierarchySelectionTournaments
        }
        isClearable={true}
        classNamePrefix="custom_select"
        styles={{
          ...styles,
        }}
        defaultValue={defaultValue?.type === "tournament" && defaultValue}
      />
    </div>
  );
};

export default SelectGroup;
