import React, { useEffect, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';
import SortableList from './SortableList';


function Sortable({ setTiebreakPriority, disabled, options }) {
    const [counter, setCounter] = useState(0)
    const [checked, setChecked] = useState([]);
    const [checkList, setCheckList] = useState([
        'The number of first places on the maps in the match',
        'The number of points only for places in the match maps',
        'Number of points only for kills in match maps',
        'The highest number of points per map in a match',
        'The highest number of points per place per map in a match',
        'The highest number of points for kills per map in a match',
        "The number of points for the last map in the match",
        "The number of points per place for the last map in the match",
        "The number of points for kills for the last map in the match",
        "Average number of points per map in a match",
        "Average number of points per place per map in a match",
        "Average number of points for kills per map in a match"
    ])

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
        setItems(updatedList);
    };

    const [items, setItems] = useState([]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(prevItem => (arrayMoveImmutable(prevItem, oldIndex, newIndex)));
        setCounter(counter + 1)
    };

    useEffect(() => {
        setTiebreakPriority(items)
    }, [items.length, counter])

    useEffect(() => {
        const filteredArr = checkList.filter(item => options.every(option => item !== option))
        setCheckList(filteredArr)
        setChecked(options)
        setItems(options)
    }, [options.length])


    return (
        <div className="App">
            {checked.length > 0 && <div style={{ marginBottom: 30 }}>
                <div style={{ marginBottom: 10, fontWeight: 500 }}>Drag to set priority</div>
                <SortableList items={items} onSortEnd={onSortEnd} />
            </div>}

            <div className="checkList">
                <div style={{ marginBottom: 10, fontWeight: 500 }}>Select items</div>
                <div className="list-container">
                    {checkList.map((item, index) => (
                        <div key={index}>
                            <input disabled={disabled} value={item} type="checkbox" onChange={handleCheck} style={{ marginRight: 10 }} />
                            <span>{item}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sortable;