import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import style from "./membersPage.module.scss";
import s from "../../components/AdminComponents/CreateEvent/createEvent.module.scss";

import { API_ROUTER, API } from "../../api";
import { default as Dropdown } from "react-select";
import SelectWithCheckboxes from "../../comon/SelectWithCheckboxes";
import "./members.css";
import { DateInput } from "../../components/UI";
import classNames from "classnames";
import { isHOGDomain } from "../../comon/isHOGDomain";

export default function Modal({ setItems, data, setFilter }) {
  const countriesList = useSelector((state) => state.countriesList);

  const [countriesBack, setCountriesBack] = useState([]);
  const [open, setOpen] = useState(false);
  const [nickname, setNickName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState("");
  const [game, setGame] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState();
  const [pro, setPro] = useState(null);
  const [verified, setVerified] = useState(null);

  const fetchCountries = async () => {
    try {
      const { data } = await axios.get(
        "https://newapi.passport.gg/public/labels/countries"
      );
      setCountriesBack(data);
    } catch (e) {
      console.error(e);
    }
  };
  const getGamesList = () => {
    let params = {
      ...API_ROUTER.user.getCurrentGames,
    };

    API.request(params)
      .then((res) => {
        setGamesList(res.games);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    control: (css) => ({ ...css, backgroundColor: "transparent" }),
    menu: (css) => ({
      ...css,
      backgroundColor: isHOGDomain() ? "#000" : "#3F317C",
    }),
    singleValue: (css) => ({
      ...css,
      color: "white",
    }),
    input: (css) => ({ ...css, color: "white" }),
    option: (css) => ({ ...css, backgroundColor: "transparent" }),
  };

  const handleSubmit = () => {
    const gameIds = game?.map((item) => item.id);
    const countries = country?.map((item) => item.value);
    const data = {
      filter: {
        nickname: nickname,
        firstName: firstName,
        lastName: lastName,
        country: countries,
        city,
        isPro: pro?.name === "yes" ? true : pro?.name === "no" ? false : null,
        verified:
          verified?.name === "yes"
            ? true
            : verified?.name === "no"
            ? false
            : null,
        game: gameIds,
        dateStart,
        dateEnd,
      },
    };
    setFilter(data.filter);
    setOpen(false);
  };

  useEffect(() => {
    getGamesList();
    fetchCountries();
  }, []);

  return (
    <>
      <div
        onClick={handleClickOpen}
        className={style.sortFilterContainer}
        title="sort"
      >
        <div className={style.filterContainer} title="filter">
          <FormattedMessage id="events.btn.filter" tagName="span" />
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className={classNames(s.dialog, { [s.hog]: isHOGDomain() })}>
          <DialogTitle className={s.title}>Filter your members</DialogTitle>
          <DialogContent>
            <Box noValidate component="form" className={s.boxDialogContent}>
              <div className={style.inputContainer}>
                <input
                  type="text"
                  placeholder="Nickname"
                  value={nickname}
                  onChange={(e) => setNickName(e.target.value)}
                />
              </div>
              <div className={style.inputContainer}>
                <input
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className={style.inputContainer}>
                <input
                  type="text"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <Select
                required
                isMulti
                placeholder="Countries"
                style={{ fontSize: 18 }}
                classNamePrefix="custom_select"
                options={countriesList || []}
                value={country}
                onChange={(e) => setCountry(e)}
              />

              {/* 
              {!!filteredCities.length && (
                <Dropdown
                  onChange={(e) => setCity(e)}
                  name="city"
                  options={data?.map((item) => {
                    return { title: item.city };
                  })}
                  placeholder="City"
                  value={city}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.title}
                  styles={styles}
                />
              )} */}

              <SelectWithCheckboxes
                style={{ marginTop: "5px" }}
                value={game}
                setState={setGame}
                options={gamesList}
                placeholder="Games"
              />

              <Dropdown
                style={{ marginTop: "5px" }}
                onChange={(e) => setPro(e)}
                name="pro"
                options={[{ name: "yes" }, { name: "no" }]}
                placeholder="Pro"
                value={pro}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                styles={styles}
                isClearable
              />

              <Dropdown
                style={{ marginTop: "5px" }}
                onChange={(e) => setVerified(e)}
                name="verified"
                options={[{ name: "yes" }, { name: "no" }]}
                placeholder="Verified"
                value={verified}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                styles={styles}
                isClearable
              />

              {/* <div className={s.label}>
                <FormattedMessage
                  id="members.modal.dateSelect"
                  tagName="span"
                />
              </div>
              <div className={s.btnContainer}>
                <div className={s.row}>
                  <div className={s.titleBtn}>
                    <FormattedMessage
                      id="members.modal.startTitle"
                      tagName="span"
                    />
                  </div>
                  <div className={s.firstInput}>
                    <DateInput
                      selected={dateStart}
                      onChange={(date) => setDateStart(date)}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <div className={s.titleBtn}>
                    <FormattedMessage
                      id="members.modal.endTitle"
                      tagName="span"
                    />
                  </div>
                  <DateInput
                    selected={dateEnd}
                    onChange={(date) => setDateEnd(date)}
                  />
                </div>
              </div> */}
            </Box>
            <div className={s.buttonWrapper}>
              <button onClick={handleSubmit} type="button">
                Apply filters
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}
