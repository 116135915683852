import { FormattedMessage } from "react-intl";
import Dialog from '@material-ui/core/Dialog';

const NoAccessPopup = (props) => {
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={props.open}
            onClose={handleClose}
        >
            <div style={{
                padding: 32,
                backgroundColor: '#3F317C',
                color: 'white'
            }}>
                <div>
                    <FormattedMessage id="members.banModal.errorMessageText1" tagName="span" />
                    <FormattedMessage id="members.banModal.errorMessageLink" tagName="span" />
                    <FormattedMessage id="members.banModal.errorMessageText2" tagName="span" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}><button onClick={() => window.open("https://about.passport.gg/officialstatusrequest", "_blank")} style={{ marginTop: 32, alignSelf: 'center', width: 200 }}><FormattedMessage id="members.banModal.errorMessageButton" tagName="span" /></button></div>
            </div>
        </Dialog>
    )
}

export default NoAccessPopup