import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { API, API_ROUTER } from "../../../api/index";
import { ROUTER } from "../../../config";
import { useHistory, useParams } from "react-router-dom";
import { convertDateToString } from '../../../comon/comonConst';
import Select from 'react-select';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import '../../../comon/CustomSelect/select.css';
import GamesSelect from '../CreateTournament/gamesSelect';

import { uploadData, onChangeDudlePhotos, addPhotos, delPhoto } from '../../../comon/uploadData';
import { addSpinner, removeSpinner, showNotificationModal } from '../../../redux/actions';

import s from './createEvent.module.scss';

import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";

import Calendar from '../../../comon/Calendar/index';
import FileUploaderSingle from '../../../comon/file-uploader-for-single';
import {DateInput} from "../../UI";
import {isHOGDomain} from "../../../comon/isHOGDomain";

export const EVENT_TYPES = [
   { id: 1, name: 'online' },
   { id: 2, name: 'offline' },
   { id: 3, name: 'mixed' }
]

const CreateEvent = (props) => {

   const dispatch = useDispatch()

   const { register, control, setValue, handleSubmit, watch, formState: { errors } } = useForm({
      mode: 'all',
   });

   const [siteType, setSiteType] = useState('')
   const [layersOptions, setLayesOptions] = useState([])

   function currentDate() {
      var d = new Date(),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      return [year, month, day].join('-');
   }

   const userData = useSelector(state => state.userData)

   const history = useHistory();
   function goToUrl(url) {
      history.push(url);
   }

   useEffect(() => {
      let params = {
         ...API_ROUTER.user.getUserRights
      };
      API.request(params)
         .then((res) => {
            setSiteType(res.adminSites)
            if (res.adminSites.includes('africa')) {
               setLayesOptions([
                  { id: 1, name: 'International' },
                  { id: 2, name: 'Regional' },
                  { id: 3, name: 'National' },
                  { id: 4, name: 'Local' },
                  { id: 5, name: 'SAGES legendary series' },
               ])
            } else {
               setLayesOptions([
                  { id: 1, name: 'International' },
                  { id: 2, name: 'Regional' },
                  { id: 3, name: 'National' },
                  { id: 4, name: 'Local' },
               ])
            }
         })
         .catch((err) => console.log(err));
   }, [])

   useEffect(() => {
      let params = {
         ...API_ROUTER.community.getCommunities,
         pathKeys: {
            page: 1,
            limit: 9999,
         }
      };
      API.request(params)
         .then((res) => {
            setCommunitysBack(res.communities);
         })
         .catch((err) => console.log(err));
   }, [])

   useEffect(() => {
      API.request({
         ...API_ROUTER.user.getCurrentGames,
      })
         .then((res) => {
            setGamesFromBack(res);
         })
         .catch((err) => console.log(err))
   }, [])

   const { id: editId } = useParams();

   const [eventToEdit, setEventToEdit] = useState({})
   const [communitysBack, setCommunitysBack] = useState([]);
   const [gamesBack, setGamesFromBack] = useState('');

   useEffect(() => {
      if (editId) {
         let params = {
            ...API_ROUTER.events.getEventById,
            pathKeys: {
               eventId: editId
            }
         };
         dispatch(addSpinner())
         API.request(params)
            .then((res) => {
               if (currentDate() > res.startDate) {
                  history.push(ROUTER.evensPage)
               } else {
                  const games = res.games?.games?.map((game) => {
                     return { label: game?.title, value: game?.id }
                  })
                  setEventToEdit(res);
                  setValue('eventName', res.name);
                  setValue('eventDescription', res.description);
                  setCalendarDateStart(new Date(res.startDate));
                  setCalendarDateEnd(new Date(res.endDate));
                  setValue('location', res.location)
                  setValue('organizer', res.nameOfOrganizer)
                  setBackgroundLogo(res.profilePageHeaderBanner ? res.profilePageHeaderBanner : '')
                  setTypeEvent(EVENT_TYPES.find(item => item.name === res.type))
                  setLayer(layersOptions.find(item => item.name === res.grade))
                  setCommunity(communitysBack.find(item => item.id === res.community && item.id))
                  setInputAnyPhotos(res.anyPhotos?.length ? res.anyPhotos : ['1'])
                  setInputArrOrganizerlogo(res.organizerLogo?.length ? res.organizerLogo : ['1'])
                  setInputArrPartnerlogo(res.partnerLogo?.length ? res.partnerLogo : ['1'])
                  setInputArrSponsorlogo(res.sponsorLogo?.length ? res.sponsorLogo : ['1'])
                  setInputArrEventlogo(res.eventLogo?.length ? res.eventLogo : '')
                  setValue('games', games)
                  setGames(games || [])
                  setValue('typeEvent', EVENT_TYPES.find(event => event.name === res.type))
                  setValue('layer', layersOptions.find(layer => layer.name === res.grade))
                  setInputArrContacts(res.contacts?.length ? res.contacts : [{ name: "", title: "" }])
                  setInputTeamOrganizer(res.teamOfOrganizer?.length ? res.teamOfOrganizer : [{ name: "", title: "" }])
                  setFacebookLink(res.socialNetworks?.facebook)
                  setInstagramLink(res.socialNetworks?.instagram)
                  setDiscordLink(res.socialNetworks?.discord)
                  setTwitterLink(res.socialNetworks?.twitter)

                  dispatch(removeSpinner())
               }
            })
            .catch(err => {
               console.log(err);
               dispatch(removeSpinner())
            })
      }
   }, [editId, communitysBack?.length, gamesBack?.length, layersOptions?.length])

   //Мультиязычность Placeholder
   const enterEventNamePlaceholder = useIntl().formatMessage({
      id: "createEvent.title.eventName.placeholder",
   });
   const descriptionPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.description.placeholder",
   });
   const organizerPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.organizer.placeholder",
   });
   const gamesPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleGames.placeholder",
   });
   const typeEventPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleTypeEvent.placeholder",
   });
   const communityPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleCommunity.placeholder",
   });
   const layerPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleLayer.placeholder",
   });
   const teamOrganizerNamePlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleTeamOrganizer.placeholderName",
   });
   const teamOrganizerTitlePlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleTeamOrganizer.placeholderTitle",
   });
   const contactsNamePlaceholder = useIntl().formatMessage({
      id: "placeholderName",
   });
   const contactsNameTitlePlaceholder = useIntl().formatMessage({
      id: "placeholderTitle",
   });
   const socialNetworksLinkPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleSocialNetworks.placeholder",
   });
   const LocationPlaceholder = useIntl().formatMessage({
      id: "createEvent.title.titleLocation.placeholder",
   });
   const ServerAnswerSuccess = useIntl().formatMessage({
      id: `${editId ? "createEvent.serverAnswer.success.edit" : "createEvent.serverAnswer.success.create"}`,
   });
   const ServerAnswerErr = useIntl().formatMessage({
      id: "createEvent.serverAnswer.err",
   });

   //dateStartCalendar
   const [dateStartIsVisible, setDateStartIsVisible] = useState(false);
   //dateEndCalendar
   const [dateEndIsVisible, setDateEndVisible] = useState(false);

   //dateStart
   const [calendarDateStart, setCalendarDateStart] = useState(new Date());

   //dateEnd
   const [calendarDateEnd, setCalendarDateEnd] = useState(new Date());


   const [games, setGames] = useState([]);
   const [typeEvent, setTypeEvent] = useState('');
   const [community, setCommunity] = useState('');
   const [layer, setLayer] = useState('');

   //SOCIAL NETWORKS
   //facebook
   const [facebookLink, setFacebookLink] = useState('');
   //instagram
   const [instagramLink, setInstagramLink] = useState('');
   //discord
   const [discordLink, setDiscordLink] = useState('');
   //twitter
   const [twitterLink, setTwitterLink] = useState('');


   //Всё, что связанно с валидацией
   const [submitErr, setSubmitErr] = useState(false);
   const [facebookErr, setFacebookErr] = useState(false);
   const [instagramErr, setInstagramErr] = useState(false);
   const [discordErr, setDiscordErr] = useState(false);
   const [twitterErr, setTwitterErr] = useState(false);
   const [contactNameErr, setContactNameErr] = useState(false);
   const [contactValueErr, setContactValueErr] = useState(false);
   const [teamNameErr, setTeamNameErr] = useState(false);
   const [teamValueErr, setTeamValueErr] = useState(false);
   //images
   const [backgroundLogo, setBackgroundLogo] = useState([]);

   //add inputs Contacts
   const [inputArrContacts, setInputArrContacts] = useState([{ name: "", title: "" }]);
   const [inputTeamOrganizer, setInputTeamOrganizer] = useState([{ name: "", title: "" }]);

   //add photo
   const [photoArrEventlogo, setInputArrEventlogo] = useState('');
   const [photoArrOrganizerlogo, setInputArrOrganizerlogo] = useState(['1']);
   const [photoArrSponsorlogo, setInputArrSponsorlogo] = useState(['1']);
   const [photoArrPartnerlogo, setInputArrPartnerlogo] = useState(['1']);
   const [photoAnyPhotos, setInputAnyPhotos] = useState(['1']);

   //change text value in input and textarea
   const changeTextValue = (value, fn, checkFn) => {
      fn(value);
      value.length < 2 ? checkFn(true) : checkFn(false)
      value ? setSubmitErr(false) : setSubmitErr(true);
   }
   //change value in select
   const changeSelectValue = (value, fn) => {
      fn(value);
      value ? setSubmitErr(false) : setSubmitErr(true);
   }

   const chakedForm = (e) => {
      e.target.value ? setSubmitErr(false) : setSubmitErr(true);
   }
   const chakedSelct = (e) => {
      e ? setSubmitErr(false) : setSubmitErr(true);
   }
   const chakedSocialNetworks = (e, name, setErr) => {
      let str = e.target.value;
      str.includes(name) ? setErr(false) : setErr(true);
   }

   const addInputs = (value, fn) => {
      let newValue = [...value];
      newValue.push({ name: "", title: "" });
      fn(newValue);
   }

   const onChangeDudleInput = (e, id, type, fn, data, checkFn) => {
      let value = e.target.value;
      value.length < 2 ? checkFn(true) : checkFn(false)
      let newValue = [...data];
      if (type === 'name') {
         newValue[id].name = value;
      } else {
         newValue[id].title = value;
      }
      fn(newValue);
   }

   const handleDelete = (idx, array, fn) => {
      const copiedArr = [...array]
      copiedArr.splice(idx, 1);
      fn(copiedArr)
   }

   const onSubmit = (submitData) => {
      const gamesForBack = games.map((game) => {
         return { gameId: game.value }
      })
      // if (calendarDateStart) {
      if (communitysBack.length === 0) {
         dispatch(addSpinner())
         API.request({
            ...API_ROUTER.community.createCommunity,
            data: {
               name: `Community of ${userData?.nickname}`,
               startedAt: new Date().toLocaleDateString() + "  00:00:00",
               admissionProcess: "automatic",
               contacts: ['contact goes here', 'contact goes here'],
               description: "Add your text here",
               nameOfOrganizer: "Add your text here",
               rules: 'Add your text here'
            }
         }).then((res) => {
            const contacts = inputArrContacts[0].name ? inputArrContacts : []
            const data = {
               communityId: res.id,
               games: gamesForBack,
               name: watch('eventName'),
               nameOfOrganizer: watch('organizer'),
               contacts: contacts,
               socialNetworks: { facebook: facebookLink, instagram: instagramLink, discord: discordLink, twitter: twitterLink },
               type: typeEvent.name, //Type of the Event
               grade: layer.name,
               teamOfOrganizer: inputTeamOrganizer,
               description: watch('eventDescription'),
               location: watch('location'),
               countries: null,
               startedAt: (convertDateToString(calendarDateStart) + ' 00:00:00').replaceAll("/", "."),
               endedAt: (convertDateToString(calendarDateEnd) + ' 00:00:00').replaceAll("/", "."),
            }

            console.log(convertDateToString(calendarDateStart), convertDateToString(calendarDateEnd), '=======')

            API.request({
               ...API_ROUTER.events.createEvent,
               data
            })
               .then((res) => {

                  setTimeout(() => {
                     if (backgroundLogo.length > 0 && !editId) {
                        uploadData(backgroundLogo, res.id, 'eventProfilePageHeaderBanner')
                     } else if (backgroundLogo.length > 0 && editId) {
                        if (eventToEdit.profilePageHeaderBanner !== backgroundLogo) {
                           uploadData(backgroundLogo, res.id, 'eventProfilePageHeaderBanner')
                        }
                     }
                  }, 0)

                  //
                  setTimeout(() => {
                     if (photoArrEventlogo.length > 0 && !editId) {
                        uploadData(photoArrEventlogo, res.id, 'eventLogo');
                     } else if (photoArrEventlogo.length > 0 && editId) {
                        if (eventToEdit.eventLogo !== photoArrEventlogo) {
                           uploadData(photoArrEventlogo, res.id, 'eventLogo')
                        }
                     }
                  }, 1000)

                  function filterImages(images, backendImages) {
                     const newArr = images.filter(item => {
                        return !backendImages.includes(item)
                     })
                     return newArr
                  }

                  setTimeout(() => {
                     if (photoArrSponsorlogo != '1' && !editId) {
                        photoArrSponsorlogo.map(el => uploadData(el, res.id, 'sponsorLogo'));
                     } else if (photoArrSponsorlogo != '1' && editId) {
                        filterImages(photoArrSponsorlogo, eventToEdit.sponsorLogo).map(el => uploadData(el, res.id, 'sponsorLogo'));
                     }
                  }, 2000)
                  setTimeout(() => {
                     if (photoArrPartnerlogo != '1' && !editId) {
                        photoArrPartnerlogo.map(el => uploadData(el, res.id, 'partnerLogo'));
                     } else if (photoArrPartnerlogo != '1' && editId) {
                        filterImages(photoArrPartnerlogo, eventToEdit.partnerLogo).map(el => uploadData(el, res.id, 'partnerLogo'));
                     }
                  }, 3000)
                  setTimeout(() => {
                     if (photoArrOrganizerlogo != '1' && !editId) {
                        photoArrOrganizerlogo.map(el => uploadData(el, res.id, 'organizerLogo'));
                     } else if (photoArrOrganizerlogo != '1' && editId) {
                        filterImages(photoArrOrganizerlogo, eventToEdit.organizerLogo).map(el => uploadData(el, res.id, 'organizerLogo'));
                     }
                  }, 4000)
                  setTimeout(() => {
                     if (photoAnyPhotos != '1' && !editId) {
                        photoAnyPhotos.map(el => uploadData(el, res.id, 'anyPhotos'));
                     } else if (photoAnyPhotos != '1' && editId) {
                        filterImages(photoAnyPhotos, eventToEdit.anyPhotos).map(el => uploadData(el, res.id, 'anyPhotos'));
                     }
                  }, 5000)

                  dispatch(showNotificationModal(ServerAnswerSuccess))
                  dispatch(removeSpinner())
                  goToUrl(ROUTER.evensPage);
               })
               .catch((err) => {
                  dispatch(removeSpinner())
                  const errors = []
                  for (let key in err.data.errors) {
                     errors.push(`${key}: ${err.data.errors[key]}`)
                  }
                  dispatch(showNotificationModal(`Some errors occured: ${errors.join(', ')}`))
               });
         }).catch((err) => {
            dispatch(removeSpinner())
            const errors = []
            for (let key in err.data.errors) {
               errors.push(`${key}: ${err.data.errors[key]}`)
            }
            dispatch(showNotificationModal(`Some errors occured: ${errors.join(', ')}`))
         })
      } else {
         const gamesForBack = games.map((game) => {
            return { gameId: game.value }
         })
         const contacts = inputArrContacts[0].name ? inputArrContacts : []
         const data = {
            communityId: community?.id,
            games: gamesForBack,
            name: watch('eventName'),
            nameOfOrganizer: watch('organizer'),
            contacts: contacts,
            socialNetworks: { facebook: facebookLink, instagram: instagramLink, discord: discordLink, twitter: twitterLink },
            type: typeEvent.name,
            grade: layer.name,
            teamOfOrganizer: inputTeamOrganizer,
            description: watch('eventDescription'),
            location: watch('location'),
            countries: null,
            startedAt: convertDateToString(calendarDateStart) + ' 00:00:00',
            endedAt: convertDateToString(calendarDateEnd) + ' 00:00:00',
         }

         dispatch(addSpinner())

         API.request(!editId ? {
            ...API_ROUTER.events.createEvent,
            data
         } : {
            ...API_ROUTER.events.updateEvent,
            pathKeys: {
               eventId: editId
            },
            data
         })
            .then((res) => {

               setTimeout(() => {
                  if (backgroundLogo.length > 0 && !editId) {
                     uploadData(backgroundLogo, res.id, 'eventProfilePageHeaderBanner')
                  } else if (backgroundLogo.length > 0 && editId) {
                     if (eventToEdit.profilePageHeaderBanner !== backgroundLogo) {
                        uploadData(backgroundLogo, res.id, 'eventProfilePageHeaderBanner')
                     }
                  }
               }, 0)

               //
               setTimeout(() => {
                  if (photoArrEventlogo.length > 0 && !editId) {
                     uploadData(photoArrEventlogo, res.id, 'eventLogo');
                  } else if (photoArrEventlogo.length > 0 && editId) {
                     if (eventToEdit.eventLogo !== photoArrEventlogo) {
                        uploadData(photoArrEventlogo, res.id, 'eventLogo')
                     }
                  }
               }, 1000)

               function filterImages(images, backendImages) {
                  const newArr = images.filter(item => {
                     return !backendImages.includes(item)
                  })
                  return newArr
               }

               setTimeout(() => {
                  if (photoArrSponsorlogo != '1' && !editId) {
                     photoArrSponsorlogo.map(el => uploadData(el, res.id, 'sponsorLogo'));
                  } else if (photoArrSponsorlogo != '1' && editId) {
                     filterImages(photoArrSponsorlogo, eventToEdit.sponsorLogo).map(el => uploadData(el, res.id, 'sponsorLogo'));
                  }
               }, 2000)
               setTimeout(() => {
                  if (photoArrPartnerlogo != '1' && !editId) {
                     photoArrPartnerlogo.map(el => uploadData(el, res.id, 'partnerLogo'));
                  } else if (photoArrPartnerlogo != '1' && editId) {
                     filterImages(photoArrPartnerlogo, eventToEdit.partnerLogo).map(el => uploadData(el, res.id, 'partnerLogo'));
                  }
               }, 3000)
               setTimeout(() => {
                  if (photoArrOrganizerlogo != '1' && !editId) {
                     photoArrOrganizerlogo.map(el => uploadData(el, res.id, 'organizerLogo'));
                  } else if (photoArrOrganizerlogo != '1' && editId) {
                     filterImages(photoArrOrganizerlogo, eventToEdit.organizerLogo).map(el => uploadData(el, res.id, 'organizerLogo'));
                  }
               }, 4000)
               setTimeout(() => {
                  if (photoAnyPhotos != '1' && !editId) {
                     photoAnyPhotos.map(el => uploadData(el, res.id, 'anyPhotos'));
                  } else if (photoAnyPhotos != '1' && editId) {
                     filterImages(photoAnyPhotos, eventToEdit.anyPhotos).map(el => uploadData(el, res.id, 'anyPhotos'));
                  }
               }, 5000)

               dispatch(showNotificationModal(ServerAnswerSuccess))
               goToUrl(ROUTER.evensPage);

               dispatch(removeSpinner())
            })
            .catch((err) => {
               dispatch(removeSpinner())
               const errors = []
               for (let key in err.data.errors) {
                  errors.push(`${key}: ${err.data.errors[key]}`)
               }
               dispatch(showNotificationModal(`Some errors occured: ${errors.join(', ')}`))
            });
      }
      // } else setSubmitErr(true);
   }

   return (
      <div className={s.mainBlock}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.contentHeaderTitleContainer}>

               <div className={s.eventsHeaderTitleContainer}>
                  <div className={s.title}>
                     {editId ? <FormattedMessage id="editEvent.pageName.title" tagName="span" /> : <FormattedMessage id="createEvent.pageName.title" tagName="span" />}
                  </div>
               </div>

               <div className={s.mainSattingsContainer}>
                  <div className={s.settingsContainer}>
                     <div className={s.inputContainer}>
                        <div className={s.titleInput}>
                           <FormattedMessage id="createEvent.title.eventName" tagName="span" />
                        </div>
                        {errors.eventName?.type === 'required' && <span className={s.messageErr}><FormattedMessage id="createTournament.title.titleObligatory" tagName="span" /></span>}
                        {errors.eventName?.type === 'minLength' && <span className={s.messageErr}><FormattedMessage id="createLanding.err.errMessageLong" tagName="span" /></span>}
                        <input
                           className={errors.eventName ? s.inputErr : ""}
                           type="text"
                           placeholder={enterEventNamePlaceholder}
                           {...register("eventName", { required: true, minLength: 2 })}
                        />
                     </div>
                     <div className={s.btnContainer}>
                        <div className={s.btnInnerContainer}>
                           <div className={s.titleBtn}>
                              <FormattedMessage id="createEvent.title.startTitle" tagName="span" />
                           </div>

                           <DateInput  onChange={setCalendarDateStart} selected={calendarDateStart} />
                        </div>
                        <div className={s.btnInnerContainer}>
                           <div className={s.titleBtn}>
                              <FormattedMessage id="createEvent.title.endTitle" tagName="span" />
                           </div>

                           <DateInput onChange={setCalendarDateEnd} selected={calendarDateEnd} />
                        </div>
                     </div>
                  </div>
                  <div className={s.imageContainer}>
                     <div className={s.uploadTitle} style={{ marginBottom: 20, background: isHOGDomain() && "rgb(132, 100, 20)" }}>
                        <FormattedMessage id="createEvent.title.BGImg" tagName="span" />
                     </div>
                     <FileUploaderSingle
                        defaultFoto={props.uploadImg}
                        setImageURL={setBackgroundLogo}
                        imageURL={backgroundLogo}
                        htmlFor={'backgroundLogo'}
                     />
                  </div>
               </div>
               <div className={s.descriptionContainer}>
                  <div className={s.descriptionTitle}>
                     <FormattedMessage id="createEvent.title.description" tagName="span" />
                  </div>
                  {/* {submitErr ? <p className={s.messageErr}>
                     <FormattedMessage id="err.errMessage" tagName="span" />
                  </p> : false}
                  {eventDescriptionErr && <p className={s.messageErr}>
                     Value is too short
                  </p>} */}
                  {errors.eventDescription?.type === 'required' && <span className={s.messageErr}><FormattedMessage id="createTournament.title.titleObligatory" tagName="span" /></span>}
                  {errors.eventDescription?.type === 'minLength' && <span className={s.messageErr}><FormattedMessage id="createLanding.err.errMessageLong" tagName="span" /></span>}
                  <div className={errors.eventDescription ? s.textareaErr : false}>
                     <textarea
                        className={s.descriptionTextarea}
                        placeholder={descriptionPlaceholder}
                        type="text"
                        style={{background: isHOGDomain() && "#000"}}
                        {...register("eventDescription", { required: true, minLength: 2 })}
                     // value={eventDescription}
                     // onChange={(e) => changeTextValue(e.target.value, setEeventDescription, setEventDescriptionErr)}
                     // onBlur={chakedForm}
                     ></textarea>
                  </div>
               </div>
               <div className={s.organizerPartnerFromContainr}>
                  <div className={s.inputMainBottomContainer}>
                     <div className={s.inputContainer}>
                        <div className={s.title}>
                           <FormattedMessage id="createEvent.title.organizer" tagName="span" />
                        </div><br />
                        {/* {submitErr ? <p className={s.messageErr}>
                           <FormattedMessage id="err.errMessage" tagName="span" />
                        </p> : false}
                        {eventOrganizerErr && <p className={s.messageErr}>
                           Value is too short
                        </p>} */}
                        {errors.organizer?.type === 'required' && <span className={s.messageErr}><FormattedMessage id="createTournament.title.titleObligatory" tagName="span" /></span>}
                        {errors.organizer?.type === 'minLength' && <span className={s.messageErr}><FormattedMessage id="createLanding.err.errMessageLong" tagName="span" /></span>}
                        <div className={submitErr ? s.textareaErr : false}>
                           <input
                              {...register("organizer", { required: true, minLength: 2 })}
                              type="text"
                              placeholder={organizerPlaceholder}
                              className={errors.organizer ? s.inputErr : false}
                           // value={organizer}
                           // onChange={(e) => changeTextValue(e.target.value, setOrganizer, setEventOrganizerErr)}
                           // onBlur={chakedForm}
                           />
                        </div>
                     </div>

                     {/* ///////////// */}
                     <div className={s.imageContainer}>
                        <div className={s.imgTitle} style={{ marginBottom: 20 }}>
                           <span style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}>
                              <FormattedMessage id="createEvent.title.imgTitle.eventLogo" />
                           </span>
                        </div>
                        <FileUploaderSingle
                           defaultFoto={props.uploadImg}
                           setImageURL={setInputArrEventlogo}
                           imageURL={photoArrEventlogo}
                           htmlFor={'photoArrEventlogo'}
                        />
                        {/* {photoArrEventlogo.map((el, i) => <div>

                        <div key={i} className={s.btnContainer}>
                           <div
                              className={s.delBtn}
                              onClick={() => delPhoto(i, setInputArrEventlogo, photoArrEventlogo)}
                           ></div>
                           <FileUploaderSingle
                              style={{ marginTop: 0 }}
                              defaultFoto={props.uploadImg}
                              imageURL={photoArrEventlogo[i]}
                              htmlFor={`event${i}`}
                              isMany={true}
                              onChangeDudlePhotos={onChangeDudlePhotos}
                              id={i}
                              fn={setInputArrEventlogo}
                              data={photoArrEventlogo}
                           />
                        </div>

                     </div>)} */}
                     </div>

                     {/* <div className={s.addBtnContainer}>
                     <button
                        className={s.addBtn}
                        onClick={() => addPhotos(photoArrEventlogo, setInputArrEventlogo)}>
                        <FormattedMessage id="createEvent.btn.addCommunityLogo" tagName="span" />
                     </button>
                  </div> */}
                     {/* ////////////////////////// */}
                  </div>
                  <div className={s.inputMainBottomContainer}>

                     <div className={s.imageContainer}>
                        <div className={s.imgTitle}>
                           <FormattedMessage id="createEvent.title.imgTitle.sponsorLogo" />
                        </div>
                        {photoArrSponsorlogo.map((el, i) => <div>

                           <div key={i} className={s.btnContainer}>
                              <div
                                 className={s.delBtn}
                                 onClick={() => delPhoto(i, setInputArrSponsorlogo, photoArrSponsorlogo, editId)}
                              ></div>
                              <FileUploaderSingle
                                 style={{ marginTop: 0 }}
                                 defaultFoto={props.uploadImg}
                                 imageURL={photoArrSponsorlogo[i]}
                                 htmlFor={`sponsor${i}`}
                                 isMany={true}
                                 onChangeDudlePhotos={onChangeDudlePhotos}
                                 id={i}
                                 fn={setInputArrSponsorlogo}
                                 data={photoArrSponsorlogo}
                              />
                           </div>

                        </div>)}
                     </div>

                     <div className={s.addBtnContainer}>
                        <button
                           className={s.addBtn}
                           type='button'
                           style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
                           onClick={() => addPhotos(photoArrSponsorlogo, setInputArrSponsorlogo)}>
                           <FormattedMessage id="createEvent.btn.addSponsorLogo" tagName="span" />
                        </button>
                     </div>

                     <div className={s.imageContainer}>
                        <div className={s.imgTitle}>
                           <span style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}>
                              <FormattedMessage id="createEvent.title.imgTitle.partnerLogo" />
                           </span>

                        </div>
                        {photoArrPartnerlogo.map((el, i) => <div>

                           <div key={i} className={s.btnContainer}>
                              <div
                                 className={s.delBtn}
                                 onClick={() => delPhoto(i, setInputArrPartnerlogo, photoArrPartnerlogo, editId)}
                              ></div>
                              <FileUploaderSingle
                                 style={{ marginTop: 0 }}
                                 defaultFoto={props.uploadImg}
                                 imageURL={photoArrPartnerlogo[i]}
                                 htmlFor={`partner${i}`}
                                 isMany={true}
                                 onChangeDudlePhotos={onChangeDudlePhotos}
                                 id={i}
                                 fn={setInputArrPartnerlogo}
                                 data={photoArrPartnerlogo}
                              />
                           </div>

                        </div>)}


                        <div className={s.addBtnContainer}>
                           <button
                              type='button'
                              className={s.addBtn}
                              style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
                              onClick={() => addPhotos(photoArrPartnerlogo, setInputArrPartnerlogo)}>
                              <FormattedMessage id="createEvent.btn.addPartnerLogo" tagName="span" />
                           </button>
                        </div>
                     </div>

                     <div className={s.imageContainer}>
                        <div className={s.imgTitle}>
                           <span style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}>
                               <FormattedMessage id="createEvent.title.imgTitle.organizerLogo" />
                           </span>
                        </div>
                        {photoArrOrganizerlogo.map((el, i) => <div>

                           <div key={i} className={s.btnContainer}>
                              <div
                                 className={s.delBtn}
                                 onClick={() => delPhoto(i, setInputArrOrganizerlogo, photoArrOrganizerlogo, editId)}
                              ></div>
                              <FileUploaderSingle
                                 style={{ marginTop: 0 }}
                                 defaultFoto={props.uploadImg}
                                 imageURL={photoArrOrganizerlogo[i]}
                                 htmlFor={`organizerLogo${i}`}
                                 isMany={true}
                                 onChangeDudlePhotos={onChangeDudlePhotos}
                                 id={i}
                                 fn={setInputArrOrganizerlogo}
                                 data={photoArrOrganizerlogo}
                              />
                           </div>
                        </div>)}
                        <div className={s.addBtnContainer}>
                           <button
                              type='button'
                              className={s.addBtn}
                              style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
                              onClick={() => addPhotos(photoArrOrganizerlogo, setInputArrOrganizerlogo)}>
                              <FormattedMessage id="createEvent.btn.addOrganizerLogo" tagName="span" />
                           </button>
                        </div>
                     </div>
                  </div>
               </div>

               {/* game* */}
               <div className={s.selectContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleGames" tagName="span" />
                  </div>
                  {errors.games?.type === 'required' && <p className={s.messageErr}>
                     <FormattedMessage id="createTournament.title.titleObligatory" tagName="span" />
                  </p>}
                  <div className={s.select} style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}>
                     <Controller
                        name="games"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={games}
                        render={({
                           field
                        }) => <GamesSelect isMulti={true} value={games} handleChange={(e) => {
                           field.onChange(e)
                           setGames(e)
                        }} />}
                     />
                  </div>
               </div>

               {/* Type of the Event*/}
               <div className={s.selectContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleTypeEvent" tagName="span" />
                  </div>
                  {/* {submitErr ? <p className={s.messageErr}>
                     <FormattedMessage id="createEvent.title.titleObligatory" tagName="span" />
                  </p> : false} */}
                  {errors.typeEvent?.type === 'required' && <p className={s.messageErr}>
                     <FormattedMessage id="createTournament.title.titleObligatory" tagName="span" />
                  </p>}
                  <div className={s.select} style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}>
                     <Controller
                        name="typeEvent"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={typeEvent}
                        render={({
                           field
                        }) => <Select
                              onChange={(e) => {
                                 field.onChange(e)
                                 setTypeEvent(e)
                              }}
                              classNamePrefix='custom_select'
                              options={EVENT_TYPES}
                              placeholder={typeEventPlaceholder}
                              isMulti={false}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={typeEvent}

                           />}
                     />
                  </div>
                  {/* <div className={s.select}>
                     <Select
                        value={typeEvent}
                        classNamePrefix='custom_select'
                        options={EVENT_TYPES}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        name="type"
                        isMulti={false}
                        placeholder={typeEventPlaceholder}
                        onChange={(e) => changeSelectValue(e, setTypeEvent)}
                        onBlur={(e) => chakedSelct(e.length > 0)}
                     />
                  </div> */}
               </div>

               {(typeEvent.name === 'offline' || typeEvent.name === 'mixed') ? <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                     <FormattedMessage id="createEvent.title.titleLocation" tagName="span" />
                  </div>
                  {errors.location?.type === 'required' && <span className={s.messageErr}><FormattedMessage id="createTournament.title.titleObligatory" tagName="span" /></span>}
                  {errors.location?.type === 'minLength' && <span className={s.messageErr}><FormattedMessage id="createLanding.err.errMessageLong" tagName="span" /></span>}
                  <input
                     {...register('location', { required: true, minLength: 2 })}
                     className={errors.location ? s.inputErr : false}
                     type="text"
                     placeholder={LocationPlaceholder}
                  />
                  {/* <input
                     className={submitErr ? s.inputErr : false}
                     type="text"
                     placeholder={LocationPlaceholder}
                     value={location}
                     onChange={(e) => changeTextValue(e.target.value, setLocation, setLocationErr)}
                  /> */}
               </div> : false}

               {/* Community*/}
               <div className={s.selectContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleCommunity" tagName="span" />
                  </div>
                  {!editId && <p style={{ marginBottom: 10, fontSize: 14 }}>If you have not created a community yet it will be created automatically. To edit your community go to community page</p>}
                  {editId && <p style={{ marginBottom: 10, fontSize: 14 }}>Community cannot be editted. Please, contact our <a style={{ color: 'white', textDecoration: 'underline' }} href='https://about.passport.gg/adminsupport'>support team.</a></p>}
                  <div className={s.select} style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}>
                     {!editId ? <Select
                        value={community}
                        classNamePrefix='custom_select'
                        options={communitysBack}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        name="community"
                        isMulti={false}
                        placeholder={communityPlaceholder}
                        onChange={(e) => {
                           setCommunity(e)
                        }}
                        onBlur={(e) => chakedSelct(e.length > 0)}
                     /> : <Select
                        value={community}
                        classNamePrefix='custom_select'
                        options={communitysBack}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        name="community"
                        isMulti={false}
                        placeholder={communityPlaceholder}
                        onChange={(e) => {
                           setCommunity(e)
                        }}
                     />}
                  </div>
               </div>

               {/* Layer*/}
               <div className={s.selectContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleLayer" tagName="span" />
                  </div>
                  {/* {submitErr ? <p className={s.messageErr}>
                     <FormattedMessage id="createEvent.title.titleObligatory" tagName="span" />
                  </p> : false} */}
                  {errors.layer?.type === 'required' && <p className={s.messageErr}>
                     <FormattedMessage id="createTournament.title.titleObligatory" tagName="span" />
                  </p>}
                  <div className={s.select} style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}>
                     <Controller
                        name="layer"
                        control={control}
                        rules={{ required: true }}
                        render={({
                           field
                        }) => <Select
                              onChange={(e) => {
                                 field.onChange(e)
                                 setLayer(e)
                              }}
                              value={layer}
                              classNamePrefix='custom_select'
                              options={layersOptions}
                              placeholder={layerPlaceholder}
                              isMulti={false}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                           />}
                     />
                  </div>
                  {/* <div className={s.select}>
                     <Select
                        value={layer}
                        classNamePrefix='custom_select'
                        options={EVENT_LAYERS}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        name="layer"
                        isMulti={false}
                        placeholder={layerPlaceholder}
                        onChange={(e) => changeSelectValue(e, setLayer)}
                        onBlur={(e) => chakedSelct(e)}
                     />
                  </div> */}
               </div>

               <div className={s.btnContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleCircuit" tagName="span" />
                  </div>
                  <a target="_blank" href="https://about.passport.gg/officialstatusrequest" rel="noreferrer">
                     <button
                        type='button'
                        style={{
                           padding: 10,
                           width: 300,
                           fontSize: 14,
                           background: isHOGDomain() && "rgb(132, 100, 20)"
                        }}>Request affiliation to circuit and grade</button>
                  </a>
               </div>

               <div className={s.btnContainer}>
                  <div className={s.text}>
                     <FormattedMessage id="createEvent.title.titleRating" tagName="span" />
                  </div>
                  <a target="_blank" href="https://about.passport.gg/officialstatusrequest" rel="noreferrer">
                     <button
                        type='button'
                        style={{
                           padding: 10,
                           width: 300,
                           fontSize: 14,
                           background: isHOGDomain() && "rgb(132, 100, 20)"
                        }}>
                        <FormattedMessage id="createEvent.title.btnRequest" tagName="span" />
                     </button>
                  </a>
               </div>

               <div className={s.inputContainerLower}>
                  <div style={{
                     fontWeight: 500,
                     fontSize: 17,
                     marginTop: 46
                  }} className={s.inputText}>
                     <FormattedMessage id="createEvent.title.titleTeamOrganizer" tagName="span" />
                  </div>
                  {inputTeamOrganizer.map((el, i) =>
                     <div className={`${s.inputBlock} ${s.inputBr}`}>
                        <div className={s.inputContainer}>
                           {teamNameErr && <p className={s.messageErr} style={{
                              top: '-17px',
                              left: 0
                           }}>
                              Value is too short
                           </p>}
                           <input
                              style={{ fontSize: 16 }}
                              type="text"
                              placeholder={teamOrganizerNamePlaceholder}
                              value={inputTeamOrganizer[i].name}
                              onChange={(e) => onChangeDudleInput(e, i, "name", setInputTeamOrganizer, inputTeamOrganizer, setTeamNameErr)}
                           />
                        </div>
                        <div className={s.inputContainer} style={{ marginTop: 0, marginBottom: 0 }}>
                           {teamValueErr && <p className={s.messageErr} style={{
                              top: '-17px',
                              left: 0
                           }}>
                              Value is too short
                           </p>}
                           <input
                              style={{ fontSize: 16 }}
                              type="text"
                              placeholder={teamOrganizerTitlePlaceholder}
                              value={inputTeamOrganizer[i].title}
                              onChange={(e) => onChangeDudleInput(e, i, "title", setInputTeamOrganizer, inputTeamOrganizer, setTeamValueErr)}
                           />
                        </div>
                        <button onClick={() => handleDelete(i, inputTeamOrganizer, setInputTeamOrganizer)} style={{ width: 50, cursor: 'pointer', marginBottom: 5, background: isHOGDomain() && "rgb(132, 100, 20)"  }} type='button'><DeleteOutlinedIcon /></button>
                     </div>
                  )}

                  <div
                     className={s.addImg}
                     style={{ marginTop: 10, background: isHOGDomain() && "rgb(132, 100, 20)" }}
                     onClick={() => addInputs(inputTeamOrganizer, setInputTeamOrganizer)}>
                  </div>

                  <div style={{
                     fontWeight: 500,
                     fontSize: 17,
                     marginTop: 46
                  }} className={s.inputText}>
                     <FormattedMessage id="createEvent.title.titleContacts" tagName="span" />
                  </div>
                  {inputArrContacts.map((el, i) =>
                     <div className={`${s.inputBlock} ${s.inputBr}`}>

                        <div className={s.inputContainer}>
                           {contactNameErr && <p className={s.messageErr} style={{
                              top: '-17px',
                              left: 0
                           }}>
                              Value is too short
                           </p>}
                           <input
                              style={{ fontSize: 16 }}
                              type="text"
                              placeholder={contactsNamePlaceholder}
                              value={inputArrContacts[i].name}
                              onChange={(e) => onChangeDudleInput(e, i, "name", setInputArrContacts, inputArrContacts, setContactNameErr)}
                           />
                        </div>
                        <div className={s.inputContainer}>
                           {contactValueErr && <p className={s.messageErr} style={{
                              top: '-17px',
                              left: 0
                           }}>
                              Value is too short
                           </p>}
                           <input
                              style={{ fontSize: 16 }}
                              type="text"
                              placeholder={contactsNameTitlePlaceholder}
                              value={inputArrContacts[i].title}
                              onChange={(e) => onChangeDudleInput(e, i, "title", setInputArrContacts, inputArrContacts, setContactValueErr)}
                           />
                        </div>
                        <button onClick={() => handleDelete(i, inputArrContacts, setInputArrContacts)} style={{ width: 50, cursor: 'pointer', marginBottom: 5, background: isHOGDomain() && "rgb(132, 100, 20)"  }} type='button'><DeleteOutlinedIcon /></button>
                     </div>
                  )}

                  <div className={s.addBtnContainer}>
                     <div
                        className={s.addImg}
                        style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
                        onClick={() => addInputs(inputArrContacts, setInputArrContacts)}>
                     </div>
                  </div>

               </div>

               <div className={s.socialNetworksContainer}>
                  <div style={{
                     fontWeight: 500,
                     fontSize: 17,
                     marginTop: 46,
                     marginBottom: 20
                  }} className={s.inputText}>
                     <FormattedMessage id="createEvent.title.titleSocialNetworks" tagName="p" />
                  </div>
                  <div className={s.inputBlock}>
                     {facebookErr ? <p className={s.messageErr}>
                        <FormattedMessage id="err.errMessage.СheckFBData" tagName="span" />
                     </p> : false}
                     <div className={s.title}>
                        <FormattedMessage id="createEvent.title.titleFacebook" tagName="span" />
                     </div>
                     <div className={s.inputContainer}>
                        <input
                           type="text"
                           placeholder={socialNetworksLinkPlaceholder}
                           className={facebookErr ? s.inputErr : false}
                           value={facebookLink}
                           onChange={(e) => changeTextValue(e.target.value, setFacebookLink, setFacebookErr)}
                           onBlur={(e) => chakedSocialNetworks(e, 'facebook', setFacebookErr)}
                        />
                     </div>
                  </div>
                  <div className={s.inputBlock}>
                     {instagramErr ? <p className={s.messageErr}>
                        <FormattedMessage id="err.errMessage.СheckInstData" tagName="span" /></p> : false}
                     <div className={s.title}>
                        <FormattedMessage id="createEvent.title.titleInstagram" tagName="span" />
                     </div>
                     <div className={s.inputContainer}>
                        <input
                           type="text"
                           placeholder={socialNetworksLinkPlaceholder}
                           className={instagramErr ? s.inputErr : false}
                           value={instagramLink}
                           onChange={(e) => changeTextValue(e.target.value, setInstagramLink, setInstagramErr)}
                           onBlur={(e) => chakedSocialNetworks(e, 'instagram', setInstagramErr)}
                        />
                     </div>
                  </div>
                  <div className={s.inputBlock}>
                     {discordErr ? <p className={s.messageErr}>
                        <FormattedMessage id="err.errMessage.СheckDiscordData" tagName="span" />
                     </p> : false}
                     <div className={s.title}>
                        <FormattedMessage id="createEvent.title.titleDiscord" tagName="span" />
                     </div>
                     <div className={s.inputContainer}>
                        <input
                           type="text"
                           placeholder={socialNetworksLinkPlaceholder}
                           className={discordErr ? s.inputErr : false}
                           value={discordLink}
                           onChange={(e) => changeTextValue(e.target.value, setDiscordLink, setDiscordErr)}
                           onBlur={(e) => chakedSocialNetworks(e, 'discord', setDiscordErr)}
                        />
                     </div>
                  </div>
                  <div className={s.inputBlock}>
                     {twitterErr ? <p className={s.messageErr}>
                        <FormattedMessage id="err.errMessage.СheckTwitterData" tagName="span" />
                     </p> : false}
                     <div className={s.title}>
                        <FormattedMessage id="createEvent.title.titleTwitter" tagName="span" />
                     </div>
                     <div className={s.inputContainer}>
                        <input
                           type="text"
                           placeholder={socialNetworksLinkPlaceholder}
                           className={twitterErr ? s.inputErr : false}
                           value={twitterLink}
                           onChange={(e) => changeTextValue(e.target.value, setTwitterLink, setTwitterErr)}
                           onBlur={(e) => chakedSocialNetworks(e, 'twitter', setTwitterErr)}
                        />
                     </div>
                  </div>
               </div>

               <div className={s.inputMainBottomContainer}>
                  <div className={s.imageContainer}>
                     <div className={s.imgTitle}>
                        <span style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}>
                           <FormattedMessage id="createEvent.title.titlePhotos" />
                        </span>
                     </div>
                     {photoAnyPhotos.map((el, i) => <div>

                        <div key={i} className={s.btnContainer}>
                           <div
                              className={s.delBtn}
                              onClick={() => delPhoto(i, setInputAnyPhotos, photoAnyPhotos, editId)}
                           ></div>
                           <FileUploaderSingle
                              style={{ marginTop: 0 }}
                              defaultFoto={props.uploadImg}
                              imageURL={photoAnyPhotos[i]}
                              htmlFor={`anyPhotos${i}`}
                              isMany={true}
                              onChangeDudlePhotos={onChangeDudlePhotos}
                              id={i}
                              fn={setInputAnyPhotos}
                              data={photoAnyPhotos}
                           />
                        </div>

                     </div>)}
                  </div>

                  <div className={s.addBtnContainer}>
                     <button
                        type='button'
                        className={s.addBtn}
                        style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
                        onClick={() => addPhotos(photoAnyPhotos, setInputAnyPhotos)}>
                        <FormattedMessage id="createEvent.btn.addAnyPhotosLogo" tagName="span" />
                     </button>
                  </div>
               </div>
            </div>
            <div style={{
               display: 'flex',
               paddingBottom: 24,
               justifyContent: 'center',
               marginTop: 46
            }}>
               <button style={{ height: 50, fontSize: 18, fontWeight: 500, padding: 15, width: 300, background: isHOGDomain() && "rgb(132, 100, 20)"  }} type='submit'>{!editId ? 'Create event' : 'Edit event'}</button>
            </div >
         </form>
      </div>
   )
}
export default CreateEvent;
