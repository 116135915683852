export const uploadPhotos = (tournamentToEdit, editId, res, uploadData, backgroundLogo, tournamentLogo, photoArrSponsorlogo, photoArrPartnerlogo, photoArrOrganizerlogo, photoAnyPhotos) => {
    setTimeout(() => {
        if (backgroundLogo.length > 0 && !editId) {
            uploadData(backgroundLogo, res.id, 'tournamentPageHeaderBanner')
        } else if (backgroundLogo.length > 0 && editId) {
            if (tournamentToEdit.media.pageHeaderBanner !== backgroundLogo) {
                uploadData(backgroundLogo, res.id, 'tournamentPageHeaderBanner')
            }
        }
    }, 0)

    //
    setTimeout(() => {
        if (tournamentLogo.length > 0 && !editId) {
            uploadData(tournamentLogo, res.id, 'tournamentLogo')
        } else if (backgroundLogo.length > 0 && editId) {
            if (tournamentToEdit.media.logo !== tournamentLogo) {
                uploadData(tournamentLogo, res.id, 'tournamentLogo')
            }
        }
    }, 2000)

    function filterImages(images, backendImages) {
        const newArr = images.filter(item => {
            return !backendImages.includes(item)
        })
        return newArr
    }

    setTimeout(() => {
        if (photoArrSponsorlogo != '1' && !editId) {
            photoArrSponsorlogo.map(el => uploadData(el, res.id, 'tournamentSponsorLogo'));
        } else if (photoArrSponsorlogo != '1' && editId) {
            filterImages(photoArrSponsorlogo, tournamentToEdit.media.sponsorLogo).map(el => uploadData(el, res.id, 'tournamentSponsorLogo'));
        }
    }, 2000)
    setTimeout(() => {
        if (photoArrPartnerlogo != '1' && !editId) {
            photoArrPartnerlogo.map(el => uploadData(el, res.id, 'tournamentPartnerLogo'));
        } else if (photoArrPartnerlogo != '1' && editId) {
            filterImages(photoArrPartnerlogo, tournamentToEdit.media.partnerLogo).map(el => uploadData(el, res.id, 'tournamentPartnerLogo'));
        }
    }, 3000)
    setTimeout(() => {
        if (photoArrOrganizerlogo != '1' && !editId) {
            photoArrOrganizerlogo.map(el => uploadData(el, res.id, 'tournamentOrganizerLogo'));
        } else if (photoArrOrganizerlogo != '1' && editId) {
            filterImages(photoArrOrganizerlogo, tournamentToEdit.media.organizerLogo).map(el => uploadData(el, res.id, 'tournamentOrganizerLogo'));
        }
    }, 4000)
    setTimeout(() => {
        if (photoAnyPhotos != '1' && !editId) {
            photoAnyPhotos.map(el => uploadData(el, res.id, 'tournamentAnyPhotos'));
        } else if (photoAnyPhotos != '1' && editId) {
            filterImages(photoAnyPhotos, tournamentToEdit.media.anyPhotos).map(el => uploadData(el, res.id, 'tournamentAnyPhotos'));
        }
    }, 5000)
}