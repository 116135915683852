export const formatGame = [
  { id: 1, name: "1v1", backName: 1 },
  { id: 2, name: "2v2", backName: 2 },
  { id: 3, name: "3v3", backName: 3 },
  { id: 4, name: "4v4", backName: 4 },
  { id: 5, name: "5v5", backName: 5 },
  { id: 6, name: "6v6", backName: 6 },
  { id: 7, name: "7v7", backName: 7 },
  { id: 8, name: "8v8", backName: 8 },
  { id: 9, name: "9v9", backName: 9 },
  { id: 10, name: "10v10", backName: 10 },
  { id: 11, name: "11v11", backName: 11 },
  { id: 12, name: "12v12", backName: 12 },
  { id: 13, name: "13v13", backName: 13 },
  { id: 14, name: "14v14", backName: 14 },
  { id: 15, name: "15v15", backName: 15 },
  { id: 16, name: "16v16", backName: 16 },
  { id: 17, name: "17v17", backName: 17 },
  { id: 18, name: "18v18", backName: 18 },
  { id: 19, name: "19v19", backName: 19 },
  { id: 20, name: "20v20", backName: 20 },
  { id: 21, name: "21v21", backName: 21 },
  { id: 22, name: "22v22", backName: 22 },
  { id: 23, name: "23v23", backName: 23 },
  { id: 24, name: "24v23", backName: 24 },
  { id: 25, name: "25v25", backName: 25 },
  { id: 26, name: "26v26", backName: 26 },
  { id: 27, name: "27v27", backName: 27 },
  { id: 28, name: "28v28", backName: 28 },
  { id: 29, name: "29v29", backName: 29 },
  { id: 30, name: "30v30", backName: 20 },
  { id: 31, name: "31v31", backName: 31 },
  { id: 33, name: "32v32", backName: 32 },
  { id: 34, name: "33v33", backName: 33 },
  { id: 35, name: "34v34", backName: 34 },
  { id: 36, name: "35v35", backName: 35 },
  { id: 37, name: "36v36", backName: 36 },
  { id: 38, name: "37v37", backName: 37 },
  { id: 39, name: "38v38", backName: 38 },
  { id: 40, name: "39v39", backName: 39 },
  { id: 41, name: "40v40", backName: 40 },
  { id: 42, name: "41v41", backName: 41 },
  { id: 43, name: "42v42", backName: 42 },
  { id: 44, name: "43v43", backName: 43 },
  { id: 45, name: "44v44", backName: 44 },
  { id: 46, name: "45v45", backName: 45 },
  { id: 47, name: "46v46", backName: 46 },
  { id: 48, name: "47v47", backName: 47 },
  { id: 49, name: "48v48", backName: 48 },
  { id: 50, name: "49v49", backName: 49 },
  { id: 51, name: "50v50", backName: 50 },
  { id: 52, name: "51v51", backName: 51 },
  { id: 53, name: "52v52", backName: 52 },
  { id: 54, name: "53v53", backName: 53 },
  { id: 55, name: "54v54", backName: 54 },
  { id: 56, name: "55v55", backName: 55 },
  { id: 57, name: "56v56", backName: 56 },
  { id: 58, name: "57v57", backName: 57 },
  { id: 59, name: "58v58", backName: 55 },
  { id: 60, name: "59v59", backName: 59 },
  { id: 61, name: "60v60", backName: 60 },
  { id: 62, name: "61v61", backName: 61 },
  { id: 63, name: "62v62", backName: 62 },
  { id: 64, name: "63v63", backName: 63 },
  { id: 65, name: "64v64", backName: 64 },
  { id: 66, name: "65v65", backName: 65 },
  { id: 67, name: "66v66", backName: 66 },
  { id: 68, name: "67v67", backName: 67 },
  { id: 69, name: "68v68", backName: 68 },
  { id: 70, name: "69v69", backName: 69 },
  { id: 71, name: "70v70", backName: 70 },
  { id: 72, name: "71v71", backName: 71 },
  { id: 73, name: "72v72", backName: 72 },
  { id: 74, name: "73v73", backName: 73 },
  { id: 75, name: "74v74", backName: 74 },
  { id: 76, name: "75v75", backName: 75 },
  { id: 77, name: "76v76", backName: 76 },
  { id: 78, name: "77v77", backName: 77 },
  { id: 79, name: "78v78", backName: 78 },
  { id: 80, name: "79v79", backName: 79 },
  { id: 81, name: "80v80", backName: 80 },
  { id: 82, name: "81v81", backName: 81 },
  { id: 83, name: "82v82", backName: 82 },
  { id: 84, name: "83v83", backName: 83 },
  { id: 85, name: "84v84", backName: 84 },
  { id: 86, name: "85v85", backName: 85 },
  { id: 87, name: "86v86", backName: 86 },
  { id: 88, name: "87v87", backName: 87 },
  { id: 89, name: "88v88", backName: 88 },
  { id: 90, name: "89v89", backName: 89 },
  { id: 91, name: "90v90", backName: 90 },
  { id: 92, name: "91v91", backName: 91 },
  { id: 93, name: "92v92", backName: 92 },
  { id: 94, name: "93v93", backName: 93 },
  { id: 95, name: "94v94", backName: 94 },
  { id: 96, name: "95v95", backName: 95 },
  { id: 97, name: "96v96", backName: 96 },
  { id: 98, name: "97v97", backName: 97 },
  { id: 99, name: "98v98", backName: 98 },
  { id: 100, name: "99v99", backName: 99 },
  { id: 101, name: "100v100", backName: 100 },
];

export const EVENT_TYPES = [
  { id: 1, name: "online" },
  { id: 2, name: "offline" },
  { id: 3, name: "mixed" },
];

export const GENDER = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Other" },
];

export const SYSTEM = [
  { id: 1, name: "Single elimination", backName: "singleElimination" },
  { id: 2, name: "Double elimination", backName: "doubleElimination" },
  { id: 3, name: "Round-robin", backName: "roundRobin" },
  //  { id: 4, name: 'Round-robin with single elemenation', backName: 'roundRobinWithSingleElimination' },
  //  { id: 5, name: 'Round-robin with double elemenation', backName: 'roundRobinWithDoubleElimination' },
  { id: 4, name: "Battle-royale (FFA)", backName: "FFA" },
];

export const FORMAT = [
  { id: 1, name: "BO1", back: 1 },
  { id: 2, name: "BO2", back: 2 },
  { id: 3, name: "BO3", back: 3 },
  { id: 4, name: "BO5", back: 5 },
  { id: 5, name: "BO7", back: 7 },
];

export const PLATFORM = [
  { id: "PC", name: "PC" },
  { id: "Android", name: "Android" },
  { id: "iOS", name: "iOS" },
  { id: "Xbox One", name: "Xbox One" },
  { id: "PlayStation 4", name: "PlayStation 4" },
  { id: "Xbox Series X/S", name: "Xbox Series X/S" },
  { id: "PlayStation 5", name: "PlayStation 5" },
];

export const AFRICA_LAYERS = [
  { id: 1, name: "International" },
  { id: 2, name: "Regional" },
  { id: 3, name: "National" },
  { id: 4, name: "Local" },
  { id: 5, name: "SAGES legendary series" },
];

export const LAYERS = [
  { id: 1, name: "International" },
  { id: 2, name: "Regional" },
  { id: 3, name: "National" },
  { id: 4, name: "Local" },
];
