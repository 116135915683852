import React from "react";
import { useSelector } from "react-redux";
import s from "./ProfilePanel.module.scss";

import { FormattedMessage } from "react-intl";

import userPhoto from "../../../img/avatars/User_ProfilePanel.png";
//icon for menu
import activeMembers from "../../../img/Icon_for_menu/Icon_for_active_menu/groupWhite.png";
import disableMembers from "../../../img/Icon_for_menu/Icon_for_disable_menu/groupLilac.png";
import activeEventsIcon from "../../../img/Icon_for_menu/Icon_for_active_menu/Events.png";
import disableEventsIcon from "../../../img/Icon_for_menu/Icon_for_disable_menu/Events.png";
import activeTournamentsIcon from "../../../img/Icon_for_menu/Icon_for_active_menu/Tournaments.png";
import disableTournamentsIcon from "../../../img/Icon_for_menu/Icon_for_disable_menu/Tournaments.png";
import activeRequestsIcon from "../../../img/Icon_for_menu/Icon_for_active_menu/Requests.png";
import disableRequestsIcon from "../../../img/Icon_for_menu/Icon_for_disable_menu/Requests.png";
import activeMatchesIcon from "../../../img/Icon_for_menu/Icon_for_active_menu/Matches.png";
import disableMatchesIcon from "../../../img/Icon_for_menu/Icon_for_disable_menu/Matches.png";
import activeCommunityIcon from "./community-disabled.png";
import disableCommunityIcon from "./community.png";
//для Logs нет активной иконки
/* import activeLogsIcon from '../../../img/Icon_for_menu/Icon_for_active_menu/'; */
import disableLogsIcon from "../../../img/Icon_for_menu/Icon_for_disable_menu/Logs.png";
import SettingsInputSvideoIcon from "@material-ui/icons/SettingsInputSvideo";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";

import activeLandingsIcon from "../../../img/Icon_for_menu/Landing_button.png";

import { useState, useEffect } from "react";

import { NavLink, Router } from "react-router-dom";
import { ROUTER } from "../../../config";
import store from "../../../redux/store";
import { userOffline, setUserData } from "../../../redux/actions";
import { API } from "../../../api";
import { API_ROUTER } from "../../../api";
import { isHOGDomain } from "../../../comon/isHOGDomain";

const ProfilePanel = (props) => {
  /* const screenWidth = window.screen.width;
   const screenHeight = window.screen.heigh; */

  const userData = useSelector((state) => state.userData);
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    let params = {
      ...API_ROUTER.user.getUserRights,
    };
    API.request(params)
      .then((res) => {
        setSuperAdmin(res.isSuperAdmin);
      })
      .catch((err) => console.log(err));
  }, []);

  const getTime = (d) => {
    let currentTimeZoneOffsetInHours = (d.getTimezoneOffset() / 60) * -1;
    return `${d.getHours()}:${d.getMinutes()} UTC +${currentTimeZoneOffsetInHours}`;
  };
  const [currentTime, setCurrentTime] = useState(getTime(new Date()));

  const getData = (d) => {
    if (window.screen.width > 852) {
      return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`;
    } else {
      const year = d.toLocaleDateString(undefined, { year: "2-digit" });
      return `${d.getDate()}.${d.getMonth()}.${year}`;
    }
  };
  const [currentData, setCurrentData] = useState(getData(new Date()));

  useEffect(() => {
    const time = setInterval(() => {
      setCurrentTime(getTime(new Date()));
      setCurrentData(getData(new Date()));
    }, 2000);
    return () => clearInterval(time);
  });

  const userAvatar = userData?.avatar;

  return (
    <div
      className={s.profilePanel}
      style={{
        background: `${isHOGDomain() && "#1e181e"}`,
        color: `${isHOGDomain() && "#000000"}`,
      }}
    >
      <div className={s.topContainer}>
        <p
          className={s.communityMainTitle}
          style={{ color: `${isHOGDomain() && "#d7bc33"}` }}
        >
          <a
            style={{ cursor: "pointer" }}
            className={s.communityMainTitle}
            href="https://app.passport.gg"
          >
            Passport.GG{" "}
          </a>
          <FormattedMessage
            id="profilePanel.pageName.mainTitle"
            tagName="span"
          />
        </p>
        <p
          className={s.communityTitle}
          style={{ color: `${isHOGDomain() && "#d7bc33"}` }}
        >
          <FormattedMessage id="profilePanel.pageName.edit" tagName="span" />
        </p>
        {/* <div className={s.edit} style={{ color: `${isHOGDomain() && '#d7bc33'}` }} >{userData?.account || 'Standart account'}</div> */}

        <div className={s.imgContainer}>
          <img src={userAvatar || userPhoto} alt="userPhoto" />
        </div>
        <a
          target="_blank"
          style={{ color: `${isHOGDomain() ? "#d7bc33" : "white"}` }}
          href={`https://app.passport.gg/id/${userData?.url}`}
          rel="noreferrer"
        >
          <div className={s.userName}>
            {userData ? userData.nickname : "ADMINISTATOR"}
          </div>
        </a>
        {/* <NavLink
               label={<div className={s.userName}>{userData ? userData.nickname : 'ADMINISTATOR'}</div>}
               to={`/id/${userData.url}`} handleClick={hideOnClick} /> */}
        {/* <div className={s.userName}>{userData ? userData.nickname : 'ADMINISTATOR'}</div> */}
        <div className={s.navLinkContainer}>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.dashboard}
          >
            <div className={s.iconMenu}>
              <div className={s.imgContainerMenu}>
                <DashboardIcon style={{ width: 25, height: 25 }} />
              </div>
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage
                id="profilePanel.menu.itemDashboard"
                tagName="span"
              />
            </div>
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.membersPage}
          >
            <div className={s.iconMenu}>
              <div className={s.imgContainerMenu}>
                <img
                  className={s.imgActive}
                  src={activeMembers}
                  alt="Members"
                />
                <img
                  className={s.imgDisable}
                  src={disableMembers}
                  alt="Members"
                />
              </div>
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item1" tagName="span" />
            </div>
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.rolesManagemantPage}
            style={{ height: 68 }}
          >
            <div className={s.iconMenu}>
              <div className={s.imgContainerMenu}>
                <PeopleIcon style={{ width: 25, height: 25 }} />
              </div>
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage
                id="profilePanel.menu.itemRoles"
                tagName="span"
              />
            </div>
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.landings}
            /* to={ROUTER} */
          >
            <div className={s.iconMenu}>
              <img
                className={s.imgActive}
                src={activeLandingsIcon}
                alt="landings"
              />
              <img
                className={s.imgDisable}
                src={activeLandingsIcon}
                alt="landings"
              />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item8" tagName="span" />
            </div>
            {/* <i className='iconLock' /> */}
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.communitysPage}
          >
            <div
              className={s.iconMenu}
              style={{
                width: 25,
                height: 25,
                top: 9,
                left: 16,
              }}
            >
              <img
                className={s.imgActive}
                src={disableCommunityIcon}
                alt="communities"
              />
              <img
                className={s.imgDisable}
                src={activeCommunityIcon}
                alt="communities"
              />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item2" tagName="span" />
            </div>
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.evensPage}
            /* to={ROUTER} */
          >
            <div className={s.iconMenu}>
              <img
                className={s.imgActive}
                src={activeEventsIcon}
                alt="events"
              />
              <img
                className={s.imgDisable}
                src={disableEventsIcon}
                alt="events"
              />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item3" tagName="span" />
            </div>
            {/* <i className='iconLock' /> */}
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            to={ROUTER.tournamentsPage}
            /* to={ROUTER} */
          >
            <div className={s.iconMenu}>
              <img
                className={s.imgActive}
                src={activeTournamentsIcon}
                alt="tournaments"
              />
              <img
                className={s.imgDisable}
                src={disableTournamentsIcon}
                alt="tournaments"
              />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item4" tagName="span" />
            </div>
            {/* <i className='iconLock' /> */}
          </NavLink>
          {/* <NavLink
                  activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
                  className={`${isHOGDomain() ? s.linkHOG : s.link}`}
                   to={ROUTER.requestsPage} 
                  to={ROUTER}
               >
                  <div className={s.iconMenu}>
                     <img className={s.imgActive} src={activeRequestsIcon} alt="requests" />
                     <img className={s.imgDisable} src={disableRequestsIcon} alt="requests" />
                  </div>
                  <div className={s.menuIcon}><FormattedMessage id="profilePanel.menu.item5" tagName="span" /></div>
                  <i className='iconLock' />
               </NavLink> */}
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            /* to={ROUTER.logsPage} */
            to={ROUTER}
          >
            <div className={s.iconMenu}>
              <img className={s.imgActive} src={disableLogsIcon} alt="logs" />
              <img className={s.imgDisable} src={disableLogsIcon} alt="logs" />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item7" tagName="span" />
            </div>
            <i className="iconLock" />
          </NavLink>
          <NavLink
            activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
            className={`${isHOGDomain() ? s.linkHOG : s.link}`}
            /* to={ROUTER.matchesPage} */
            to={ROUTER}
          >
            <div className={s.iconMenu}>
              <img
                className={s.imgActive}
                src={activeMatchesIcon}
                alt="matches"
              />
              <img
                className={s.imgDisable}
                src={disableMatchesIcon}
                alt="matches"
              />
            </div>
            <div className={s.menuIcon}>
              <FormattedMessage id="profilePanel.menu.item6" tagName="span" />
            </div>
            <i className="iconLock" />
          </NavLink>
          {isSuperAdmin && (
            <NavLink
              activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
              className={`${isHOGDomain() ? s.linkHOG : s.link}`}
              to={ROUTER.accessPage}
            >
              <div className={s.menuIcon}>
                <FormattedMessage id="profilePanel.menu.item9" tagName="span" />
              </div>
            </NavLink>
          )}
          {false && (
            <NavLink
              style={{ height: 68 }}
              activeClassName={`${isHOGDomain() ? s.activeHOG : s.active}`}
              className={`${isHOGDomain() ? s.linkHOG : s.link}`}
              to={ROUTER.ratingPage}
            >
              <div className={s.iconMenu}>
                <SettingsInputSvideoIcon style={{ width: 25, height: 25 }} />
              </div>
              <div className={s.menuIcon}>
                <FormattedMessage
                  id="profilePanel.menu.item10"
                  tagName="span"
                />
              </div>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProfilePanel;
