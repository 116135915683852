import React from "react";
import s from '../../comon/ComonAdmin.module.scss';

import { FormattedMessage } from "react-intl";

export const pageInfo = {
   pageName: <FormattedMessage id="members.title.pageName" tagName="span" />,
   filter: true,
   periodContainer: false,
}
export const titleHeader = [
   <FormattedMessage id="members.title.nickname" tagName="span" />,
   <FormattedMessage id="members.title.firstName" tagName="span" />,
   <FormattedMessage id="members.title.lastName" tagName="span" />,
   <FormattedMessage id="members.title.country" tagName="span" />,
   <FormattedMessage id="members.title.city" tagName="span" />,
   <><FormattedMessage id="members.title.games" tagName="span" /><div style={{ marginTop: '-40px' }}> - Nicknames</div></>
   ,
   // <><div>Last match</div><div style={{ marginTop: '-40px' }}><FormattedMessage id="members.title.lastMatchTicker" tagName="span" /></div></>,
   <><div>Registration</div><div style={{ marginTop: '-40px' }}><FormattedMessage id="members.title.rigistration" tagName="span" /></div></>,
   <FormattedMessage id="members.title.verified" tagName="span" />,
   <FormattedMessage id="members.title.pro" tagName="span" />,
   <FormattedMessage id="members.title.banned" tagName="span" />
];
export const headerItem = {
   "rows": [
      {
         "nickname": "Loading...",
         "firstName": "Loading...",
         "lastName": "Loading...",
         "country": "Loading...",
         "city": "Loading...",
         "games": "Loading...",
         "registrationDate": "Loading...",
         "verified": "Loading...",
         "pro": "Loading...",
         "banned": "Loading...",
      },
   ]
}

/* export const btnEvensObj = {
   topBtn: {
      edit: 'Edit Event', img1: editImage, to1: '/admin/editEvent',
      create: 'Create Tournament', img2: createImage,
   },
   hiddenBtn: [
      { name: 'Statistics', img: statisticsImage },
      { name: 'Tournament List', img: tournamentsImage },
   ]
} */