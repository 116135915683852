import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Modal } from "../../../components/UI";
import { API_ROUTER, API } from "../../../api";

import styles from "./styles.module.scss";

export default function MoreInfoMemeberModal({
  openModal,
  setOpenModal,
  userGames,
  userNick,
}) {
  return (
    <Modal
      open={openModal}
      isTransparent={true}
      onClose={() => setOpenModal(!true)}
      closeButton={true}
    >
      <div className={styles.dialog}>
        <div className={styles.title}>
          Games of <span className={styles.user}>{userNick}</span>{" "}
        </div>
        <div>
          <div className={styles.boxDialogContent}>
            <div className={styles.inputContainer}>
              <div className={styles.userGames}>
                {userGames?.map((game) => (
                  <div className={styles.userGamesContainer}>
                    <div className={styles.gameContainer}>
                      <div className={styles.gameTitle}>Game : </div>
                      <div className={styles.gameName}> {game.game.title}</div>
                    </div>
                    <div className={styles.nickContainer}>
                      <div className={styles.nickTitle}>Nickname : </div>
                      <div className={styles.nickname}> {game.nickname}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
