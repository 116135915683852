import { initialErrors } from "../const";
import { isFieldEmpty } from "../../../../helpers";
const checkErrors = (
  formFields,
  setErrors,
  errors,
  selectLanguages,
  setErrorField
) => {
  const newErrors = initialErrors;
  // newErrors.textBlockOrganizer1 = isFieldEmpty(formFields.textBlockOrganizer1);
  // newErrors.textBlockOrganizer2 = isFieldEmpty(formFields.textBlockOrganizer2);
  // newErrors.textBlockOrganizer3 = isFieldEmpty(formFields.textBlockOrganizer3);

  newErrors.textBlockSuccessMsg = isFieldEmpty(formFields.textBlockSuccessMsg);

  // newErrors.textBlockTitle1 = isFieldEmpty(formFields.textBlockTitle1);
  // newErrors.textBlockTitle2 = isFieldEmpty(formFields.textBlockTitle2);
  // newErrors.textBlockTitle3 = isFieldEmpty(formFields.textBlockTitle3);
  // newErrors.textBlockTitle4 = isFieldEmpty(formFields.textBlockTitle4);
  newErrors.textBlockRegisterError1 = isFieldEmpty(
    formFields.textBlockRegisterError1
  );
  newErrors.textBlockRegisterError2 = isFieldEmpty(
    formFields.textBlockRegisterError2
  );
  newErrors.textBlockRegisterError3 = isFieldEmpty(
    formFields.textBlockRegisterError3
  );
  newErrors.textBlockRegistration6 = isFieldEmpty(
    formFields.textBlockRegistration6
  );
  newErrors.textBlockRegistration7 = isFieldEmpty(
    formFields.textBlockRegistration7
  );
  newErrors.textBlockRegistration8 = isFieldEmpty(
    formFields.textBlockRegistration8
  );
  newErrors.textBlockRegistration9 = isFieldEmpty(
    formFields.textBlockRegistration9
  );
  newErrors.textBlockRegistration10 = isFieldEmpty(
    formFields.textBlockRegistration10
  );
  newErrors.textBlockRegistration11 = isFieldEmpty(
    formFields.textBlockRegistration11
  );
  newErrors.textBlockRegistration12 = isFieldEmpty(
    formFields.textBlockRegistration12
  );
  newErrors.textBlockRegistration13 = isFieldEmpty(
    formFields.textBlockRegistration13
  );
  newErrors.textBlockRegistration14 = isFieldEmpty(
    formFields.textBlockRegistration14
  );
  newErrors.textBlockRegistration5 = isFieldEmpty(
    formFields.textBlockRegistration5
  );
  newErrors.textBlockRegistration4 = isFieldEmpty(
    formFields.textBlockRegistration4
  );
  newErrors.textBlockRegistration3 = isFieldEmpty(
    formFields.textBlockRegistration3
  );
  newErrors.textBlockRegistration2 = isFieldEmpty(
    formFields.textBlockRegistration2
  );
  newErrors.textBlockRegistration1 = isFieldEmpty(
    formFields.textBlockRegistration1
  );
  newErrors.textBlockButtons5 = isFieldEmpty(formFields.textBlockButtons5);
  newErrors.textBlockButtons4 = isFieldEmpty(formFields.textBlockButtons4);
  newErrors.textBlockButtons3 = isFieldEmpty(formFields.textBlockButtons3);
  newErrors.textBlockButtons2 = isFieldEmpty(formFields.textBlockButtons2);
  newErrors.textBlockButtons1 = isFieldEmpty(formFields.textBlockButtons1);

  newErrors.textBlockAbout25 = isFieldEmpty(formFields.textBlockAbout25);
  newErrors.textBlockAbout24 = isFieldEmpty(formFields.textBlockAbout24);
  newErrors.textBlockAbout23 = isFieldEmpty(formFields.textBlockAbout23);
  newErrors.textBlockAbout22 = isFieldEmpty(formFields.textBlockAbout22);
  newErrors.textBlockAbout21 = isFieldEmpty(formFields.textBlockAbout21);

  newErrors.textBlockAbout20 = isFieldEmpty(formFields.textBlockAbout20);
  newErrors.textBlockAbout19 = isFieldEmpty(formFields.textBlockAbout19);
  newErrors.textBlockAbout18 = isFieldEmpty(formFields.textBlockAbout18);
  newErrors.textBlockAbout17 = isFieldEmpty(formFields.textBlockAbout17);
  newErrors.textBlockAbout16 = isFieldEmpty(formFields.textBlockAbout16);

  newErrors.textBlockAbout15 = isFieldEmpty(formFields.textBlockAbout15);
  newErrors.textBlockAbout14 = isFieldEmpty(formFields.textBlockAbout14);
  newErrors.textBlockAbout13 = isFieldEmpty(formFields.textBlockAbout13);
  newErrors.textBlockAbout12 = isFieldEmpty(formFields.textBlockAbout12);
  newErrors.textBlockAbout11 = isFieldEmpty(formFields.textBlockAbout11);
  newErrors.textBlockAbout10 = isFieldEmpty(formFields.textBlockAbout10);
  newErrors.textBlockAbout9 = isFieldEmpty(formFields.textBlockAbout9);
  newErrors.textBlockAbout8 = isFieldEmpty(formFields.textBlockAbout8);
  newErrors.textBlockAbout7 = isFieldEmpty(formFields.textBlockAbout7);
  newErrors.textBlockAbout6 = isFieldEmpty(formFields.textBlockAbout6);
  newErrors.textBlockAbout5 = isFieldEmpty(formFields.textBlockAbout5);
  newErrors.textBlockAbout4 = isFieldEmpty(formFields.textBlockAbout4);
  newErrors.textBlockAbout3 = isFieldEmpty(formFields.textBlockAbout3);
  newErrors.textBlockAbout2 = isFieldEmpty(formFields.textBlockAbout2);
  newErrors.textBlockAbout1 = isFieldEmpty(formFields.textBlockAbout1);
  newErrors.textBlockContent2 = isFieldEmpty(formFields.textBlockContent2);
  newErrors.textBlockContent1 = isFieldEmpty(formFields.textBlockContent1);
  newErrors.domain = isFieldEmpty(formFields?.domain);
  // newErrors.textDescription = isFieldEmpty(formFields.textDescription);
  // newErrors.textTitle = isFieldEmpty(formFields.textTitle);
  newErrors.country = isFieldEmpty(formFields.country.value);
  if (!Object.values(newErrors).some((value) => value.length)) {
    selectLanguages.forEach((el, index) => {
      if (index === 0) {
        newErrors.textBlockAbout1 = isFieldEmpty(el.textBlockAbout1);
        newErrors.textBlockAbout2 = isFieldEmpty(el.textBlockAbout2);
        newErrors.textBlockAbout3 = isFieldEmpty(el.textBlockAbout3);
        newErrors.textBlockAbout4 = isFieldEmpty(el.textBlockAbout4);
        newErrors.textBlockAbout5 = isFieldEmpty(el.textBlockAbout5);
        newErrors.textBlockAbout6 = isFieldEmpty(el.textBlockAbout6);
        newErrors.textBlockAbout7 = isFieldEmpty(el.textBlockAbout7);
        newErrors.textBlockAbout8 = isFieldEmpty(el.textBlockAbout8);
        newErrors.textBlockAbout9 = isFieldEmpty(el.textBlockAbout9);
        newErrors.textBlockAbout10 = isFieldEmpty(el.textBlockAbout10);
        newErrors.textBlockAbout11 = isFieldEmpty(el.textBlockAbout11);
        newErrors.textBlockAbout12 = isFieldEmpty(el.textBlockAbout12);
        newErrors.textBlockAbout13 = isFieldEmpty(el.textBlockAbout13);
        newErrors.textBlockAbout14 = isFieldEmpty(el.textBlockAbout14);
        newErrors.textBlockAbout15 = isFieldEmpty(el.textBlockAbout15);
        newErrors.textBlockAbout16 = isFieldEmpty(el.textBlockAbout16);
        newErrors.textBlockAbout17 = isFieldEmpty(el.textBlockAbout17);
        newErrors.textBlockAbout18 = isFieldEmpty(el.textBlockAbout18);
        newErrors.textBlockAbout19 = isFieldEmpty(el.textBlockAbout19);
        newErrors.textBlockAbout20 = isFieldEmpty(el.textBlockAbout20);

        newErrors.textBlockAbout21 = isFieldEmpty(el.textBlockAbout21);
        newErrors.textBlockAbout22 = isFieldEmpty(el.textBlockAbout22);
        newErrors.textBlockAbout23 = isFieldEmpty(el.textBlockAbout23);
        newErrors.textBlockAbout24 = isFieldEmpty(el.textBlockAbout24);
        newErrors.textBlockAbout25 = isFieldEmpty(el.textBlockAbout25);

        newErrors.textBlockButtons1 = isFieldEmpty(el.textBlockButtons1);
        newErrors.textBlockButtons2 = isFieldEmpty(el.textBlockButtons2);
        newErrors.textBlockButtons3 = isFieldEmpty(el.textBlockButtons3);
        newErrors.textBlockButtons4 = isFieldEmpty(el.textBlockButtons4);
        newErrors.textBlockButtons5 = isFieldEmpty(el.textBlockButtons5);
        newErrors.textBlockContent1 = isFieldEmpty(el.textBlockContent1);
        newErrors.textBlockContent2 = isFieldEmpty(el.textBlockContent2);
        // newErrors.textBlockOrganizer1 = isFieldEmpty(el.textBlockOrganizer1);
        // newErrors.textBlockOrganizer2 = isFieldEmpty(el.textBlockOrganizer2);
        // newErrors.textBlockOrganizer3 = isFieldEmpty(el.textBlockOrganizer3);
        newErrors.textBlockRegisterError1 = isFieldEmpty(
          el.textBlockRegisterError1
        );
        newErrors.textBlockRegisterError2 = isFieldEmpty(
          el.textBlockRegisterError2
        );
        newErrors.textBlockRegisterError3 = isFieldEmpty(
          el.textBlockRegisterError3
        );
        newErrors.textBlockRegistration1 = isFieldEmpty(
          el.textBlockRegistration1
        );
        newErrors.textBlockRegistration2 = isFieldEmpty(
          el.textBlockRegistration2
        );
        newErrors.textBlockRegistration3 = isFieldEmpty(
          el.textBlockRegistration3
        );
        newErrors.textBlockRegistration4 = isFieldEmpty(
          el.textBlockRegistration4
        );
        newErrors.textBlockRegistration5 = isFieldEmpty(
          el.textBlockRegistration5
        );
        newErrors.textBlockRegistration6 = isFieldEmpty(
          el.textBlockRegistration6
        );
        newErrors.textBlockRegistration7 = isFieldEmpty(
          el.textBlockRegistration7
        );
        newErrors.textBlockRegistration8 = isFieldEmpty(
          el.textBlockRegistration8
        );
        newErrors.textBlockRegistration9 = isFieldEmpty(
          el.textBlockRegistration9
        );
        newErrors.textBlockRegistration10 = isFieldEmpty(
          el.textBlockRegistration10
        );
        newErrors.textBlockRegistration11 = isFieldEmpty(
          el.textBlockRegistration11
        );
        newErrors.textBlockRegistration12 = isFieldEmpty(
          el.textBlockRegistration12
        );
        newErrors.textBlockRegistration13 = isFieldEmpty(
          el.textBlockRegistration13
        );
        newErrors.textBlockRegistration14 = isFieldEmpty(
          el.textBlockRegistration14
        );
        newErrors.textBlockSuccessMsg = isFieldEmpty(el.textBlockSuccessMsg);

        // newErrors.textBlockTitle1 = isFieldEmpty(el.textBlockTitle1);
        // newErrors.textBlockTitle2 = isFieldEmpty(el.textBlockTitle2);
        // newErrors.textBlockTitle3 = isFieldEmpty(el.textBlockTitle3);
        // newErrors.textBlockTitle4 = isFieldEmpty(el.textBlockTitle4);
        // newErrors.textDescription = isFieldEmpty(el.textDescription);
        // newErrors.textTitle = isFieldEmpty(el.textTitle);
      } else if (
        index === 1 &&
        !Object.values(newErrors).some((value) => value.length)
      ) {
        newErrors.textBlockAbout1 = isFieldEmpty(el.textBlockAbout1);
        newErrors.textBlockAbout2 = isFieldEmpty(el.textBlockAbout2);
        newErrors.textBlockAbout3 = isFieldEmpty(el.textBlockAbout3);
        newErrors.textBlockAbout4 = isFieldEmpty(el.textBlockAbout4);
        newErrors.textBlockAbout5 = isFieldEmpty(el.textBlockAbout5);
        newErrors.textBlockAbout6 = isFieldEmpty(el.textBlockAbout6);
        newErrors.textBlockAbout7 = isFieldEmpty(el.textBlockAbout7);
        newErrors.textBlockAbout8 = isFieldEmpty(el.textBlockAbout8);
        newErrors.textBlockAbout9 = isFieldEmpty(el.textBlockAbout9);
        newErrors.textBlockAbout10 = isFieldEmpty(el.textBlockAbout10);
        newErrors.textBlockAbout11 = isFieldEmpty(el.textBlockAbout11);
        newErrors.textBlockAbout12 = isFieldEmpty(el.textBlockAbout12);
        newErrors.textBlockAbout13 = isFieldEmpty(el.textBlockAbout13);
        newErrors.textBlockAbout14 = isFieldEmpty(el.textBlockAbout14);
        newErrors.textBlockAbout15 = isFieldEmpty(el.textBlockAbout15);
        newErrors.textBlockAbout16 = isFieldEmpty(el.textBlockAbout16);
        newErrors.textBlockAbout17 = isFieldEmpty(el.textBlockAbout17);
        newErrors.textBlockAbout18 = isFieldEmpty(el.textBlockAbout18);
        newErrors.textBlockAbout19 = isFieldEmpty(el.textBlockAbout19);
        newErrors.textBlockAbout20 = isFieldEmpty(el.textBlockAbout20);

        newErrors.textBlockAbout21 = isFieldEmpty(el.textBlockAbout21);
        newErrors.textBlockAbout22 = isFieldEmpty(el.textBlockAbout22);
        newErrors.textBlockAbout23 = isFieldEmpty(el.textBlockAbout23);
        newErrors.textBlockAbout24 = isFieldEmpty(el.textBlockAbout24);
        newErrors.textBlockAbout25 = isFieldEmpty(el.textBlockAbout25);

        newErrors.textBlockButtons1 = isFieldEmpty(el.textBlockButtons1);
        newErrors.textBlockButtons2 = isFieldEmpty(el.textBlockButtons2);
        newErrors.textBlockButtons3 = isFieldEmpty(el.textBlockButtons3);
        newErrors.textBlockButtons4 = isFieldEmpty(el.textBlockButtons4);
        newErrors.textBlockButtons5 = isFieldEmpty(el.textBlockButtons5);
        newErrors.textBlockContent1 = isFieldEmpty(el.textBlockContent1);
        newErrors.textBlockContent2 = isFieldEmpty(el.textBlockContent2);
        // newErrors.textBlockOrganizer1 = isFieldEmpty(el.textBlockOrganizer1);
        // newErrors.textBlockOrganizer2 = isFieldEmpty(el.textBlockOrganizer2);
        // newErrors.textBlockOrganizer3 = isFieldEmpty(el.textBlockOrganizer3);
        newErrors.textBlockRegisterError1 = isFieldEmpty(
          el.textBlockRegisterError1
        );
        newErrors.textBlockRegisterError2 = isFieldEmpty(
          el.textBlockRegisterError2
        );
        newErrors.textBlockRegisterError3 = isFieldEmpty(
          el.textBlockRegisterError3
        );
        newErrors.textBlockRegistration1 = isFieldEmpty(
          el.textBlockRegistration1
        );
        newErrors.textBlockRegistration2 = isFieldEmpty(
          el.textBlockRegistration2
        );
        newErrors.textBlockRegistration3 = isFieldEmpty(
          el.textBlockRegistration3
        );
        newErrors.textBlockRegistration4 = isFieldEmpty(
          el.textBlockRegistration4
        );
        newErrors.textBlockRegistration5 = isFieldEmpty(
          el.textBlockRegistration5
        );
        newErrors.textBlockRegistration6 = isFieldEmpty(
          el.textBlockRegistration6
        );
        newErrors.textBlockRegistration7 = isFieldEmpty(
          el.textBlockRegistration7
        );
        newErrors.textBlockRegistration8 = isFieldEmpty(
          el.textBlockRegistration8
        );
        newErrors.textBlockRegistration9 = isFieldEmpty(
          el.textBlockRegistration9
        );
        newErrors.textBlockRegistration10 = isFieldEmpty(
          el.textBlockRegistration10
        );
        newErrors.textBlockRegistration11 = isFieldEmpty(
          el.textBlockRegistration11
        );
        newErrors.textBlockRegistration12 = isFieldEmpty(
          el.textBlockRegistration12
        );
        newErrors.textBlockRegistration13 = isFieldEmpty(
          el.textBlockRegistration13
        );
        newErrors.textBlockRegistration14 = isFieldEmpty(
          el.textBlockRegistration14
        );
        newErrors.textBlockSuccessMsg = isFieldEmpty(el.textBlockSuccessMsg);

        // newErrors.textBlockTitle1 = isFieldEmpty(el.textBlockTitle1);
        // newErrors.textBlockTitle2 = isFieldEmpty(el.textBlockTitle2);
        // newErrors.textBlockTitle3 = isFieldEmpty(el.textBlockTitle3);
        // newErrors.textBlockTitle4 = isFieldEmpty(el.textBlockTitle4);
        // newErrors.textDescription = isFieldEmpty(el.textDescription);
        // newErrors.textTitle = isFieldEmpty(el.textTitle);
      }
    });
  }

  if (Object.values(newErrors).some((value) => value.length)) {
    setErrors({ ...errors, ...newErrors });
    for (const property in newErrors) {
      if (newErrors[property].length > 0) {
        setErrorField(property);
      }
    }
  }
  const result = !Object.values(newErrors).some((value) => value.length);
  return result;
};
export default checkErrors;
