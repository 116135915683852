import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTER } from '../../../config';
import Menu from '../MenuComponent/index';
import s from './headerAdmin.module.scss';
import { isHOGDomain } from '../../../comon/isHOGDomain';
import Select, { components } from 'react-select';

import { FormattedMessage } from "react-intl";

const groupStyles = {
   border: `2px dotted '#5243AA'`,
   color: 'white',
   background: '#5243AA',
   padding: '5px 0px',
   display: 'flex',
}

const groupHOGStyles = {
   border: `2px dotted '#5243AA'`,
   background: 'rgba(215, 188, 51, 0.5)',
   color: '#d7bc33',
   padding: '5px 0px',
   display: 'flex',
};

const GroupHeading = (
   props
) => (
   <div style={isHOGDomain() ? groupHOGStyles : groupStyles}>
      <components.GroupHeading {...props} />
   </div>
);

const styles = {
   control: (css) => ({ ...css, backgroundColor: 'transparent' }),
   menu: (css) => ({ ...css, backgroundColor: isHOGDomain() ? '#000' : '#3F317C' }),
   singleValue: (css) => ({
      ...css, color: 'white'
   }),
   input: (css) => ({ ...css, color: 'white' }),
   option: (styles, { data }) => { return { ...styles, backgroundColor: 'transparent', marginLeft: data.margin } },
};

const HeaderAdmin = (props) => {
   const [isFilter, setIsFilter] = useState(props.filter);

   const showButton = (btnArrayName) => {
      return (
         btnArrayName.map(btn => <button
            onClick={props.onChangeActiveBtn}
            className={props.activeBtn === btn.name ? `${s.panelBtn} ${s.activeBtn}` : s.panelBtn}
            value={btn.name}
         >{props.activeBtn === btn.name ? <img src={btn.iconActive} alt="" /> : <img src={btn.icon} alt="" />}
            {btn.name}<i className='iconLock' /></button>)
      )
   }
   return (
      <div className={s.contentHeaderTitleContainer}>

         <div className={s.eventsHeaderTitleContainer}>
            <div className={s.title}>{props.pageName}</div>
            {props.addBtn ? <div className={s.buttonContainer}>
               {/* <button className={s.exportBtn} style={{ background: `${isHOGDomain() && '#846414'}` }}><FormattedMessage id="events.btn.export" tagName="span" /></button> */}
               <NavLink
                  onClick={props.storeFn}
                  activeClassName={s.active}
                  className={s.addEventBtn}
                  style={{ background: `${isHOGDomain() && '#846414'}` }}
                  to={ROUTER[props.toROUTER]}
               >{props.addBtn}</NavLink>
            </div> : false}

         </div>
         {props.select === 'event' && <div style={{ marginBottom: 32 }}>
            <Select
            onChange={(e) => props.setSelect(e)}
            placeholder={"Choose community "}
            options={props.selectOptions}
            isMulti={false}
            getOptionLabel={(option) => option.communityName}
            getOptionValue={(option) => option.communityId}
            styles={{
               ...styles
            }}
         /></div>}
         {props.select === 'tournament' && <div style={{ marginBottom: 32 }}><Select
            onChange={(e) => props.setSelect(e)}
            placeholder={"Choose community or event "}
            options={props.selectOptions}
            isMulti={false}
            components={{ GroupHeading }}
            styles={{
               ...styles
            }}
         /></div>}

         <div className={s.eventsHeaderFilterContainer}>

            {props.periodContainer && <div className={s.periodContainer}>
               <div className={s.periodTitle}><FormattedMessage id="events.btn.period" tagName="span" /></div>
               <button className={s.fromBtn} style={{ background: `${isHOGDomain() && '#846414'}` }}><FormattedMessage id="events.btn.from" tagName="span" /></button>
               <div className={s.line}></div>
               <button className={s.toBtn} style={{ background: `${isHOGDomain() && '#846414'}` }}><FormattedMessage id="events.btn.to" tagName="span" /></button>
            </div>}

            {props.btnInfo ? <div className={s.periodContainer}>
               {showButton(props.btnInfo)}
            </div> : false}

            {/* <div className={s.sortFilterContainer} title='sort'> */}
            {/* <div className={s.sortContainer}>
                  <div>Sort</div>
               </div> */}
            {!!isFilter && (
                <div
                    onClick={() => setIsFilter(true)}
                    className={s.sortFilterContainer}
                    title='sort'
                >
                   <div
                       className={s.filterContainer}
                       title='filter'
                   >
                      <FormattedMessage id="events.btn.filter" tagName="span" />
                   </div>
                </div>
            )}

            {/* <div className={s.inputContainer}><input type="text" placeholder='Search' /></div> */}
            {/* </div> */}
         </div>

          {!!props.pageMenu && (
              <Menu pageMenu={props.pageMenu}/>
          )}

      </div>
   )
}
export default HeaderAdmin;
