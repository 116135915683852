import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import Select from 'react-select';

import { API, API_ROUTER } from '../../api/index';

import s from '../../components/AdminComponents/CreateEvent/createEvent.module.scss';

const GiveOffStatusForm = () => {
    const { control, handleSubmit } = useForm();

    const [communitysBack, setCommunitysBack] = useState([]);

    useEffect(() => {
        let params = {
            ...API_ROUTER.community.getCommunities,
            pathKeys: {
                page: 1,
                limit: 9999,
            }
        };

        API.request(params)
            .then((res) => {
                setCommunitysBack(res.communities);
            })
            .catch((err) => console.log(err));
    }, [])

    const giveOffStatus = (submitData) => {
        console.log('submitData2', submitData);
    }

    return (
        <form onSubmit={handleSubmit(giveOffStatus)} style={{ marginBottom: 42, marginLeft: 48, marginTop: 60 }}>
            <p style={{
                marginBottom: 42, fontSize: 24,
                fontWeight: 500
            }}>Give community official status</p>
            <div className={s.inputContainer} style={{ width: 322, marginBottom: 10 }}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                    <FormattedMessage id="createTournament.title.titleCommunity" tagName="span" />
                </div>
                <div className={s.select}>
                    <Controller
                        name="community"
                        control={control}
                        render={({
                            field
                        }) => <Select
                                onChange={(e) => {
                                    field.onChange(e)
                                }}
                                classNamePrefix='custom_select'
                                options={communitysBack}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder='Select community'
                                isMulti={false}
                            />}
                    />
                </div>
            </div>
            <button style={{ marginTop: 24 }} type='submit'>Give official status</button>
        </form>
    )
}

export default GiveOffStatusForm