import { initialErrors } from "./const";
import { isFieldEmpty } from "../../../helpers";
const checkErrors = (formFields, setErrors, errors, setErrorField) => {
  const newErrors = initialErrors;
  newErrors.nameOfOrganizer = isFieldEmpty(formFields.nameOfOrganizer);
  newErrors.name = isFieldEmpty(formFields.name);
  newErrors.description = isFieldEmpty(formFields.description);
  if (Object.values(newErrors).some((value) => value.length)) {
    setErrors({ ...errors, ...newErrors });
    for (const property in newErrors) {
      if (newErrors[property].length > 0) {
        setErrorField(property);
      }
    }
  }
  const result = !Object.values(newErrors).some((value) => value.length);
  return result;
};
export default checkErrors;
