import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addSpinner, removeSpinner } from "../../redux/actions";

import { API, API_ROUTER } from "../../api/index";

import { pageInfo, titleHeader, headerItem } from "./const";

import { profileInfo } from "../../comon/comonConst";

import ProfilePanel from "../../components/AdminComponents/ProfilePanelComponent/index";
import HeaderAdmin from "../../components/AdminComponents/header-admin";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Table from "./components/Table-component";
import { Container } from "../../components/UI";
import AddNewPerson from "./components/addNewPerson";
import SelectGroup from "./components/selectGroup";

import NoAccessPopup from "./noAccessPopup";

import s from "../../comon/ComonAdmin.module.scss";

const RolesManagementPage = () => {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState(null);
  const [showAccessPopup, setShowAccessPopup] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [addNewPersonInput, setAddNewPersonInput] = useState(false);

  useEffect(() => {
    const storedItem = JSON.parse(sessionStorage.getItem("selectedRolesItem"));
    setSelectedItem(storedItem);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedRolesItem", JSON.stringify(selectedItem));
  }, [selectedItem]);

  const handleChange = (e) => {
    setSelectedItem(e);
  };

  useEffect(() => {
    let params;
    if (selectedItem?.label.includes("Community")) {
      const requestBody = {
        ...API_ROUTER.userAdmin.viewAccessCommunity,
        pathKeys: {
          communityId: selectedItem?.value,
        },
      };
      params = requestBody;
    }
    if (selectedItem?.label.includes("Event")) {
      const requestBody = {
        ...API_ROUTER.userAdmin.viewAccessEvent,
        pathKeys: {
          eventId: selectedItem?.value,
        },
      };
      params = requestBody;
    }
    if (selectedItem?.label.includes("Tournament")) {
      const requestBody = {
        ...API_ROUTER.userAdmin.viewAccessTournament,
        pathKeys: {
          tournamentId: selectedItem?.value,
        },
      };
      params = requestBody;
    }
    dispatch(addSpinner());
    API.request(params)
      .then((res) => {
        setData(res);
        dispatch(removeSpinner());
      })
      .catch((err) => {
        dispatch(removeSpinner());
      });
  }, [selectedItem?.label, updateData]);

  useEffect(() => {
    sessionStorage.setItem("selectedItemToEdit", JSON.stringify(selectedItem));
  }, [selectedItem]);

  return (
    <div className={s.evensPageContainer}>
      <ProfilePanel pageInfo={pageInfo} profileInfo={profileInfo} />
      <ContentContainer MainBlock={true} MainCreateBlock={null}>
        <Container>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginLeft: "10px",
            }}
          >
            <HeaderAdmin
              pageName={
                selectedItem ? (
                  <div
                    style={{
                      display: "flex",

                      fontSize: "20px",
                    }}
                  >
                    Roles of
                    <p
                      style={{
                        color: "#b67e2a",
                        marginLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {selectedItem?.label}
                    </p>
                  </div>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Please choose community, event or tournament
                  </p>
                )
              }
              toROUTER={pageInfo.toROUTER}
              periodContainer={pageInfo.periodContainer}
              btnInfo={pageInfo?.btnInfo}
            />
          </div>
          {showAccessPopup && (
            <NoAccessPopup
              open={showAccessPopup}
              setOpen={setShowAccessPopup}
            />
          )}

          <div style={{ marginBottom: 20 }}>
            <SelectGroup
              handleChange={handleChange}
              selectedItem={selectedItem}
              defaultValue={JSON.parse(
                sessionStorage.getItem("selectedRolesItem")
              )}
            />
          </div>
          <div style={{ marginBottom: 20, marginLeft: "10px" }}>
            <button onClick={(e) => setAddNewPersonInput(!addNewPersonInput)}>
              + Add new person
            </button>

            <div
              className={
                !addNewPersonInput
                  ? s.addNewPersonBlock
                  : s.addNewPersonBlockActive
              }
            >
              <AddNewPerson
                selectedItem={selectedItem}
                setUpdateData={setUpdateData}
                updateData={updateData}
              />
            </div>
          </div>
          <div className={s.mainScrollContainer}>
            <div className={s.scrollContainer}>
              <Table
                data={data?.[selectedItem?.rolesManagementType]}
                selectedItem={selectedItem}
                setShowAccessPopup={setShowAccessPopup}
                setUpdateData={setUpdateData}
                updateData={updateData}
              />
            </div>
          </div>
        </Container>
      </ContentContainer>
    </div>
  );
};

export default RolesManagementPage;
