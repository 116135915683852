import InputComponent from "../components/input";
import * as images from "../images";

const registrationWindow = (
  ref,
  formFields,
  inputHandler,
  selectLanguages,
  changeLanguagesValue,
  errorField
) => {
  return (
    <>
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration1}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration1"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration1"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register1}
      />
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration2}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration2"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration2"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register2}
      />
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration3}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration3"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration3"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register3}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration4}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration4"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration4"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register4}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration5}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration5"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration5"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register5}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration6}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration6"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration6"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register6}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration7}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration7"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration7"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register7}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration8}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration8"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration8"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register8}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration9}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration9"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration9"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register9}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration10}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration10"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration10"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register10}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration11}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration11"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration11"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register13}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration12}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration12"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration12"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register11}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration13}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration13"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration13"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register12}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegistration14}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegistration14"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registration14"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.register13}
      />
    </>
  );
};

export default registrationWindow;
