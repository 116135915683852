import React, { useState, useEffect } from 'react';
import ContentContainer from '../../components/ContentContainer/ContentContainer';

import { API, API_ROUTER } from "../../api/index";
import { useDispatch } from 'react-redux';
import {FormattedMessage, useIntl} from "react-intl";

import s from '../../comon/ComonAdmin.module.scss';
//обнуляющие стили
import "../../comon/_null_style.css";

//компоненты, из которых состоит страница
import ProfilePanel from '../../components/AdminComponents/ProfilePanelComponent/index';
import HeaderAdmin from '../../components/AdminComponents/header-admin';
import Item from '../../components/AdminComponents/itemComponent/itemComponent';
import InfoPanel from '../../components/AdminComponents/InfoPanel/index';
import { addSpinner, removeSpinner } from "../../redux/actions";

//const
import { pageInfo, btnCommunityObj } from "./const";
import { profileInfo, chatInfo, pageMenu, setPaginatorDate } from "../../comon/comonConst";
import Paginator from '../../comon/Paginator';

const CommunityPage = (props) => {

   const limit = 10;
   const [itemArray, setItemArray] = useState([]);
   const [pages, setPages] = useState([]);
   const [pagesCount, setPagesCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [communiityName, setCommuniityName] = useState("");

   const dispatch = useDispatch()

   const getCommunitiesData = (page = 1, limit = 10) => {
      dispatch(addSpinner())
       let urlParams = {};
      if (communiityName) {
          urlParams["name"] = communiityName;
      }
      let params = {
         ...API_ROUTER.community.getCommunities,
         pathKeys: {
            page: page,
            limit: limit,
         },
          urlParams
      };

      API.request(params)
         .then((res) => {
            setItemArray(res.communities);

            setPaginatorDate(
               res.paginator.lastPage,
               res.paginator.currentPage,
               setPagesCount,
               setPages,
               setCurrentPage
            );
            dispatch(removeSpinner())

         })
         .catch((err) => {
            console.log(err)
            dispatch(removeSpinner())
         });
   }

   useEffect(() => {
      getCommunitiesData(sessionStorage.getItem('communityPage') || 1, limit);
   }, [communiityName]);

   return (
      <div className={s.evensPageContainer}>
         <ProfilePanel
            pageInfo={pageInfo}
            profileInfo={profileInfo}
         />
         <ContentContainer
            MainBlock
            MainCreateBlock={null}
         >
            <HeaderAdmin
               pageName={pageInfo.pageName}
               toROUTER={pageInfo.toROUTER}
               addBtn={pageInfo.addBtn}
               periodContainer={pageInfo.periodContainer}
               btnInfo={pageInfo?.btnInfo}
               // pageMenu={pageMenu}
               filter={pageInfo.filter}
            />


             <div className={s.row}>
                 <div className={s.inputContainer}>
                     <div className={s.titleInput}>
                         <FormattedMessage id="community.search.byName" />
                     </div>
                     <input
                         type="text"
                         placeholder={useIntl().formatMessage({id: "community.search.byName"})}
                         value={communiityName}
                         onChange={(e) => setCommuniityName(e.target.value)}
                     />
                 </div>
             </div>
            <div className={s.mainScrollContainer}>
               <div className={s.scrollContainer}>
                  {itemArray?.map((u, i) =>
                     <Item
                        key={i}
                        id={u.id}
                        name={u.name}
                        btnObj={btnCommunityObj}
                        hiddenBtn={btnCommunityObj.hiddenBtn}
                        status
                     />
                  )}
               </div>
               {!!pagesCount && (
                   <Paginator
                       limit={limit}
                       pages={pages}
                       getFn={getCommunitiesData}
                       pagesCount={pagesCount}
                       currentPage={currentPage}
                       component='communityPage'
                   />
               )}

            </div>
         </ContentContainer>
      </div>

   )
}
export default CommunityPage;
