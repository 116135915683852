import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import Select from "react-select";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { API, API_ROUTER } from "../../../api/index";
import { ROUTER } from "../../../config";

import {
  addSpinner,
  removeSpinner,
  showNotificationModal,
} from "../../../redux/actions";

import {
  uploadData,
  onChangeDudlePhotos,
  addPhotos,
  delPhoto,
} from "../../../comon/uploadData";
import { uploadImg } from "../../../comon/comonConst";
import FileUploaderSingle from "../../../comon/file-uploader-for-single";
import { isHOGDomain } from "../../../comon/isHOGDomain";

import checkErrors from "./errors";
import { constformFields, initialErrors } from "./const";

import s from "./style/createEvent.module.scss";
import "../../../comon/CustomSelect/select.css";

const ADMISSION_PROCESS = [
  { value: "automatic", label: "automatic" },
  { value: "approval", label: "approva" },
];

const styles = {
  singleValue: (css) => ({
    ...css,
    color: "white",
  }),
  input: (css) => ({ ...css, color: "white" }),
};

const СreateCommunity = () => {
  const ref = useRef();
  const { id: editId } = useParams();
  const dispatch = useDispatch();
  const scrollToErrorField = () => {
    setImmediate(() =>
      ref.current.scrollIntoView({ behavior: "smooth", inline: "center" })
    );
  };

  const { handleSubmit, control } = useForm();

  const history = useHistory();

  const [communityToEdit, setCommunityToEdit] = useState({});
  const [formFields, setFormFields] = useState();
  const [landingData, setLandingData] = useState({});
  const [organizerLogo, setOrganizerLogo] = useState(["1"]);
  const [sponsorLogo, setSponsorLogo] = useState(["1"]);
  const [partnerLogo, setPartnerLogo] = useState(["1"]);
  const [photo, setPhoto] = useState(["1"]);
  const [errorField, setErrorField] = useState("");
  const [errors, setErrors] = useState(initialErrors);
  const [communityBackgroundLogo, setCommunityBackgroundLogo] = useState("");
  const [communityLogo, setCommunityLogo] = useState("");
  // СТЕЙТ ДЛЯ СОХРАНЕНИЯ ДАННЫХ ПОЛЕЙ
  function setLandingState() {
    setFormFields(constformFields(landingData));
  }
  // ИНПУТ
  function inputHandler(e) {
    const { name, value } = e.target;

    setFormFields({
      ...formFields,
      [name]: value,
    });
  }
  // ГИБКИЙ ИНПУТ
  function inputHandlerMulty(e, type, field, id, data) {
    const { name, value } = e.target;
    if (type === "doodle") {
      let newValue = [...data];
      if (field === "name") {
        newValue[id].name = value;
      } else {
        newValue[id].title = value;
      }
      setFormFields({
        ...formFields,
        [name]: newValue,
      });
    } else if ((type = "socialNetworks")) {
      setFormFields({
        ...formFields,
        [name]: {
          ...formFields[name],
          [field]: value,
        },
      });
    }
  }
  // ДОБАВЛЕНИЕ ПОЛЕЙ
  const addInputsNew = (name, value, fn) => {
    let newValue = [...value];
    newValue.push({ name: "", title: "" });
    setFormFields({
      ...formFields,
      [name]: newValue,
    });
  };
  // УДАЛЕНИЕ ПОЛЕЙ
  const handleDeleteNew = (name, idx, array, fn) => {
    const copiedArr = [...array];
    copiedArr.splice(idx, 1);
    setFormFields({
      ...formFields,
      [name]: copiedArr,
    });
  };
  // ОТОБРАЖЕНИЕ ОШИБОК
  const validationEmptyTextArea = (element) => {
    if (element?.length < 1) {
      return `${s.descriptionTextarea} ${s.textareaErr}`;
    } else {
      return `${s.descriptionTextarea}`;
    }
  };
  const validationEmptyInput = (element) => {
    if (element?.length < 1) {
      return `${s.inputErr}`;
    }
  };

  // ФУНКЦИЯ ДЛЯ ОТПРАВКИ ИЗОБРАЖЕНИЙ НА СЕРВЕР

  const uploadImages = (res) => {
    function filterImages(images, backendImages) {
      const newArr = images.filter((item) => {
        return !backendImages.includes(item);
      });
      return newArr;
    }

    if (communityBackgroundLogo.length > 0 && !editId) {
      uploadData(communityBackgroundLogo, res.id, "communityBackgroundLogo");
    } else if (communityBackgroundLogo.length > 0 && editId) {
      if (communityToEdit.backgroundLogo !== communityBackgroundLogo) {
        uploadData(communityBackgroundLogo, res.id, "communityBackgroundLogo");
      }
    }

    if (communityLogo.length > 0 && !editId) {
      uploadData(communityLogo, res.id, "communityLogo");
    } else if (communityLogo.length > 0 && editId) {
      if (communityToEdit.logo !== communityLogo) {
        uploadData(communityLogo, res.id, "communityLogo");
      }
    }

    if (organizerLogo != "1" && !editId) {
      organizerLogo.map((el) =>
        uploadData(el, res.id, "communityOrganizerLogo")
      );
    } else if (organizerLogo != "1" && editId) {
      filterImages(organizerLogo, communityToEdit.organizerLogo).map((el) =>
        uploadData(el, res.id, "communityOrganizerLogo")
      );
    }

    if (photo != "1" && !editId) {
      photo.map((el) => uploadData(el, res.id, "communityAnyPhotos"));
    } else if (photo != "1" && editId) {
      filterImages(photo, communityToEdit.anyPhotos).map((el) =>
        uploadData(el, res.id, "communityAnyPhotos")
      );
    }

    if (sponsorLogo != "1" && !editId) {
      sponsorLogo.map((el) => uploadData(el, res.id, "communitySponsorLogo"));
    } else if (sponsorLogo != "1" && editId) {
      filterImages(sponsorLogo, communityToEdit.sponsorLogo).map((el) =>
        uploadData(el, res.id, "communitySponsorLogo")
      );
    }

    if (partnerLogo != "1" && !editId) {
      partnerLogo.map((el) => uploadData(el, res.id, "communityPartnerLogo"));
    } else if (partnerLogo != "1" && editId) {
      filterImages(partnerLogo, communityToEdit.partnerLogo).map((el) =>
        uploadData(el, res.id, "communityPartnerLogo")
      );
    }
  };

  // ФУНКЦИЯ ДЛЯ ОТПРАВКИ ДАННЫХ НА СЕРВЕР
  const onSubmitForm = (submitData) => {
    let result;
    const data = {
      name: formFields?.name || "",
      nameOfOrganizer: formFields?.nameOfOrganizer || "",
      contacts: formFields?.contact || [],
      socialNetworks: {
        discord: formFields?.socialNetworks?.discord || "",
        facebook: formFields?.socialNetworks?.facebook || "",
        instagram: formFields?.socialNetworks?.instagram || "",
        twitter: formFields?.socialNetworks?.twitter || "",
      },
      teamOfOrganizer: formFields?.teamOfOrganizer || [],
      admissionProcess: "automatic",
      description: formFields?.description || "",
      rules: formFields?.rules || "",
    };
    if (checkErrors(formFields, setErrors, errors, setErrorField) === true) {
      API.request(
        !editId
          ? {
              ...API_ROUTER.community.createCommunity,
              data,
            }
          : {
              ...API_ROUTER.community.updateCommunity,
              pathKeys: {
                communityId: editId,
              },
              data,
            }
      )
        .then((res) => {
          result = res;
        })
        .then(() => {
          uploadImages(result);
        })
        .then(() => {
          dispatch(showNotificationModal(ServerAnswerSuccess));
          goToUrl(ROUTER.communitysPage);
          dispatch(removeSpinner());
        })
        .catch((err) => {
          const errors = [];
          for (let key in err.data.errors) {
            errors.push(`${key}: ${err.data.errors[key]}`);
          }
          dispatch(
            showNotificationModal(`Some errors occured: ${errors.join(", ")}`)
          );
          dispatch(removeSpinner());
        });
    } else {
      dispatch(removeSpinner());
      scrollToErrorField();
      toast.error("Fill all purple required fields * ");
    }
  };

  const goToUrl = (url) => {
    history.push(url);
  };
  //Мультиязычность Placeholder
  const сommunityNamePlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.сommunityName.placeholder",
  });
  const descriptionPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.description.placeholder",
  });
  const organizerPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.organizer.placeholder",
  });
  const subscriptionPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.imgTitle.titleSubscription.placeholder",
  });
  const admissionPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.imgTitle.titleAdmission.placeholder",
  });
  const rulesPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.rules.titleLayer",
  });
  const teamOrganizerNamePlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.titleTeamOrganizer.placeholderName",
  });
  const contactsNameTitlePlaceholder = useIntl().formatMessage({
    id: "placeholderTitle",
  });
  const teamOrganizerTitlePlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.titleTeamOrganizer.placeholderTitle",
  });
  const contactsNamePlaceholder = useIntl().formatMessage({
    id: "placeholderName",
  });
  const socialNetworksLinkPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.titleSocialNetworks.placeholder",
  });
  const titleContactsPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.titleContacts.placeholder",
  });
  const titleContactsSecondPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.titleContacts.second.placeholder",
  });
  const ServerAnswerSuccess = useIntl().formatMessage({
    id: `${
      editId
        ? "createCommunity.serverAnswer.success.edit"
        : "createCommunity.serverAnswer.success.create"
    }`,
  });

  const ServerAnswerErr = useIntl().formatMessage({
    id: "createCommunity.serverAnswer.err",
  });

  const subscriptionFeePlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.subscribtionFeePlaceholder",
  });

  const admissionProcessPlaceholder = useIntl().formatMessage({
    id: "createCommunity.title.admissionProcessPlaceholder",
  });

  const getCommunityData = () => {
    if (editId) {
      let params = {
        ...API_ROUTER.community.getCommunityById,
        pathKeys: {
          communityId: editId,
        },
      };
      dispatch(addSpinner());
      API.request(params)
        .then((response) => {
          setCommunityToEdit(response);
          setCommunityBackgroundLogo(
            response.backgroundLogo ? response.backgroundLogo : ""
          );
          setCommunityLogo(response.logo ? response.logo : "");
          setOrganizerLogo(
            response.organizerLogo.length ? response.organizerLogo : ["1"]
          );
          setSponsorLogo(
            response.sponsorLogo.length ? response.sponsorLogo : ["1"]
          );
          setPartnerLogo(
            response.partnerLogo.length ? response.partnerLogo : ["1"]
          );
          setPhoto(response.anyPhotos.length ? response.anyPhotos : ["1"]);
          setLandingData(response);
          dispatch(removeSpinner());
        })
        .catch((err) => {
          console.log(err);
          toast.error("something went wrong");
          dispatch(removeSpinner());
        });
    }
  };

  useEffect(() => {
    getCommunityData();
  }, [editId]);

  useEffect(() => {
    if (landingData) {
      return setLandingState();
    } else {
      setFormFields({ ...formFields });
    }
  }, [landingData]);

  return (
    <div className={s.mainBlock}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className={s.titlePage}>
          <FormattedMessage
            id={
              !editId
                ? "createCommunity.pageName.title"
                : "createCommunity.pageName.editTitle"
            }
            tagName="span"
          />
        </div>
        <div className={s.mainSattingsContainer}>
          <div className={s.settingsContainer}>
            <div className={s.inputContainer}>
              <div
                className={s.titleInput}
                ref={errorField === "name" ? ref : null}
              >
                <FormattedMessage
                  id="createCommunity.title.сommunityName"
                  tagName="span"
                />
              </div>
              <input
                value={formFields?.name}
                name="name"
                className={validationEmptyInput(formFields?.name)}
                onChange={(e) => inputHandler(e)}
                type="text"
                placeholder={сommunityNamePlaceholder}
              />
            </div>

            <div className={s.imageContainer}>
              <div className={s.imageContainer}>
                <div className={s.imgTitle}>
                  <FormattedMessage
                    id="createCommunity.title.BGImg"
                    tagName="span"
                  />
                </div>
                <FileUploaderSingle
                  defaultFoto={uploadImg}
                  setImageURL={setCommunityBackgroundLogo}
                  imageURL={communityBackgroundLogo}
                  htmlFor={"communityBackgroundLogo"}
                />
              </div>
            </div>
            <div className={s.inputContainer}>
              <div
                className={s.label}
                ref={errorField === "description" ? ref : null}
              >
                <span>
                  <FormattedMessage id="createCommunity.title.description" />
                </span>
              </div>

              <textarea
                name="description"
                value={formFields?.description}
                // className={`${s.descriptionTextarea} ${
                //   errors.description && s.error
                // }`}
                className={validationEmptyTextArea(formFields?.description)}
                onChange={(e) => inputHandler(e)}
                placeholder={descriptionPlaceholder}
                style={{ background: isHOGDomain() && "#000" }}
              ></textarea>
            </div>
            <div className={s.inputContainer}>
              <div
                className={s.titleInput}
                ref={errorField === "nameOfOrganizer" ? ref : null}
              >
                <FormattedMessage
                  id="createCommunity.title.organizer"
                  tagName="span"
                />
              </div>
              <input
                className={validationEmptyInput(formFields?.nameOfOrganizer)}
                name="nameOfOrganizer"
                value={formFields?.nameOfOrganizer}
                onChange={(e) => inputHandler(e)}
                type="text"
                placeholder={organizerPlaceholder}
              />
            </div>

            <div className={s.imageContainer}>
              <div className={s.imageContainer}>
                <div className={s.imgTitle}>
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.communityLogo"
                    tagName="span"
                  />
                </div>
                <FileUploaderSingle
                  defaultFoto={uploadImg}
                  setImageURL={setCommunityLogo}
                  imageURL={communityLogo}
                  htmlFor={"communityLogo"}
                />
              </div>
            </div>

            <div className={s.imageContainer}>
              <div className={s.imageContainer}>
                <div className={s.imgTitle}>
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.organizerLogo"
                    tagName="span"
                  />
                </div>
                {organizerLogo.map((el, i) => (
                  <div>
                    <div key={i} className={s.btnContainer}>
                      <div
                        style={{ top: 4 }}
                        className={s.delBtn}
                        onClick={() =>
                          delPhoto(i, setOrganizerLogo, organizerLogo, editId)
                        }
                      ></div>
                      <FileUploaderSingle
                        style={{ marginTop: 0 }}
                        defaultFoto={uploadImg}
                        imageURL={organizerLogo[i]}
                        htmlFor={`organizerLogo${i}`}
                        isMany={true}
                        onChangeDudlePhotos={onChangeDudlePhotos}
                        id={i}
                        fn={setOrganizerLogo}
                        data={organizerLogo}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={s.addBtnContainer}
                style={{ justifyContent: "start" }}
              >
                <button
                  style={{
                    marginLeft: "0px",
                    background: isHOGDomain() && "rgb(132, 100, 20)",
                  }}
                  type="button"
                  className={s.addBtn}
                  onClick={() => addPhotos(organizerLogo, setOrganizerLogo)}
                >
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.addOrganizerLogo"
                    tagName="span"
                  />
                </button>
              </div>
            </div>

            <div className={s.imageContainer}>
              <div className={s.imageContainer}>
                <div className={s.imgTitle}>
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.sponsorLogo"
                    tagName="span"
                  />
                </div>
                {sponsorLogo.map((el, i) => (
                  <div>
                    <div key={i} className={s.btnContainer}>
                      <div
                        style={{ top: 4 }}
                        className={s.delBtn}
                        onClick={() =>
                          delPhoto(i, setSponsorLogo, sponsorLogo, editId)
                        }
                      ></div>
                      <FileUploaderSingle
                        style={{ marginTop: 10 }}
                        defaultFoto={uploadImg}
                        imageURL={sponsorLogo[i]}
                        htmlFor={`sponsorLogo${i}`}
                        isMany={true}
                        onChangeDudlePhotos={onChangeDudlePhotos}
                        id={i}
                        fn={setSponsorLogo}
                        data={sponsorLogo}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={s.addBtnContainer}
                style={{ justifyContent: "start" }}
              >
                <button
                  style={{
                    marginLeft: "0px",
                    background: isHOGDomain() && "rgb(132, 100, 20)",
                  }}
                  type="button"
                  className={s.addBtn}
                  onClick={() => addPhotos(sponsorLogo, setSponsorLogo)}
                >
                  <span>
                    <FormattedMessage id="createCommunity.title.imgTitle.addSponsorLogo" />
                  </span>
                </button>
              </div>
            </div>

            <div className={s.imageContainer}>
              <div className={s.imageContainer}>
                <div className={s.imgTitle}>
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.partnerLogo"
                    tagName="span"
                  />
                </div>
                {partnerLogo.map((el, i) => (
                  <div>
                    <div key={i} className={s.btnContainer}>
                      <div
                        style={{ top: 4 }}
                        className={s.delBtn}
                        onClick={() =>
                          delPhoto(i, setPartnerLogo, partnerLogo, editId)
                        }
                      ></div>
                      <FileUploaderSingle
                        defaultFoto={uploadImg}
                        imageURL={partnerLogo[i]}
                        htmlFor={`partnerLogo${i}`}
                        isMany={true}
                        onChangeDudlePhotos={onChangeDudlePhotos}
                        id={i}
                        fn={setPartnerLogo}
                        data={partnerLogo}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={s.addBtnContainer}
                style={{ justifyContent: "start" }}
              >
                <button
                  style={{
                    marginLeft: "0px",
                    background: isHOGDomain() && "rgb(132, 100, 20)",
                  }}
                  type="button"
                  className={s.addBtn}
                  onClick={() => addPhotos(partnerLogo, setPartnerLogo)}
                >
                  <FormattedMessage
                    id="createCommunity.title.imgTitle.addPartnerLogo"
                    tagName="span"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={s.selectContainer}>
          <div className={s.text}>
            <FormattedMessage
              id="createCommunity.title.subscribtionFee"
              tagName="span"
            />
          </div>
          <div className={s.select}>
            <Controller
              name="subscribtionFee"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  classNamePrefix="custom_select"
                  options={[
                    { value: "yes", label: "yes" },
                    { value: "no", label: "no" },
                  ]}
                  placeholder={subscriptionFeePlaceholder}
                  isMulti={false}
                  styles={{ ...styles }}
                  isDisabled={true}
                />
              )}
            />
          </div>
          <i className="iconLock" style={{ top: 30, right: "-30px" }} />
        </div>

        <div className={s.selectContainer}>
          <div className={s.text}>
            <FormattedMessage
              id="createCommunity.title.admissionProcess"
              tagName="span"
            />
          </div>
          <div className={s.select}>
            <Controller
              name="admissionProcess"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  classNamePrefix="custom_select"
                  options={ADMISSION_PROCESS}
                  placeholder={admissionProcessPlaceholder}
                  isMulti={false}
                  styles={{ ...styles }}
                  isDisabled={true}
                />
              )}
            />
          </div>
          <i className="iconLock" style={{ top: 30, right: "-30px" }} />
        </div>

        <div className={s.contentHeaderTitleContainer}>
          <div className={s.btnContainer}>
            <div className={s.text}>
              <FormattedMessage
                id="createCommunity.title.titleCircuit"
                tagName="span"
              />
            </div>
            <a
              target="_blank"
              href="https://about.passport.gg/officialstatusrequest"
              rel="noreferrer"
            >
              <button
                type="button"
                style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
              >
                Request affiliation to circuit and grade
              </button>
            </a>
          </div>

          <div className={s.btnContainer}>
            <div className={s.text}>
              <FormattedMessage
                id="createCommunity.title.titleRating"
                tagName="span"
              />
            </div>
            <a
              target="_blank"
              href="https://about.passport.gg/officialstatusrequest"
              rel="noreferrer"
            >
              <button
                type="button"
                style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
              >
                <FormattedMessage
                  id="createCommunity.title.btnRequest"
                  tagName="span"
                />
              </button>
            </a>
          </div>
        </div>

        <div className={s.label}>
          <FormattedMessage id="createTournament.title.rules" tagName="span" />
        </div>

        <textarea
          value={formFields?.rules}
          className={validationEmptyTextArea(formFields?.rules)}
          onChange={(e) => inputHandler(e)}
          name="rules"
          placeholder={rulesPlaceholder}
          style={{ background: isHOGDomain() && "#000" }}
        ></textarea>

        <div className={s.inputContainerLower}>
          <div className={`${s.inputText} ${s.disabledText}`}>
            <FormattedMessage
              id="createCommunity.title.titleWelcome"
              tagName="span"
            />
            <i className="iconLock" style={{ top: "-1px", left: 147 }} />
          </div>
        </div>
        <div className={s.inputContainerLower}>
          <div className={s.titleInput}>
            <FormattedMessage
              id="createCommunity.title.titleTeamOrganizer"
              tagName="span"
            />
          </div>
          {formFields?.teamOfOrganizer.map((el, i) => (
            <div className={`${s.inputBlock} ${s.inputBr}`}>
              <div
                className={s.inputContainer}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  className={validationEmptyInput(
                    formFields?.teamOfOrganizer[i].name
                  )}
                  name="teamOfOrganizer"
                  style={{ fontSize: 16, marginTop: 0 }}
                  type="text"
                  placeholder={teamOrganizerNamePlaceholder}
                  value={formFields?.teamOfOrganizer[i].name}
                  onChange={(e) =>
                    inputHandlerMulty(
                      e,
                      "doodle",
                      "name",
                      i,
                      formFields?.teamOfOrganizer
                    )
                  }
                />
              </div>
              <div
                className={s.inputContainer}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  className={validationEmptyInput(
                    formFields?.teamOfOrganizer[i].title
                  )}
                  style={{ fontSize: 16, marginTop: 0 }}
                  type="text"
                  placeholder={teamOrganizerTitlePlaceholder}
                  value={formFields?.teamOfOrganizer[i].title}
                  onChange={(e) =>
                    inputHandlerMulty(
                      e,
                      "doodle",
                      "title",
                      i,
                      formFields?.teamOfOrganizer
                    )
                  }
                />
              </div>
              <button
                onClick={() =>
                  handleDeleteNew(
                    "teamOfOrganizer",
                    i,
                    formFields?.teamOfOrganizer
                  )
                }
                style={{
                  width: 50,
                  cursor: "pointer",
                  marginBottom: 5,
                  background: isHOGDomain() && "rgb(132, 100, 20)",
                }}
                type="button"
              >
                <DeleteOutlinedIcon />
              </button>
            </div>
          ))}
          <div
            className={s.addImg}
            style={{
              marginTop: 10,
              background: isHOGDomain() && "rgb(132, 100, 20)",
            }}
            onClick={() =>
              addInputsNew("teamOfOrganizer", formFields?.teamOfOrganizer)
            }
          ></div>
        </div>
        <div className={s.inputContainerLower}>
          <div className={s.inputText}>
            <FormattedMessage
              id="createCommunity.title.titleContacts"
              tagName="span"
            />
          </div>
        </div>

        {formFields?.contact.map((el, i) => (
          <div className={`${s.inputBlock} ${s.inputBr}`}>
            <div
              className={s.inputContainer}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <input
                className={validationEmptyInput(formFields?.contact[i].name)}
                style={{ fontSize: 16, marginTop: 0 }}
                type="text"
                placeholder={contactsNamePlaceholder}
                value={formFields?.contact[i].name}
                onChange={(e) =>
                  inputHandlerMulty(e, "doodle", "name", i, formFields?.contact)
                }
              />
            </div>
            <div
              className={s.inputContainer}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <input
                className={validationEmptyInput(formFields?.contact[i].title)}
                style={{ fontSize: 16, marginTop: 0 }}
                type="text"
                placeholder={contactsNameTitlePlaceholder}
                value={formFields?.contact[i].title}
                onChange={(e) =>
                  inputHandlerMulty(
                    e,
                    "doodle",
                    "title",
                    i,
                    formFields?.contact
                  )
                }
              />
            </div>
            <button
              onClick={() => handleDeleteNew("contact", i, formFields?.contact)}
              style={{
                width: 50,
                cursor: "pointer",
                marginBottom: 5,
                background: isHOGDomain() && "rgb(132, 100, 20)",
              }}
              type="button"
            >
              <DeleteOutlinedIcon />
            </button>
          </div>
        ))}
        <div
          className={s.addImg}
          style={{
            marginTop: 10,
            background: isHOGDomain() && "rgb(132, 100, 20)",
          }}
          onClick={() => addInputsNew("contact", formFields?.contact)}
        ></div>

        <div className={s.subtitle}>
          <FormattedMessage
            id="createCommunity.title.titleSocialNetworks"
            tagName="span"
          />
        </div>

        <div className={s.inputContainer}>
          <div className={s.label}>
            <FormattedMessage
              id="createEvent.title.titleFacebook"
              tagName="span"
            />
          </div>
          <input
            className={validationEmptyInput(
              formFields?.socialNetworks?.facebook
            )}
            name="socialNetworks"
            value={formFields?.socialNetworks?.facebook}
            onChange={(e) => inputHandlerMulty(e, "socialNetworks", "facebook")}
            type="text"
            placeholder={socialNetworksLinkPlaceholder}
          />
        </div>

        <div className={s.inputContainer}>
          <div className={s.label}>
            <FormattedMessage
              id="createEvent.title.titleInstagram"
              tagName="span"
            />
          </div>
          <input
            className={validationEmptyInput(
              formFields?.socialNetworks?.instagram
            )}
            name="socialNetworks"
            value={formFields?.socialNetworks?.instagram}
            onChange={(e) =>
              inputHandlerMulty(e, "socialNetworks", "instagram")
            }
            type="text"
            placeholder={socialNetworksLinkPlaceholder}
          />
        </div>

        <div className={s.inputContainer}>
          <div className={s.label}>
            <FormattedMessage
              id="createEvent.title.titleDiscord"
              tagName="span"
            />
          </div>
          <input
            className={validationEmptyInput(
              formFields?.socialNetworks?.discord
            )}
            name="socialNetworks"
            value={formFields?.socialNetworks?.discord}
            onChange={(e) => inputHandlerMulty(e, "socialNetworks", "discord")}
            type="text"
            placeholder={socialNetworksLinkPlaceholder}
          />
        </div>

        <div className={s.inputContainer}>
          <div className={s.label}>
            <FormattedMessage
              id="createEvent.title.titleTwitter"
              tagName="span"
            />
          </div>
          <input
            className={validationEmptyInput(
              formFields?.socialNetworks?.twitter
            )}
            name="socialNetworks"
            value={formFields?.socialNetworks?.twitter}
            onChange={(e) => inputHandlerMulty(e, "socialNetworks", "twitter")}
            type="text"
            placeholder={socialNetworksLinkPlaceholder}
          />
        </div>

        <div className={s.imageContainer}>
          <div className={s.imageContainer}>
            <div className={s.imgTitle}>
              <FormattedMessage
                id="createCommunity.title.titlePhotos"
                tagName="span"
              />
            </div>
            {photo.map((el, i) => (
              <div>
                <div key={i} className={s.btnContainer}>
                  <div
                    style={{ top: 4 }}
                    className={s.delBtn}
                    onClick={() => delPhoto(i, setPhoto, photo, editId)}
                  ></div>
                  <FileUploaderSingle
                    defaultFoto={uploadImg}
                    imageURL={photo[i]}
                    htmlFor={`photo${i}`}
                    isMany={true}
                    onChangeDudlePhotos={onChangeDudlePhotos}
                    id={i}
                    fn={setPhoto}
                    data={photo}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className={s.addBtnContainer}
            style={{ justifyContent: "start" }}
          >
            <button
              style={{
                marginLeft: "0px",
                background: isHOGDomain() && "rgb(132, 100, 20)",
              }}
              type="button"
              className={s.addBtn}
              onClick={() => addPhotos(photo, setPhoto)}
            >
              <FormattedMessage
                id="createCommunity.title.addTitlePhotos"
                tagName="span"
              />
            </button>
          </div>
        </div>

        <div className={s.contentHeaderTitleContainer}>
          <button
            style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
            type="submit"
            className={s.createBtn}
          >
            <FormattedMessage
              id={
                !editId
                  ? "createCommunity.pageName.title"
                  : "createCommunity.pageName.editTitle"
              }
              tagName="span"
            />
          </button>
        </div>
      </form>
    </div>
  );
};
export default СreateCommunity;
