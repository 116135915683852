import * as React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import { API_ROUTER, API } from "../../api";
// createEvent.module.scss
import style from "./membersPage.module.scss";
import "./members.css";

const useStyles = makeStyles({
  dialog: {
    backgroundColor: "#3F317C",
    color: "white",
  },
});

export default function BanPopup(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };

  const onSubmit = (data) => {
    const requestBody = {
      userId: props.member.id,
      newValue: true,
      banEnd: `${data.expiryDate} 00:00:00`,
      banReason: data.reason,
      communityId: props.communityId,
    };
    API.request({
      ...API_ROUTER.userAdmin.banUser,
      data: requestBody,
    })
      .then((res) => {
        console.log("res", res);
        // fnChange(!value);
        // fnPreloader(false);
        toast.success("User banned successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error");
      });
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={props.open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.dialog}>
            <DialogTitle>{`Ban ${props.member.nickname}`}</DialogTitle>
            <DialogContent>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: 350,
                  gap: 20,
                }}
              >
                {errors.reason?.type === "required" && (
                  <span className={style.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </span>
                )}
                {errors.reason?.type === "minLength" && (
                  <span className={style.messageErr}>
                    <FormattedMessage
                      id="createLanding.err.errMessageLong"
                      tagName="span"
                    />
                  </span>
                )}
                <textarea
                  className={`${style.descriptionTextarea} ${
                    errors.reason && style.error
                  }`}
                  {...register("reason", { required: true, minLength: 2 })}
                  placeholder="Reason for ban"
                ></textarea>
                <div className={style.inputContainer}>
                  <div className={style.descriptionTitle}>
                    <FormattedMessage
                      id="members.banModal.expiryDate"
                      tagName="span"
                    />
                  </div>
                  {errors.expiryDate?.type === "required" && (
                    <span className={style.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </span>
                  )}
                  <input
                    className={errors.expiryDate ? style.inputErr : ""}
                    {...register("expiryDate", { required: true })}
                    type="date"
                    placeholder="Expiry date"
                  />
                </div>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                sx={{
                  color: "white",
                  marginTop: 32,
                }}
                type="submit"
              >
                Ban
              </Button>
            </DialogActions>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
