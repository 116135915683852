import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import s from "../createLanding.module.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const TooltipImg = ({ image, position }) => {
  return (
    <HtmlTooltip
      placement={position ? position : "right"}
      title={
        <React.Fragment>
          <br />
          <Typography color="inherit">How it looks like</Typography>
          <br />
          <img
            alt="img"
            style={{ maxWidth: "100%", height: "auto" }}
            src={image}
          ></img>
          <br />
          <br />
          <u>{"If you don't like it you can change it at any time"}</u>.
        </React.Fragment>
      }
    >
      <div className={s.tooltip}>?</div>
    </HtmlTooltip>
  );
};

export default TooltipImg;
