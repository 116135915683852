import { ADD_SPINNER, REMOVE_SPINNER } from "../../types";

const rd = (state = false, action) => {
    switch (action.type) {
        case ADD_SPINNER:
            return state = true;
        case REMOVE_SPINNER:
            return state = false;
        default:
            return state
    }
}

export default rd