import en from './translations/en.json';
import ru from './translations/ru.json';
/*import pt from './translations/pt.json';
import fr from './translations/fr.json';
import es from './translations/es.json'; */
// import pl from './translations/pl.json';
import cn from './translations/cn.json';
// import it from './translations/it.json';

export const translations = {
   en,
   ru,
   //pt,
   //fr,
   //es,
   // pl,
   cn,
   // it
};
