import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import classNames from "classnames";

import TooltipImg from "./tooltip";
import { isHOGDomain } from "../../../../comon/isHOGDomain";

import s from "../createLanding.module.scss";

const DomenInput = React.forwardRef(
  (
    {
      // Элемент основного языка
      element,
      // Функция обработчик изменения элемента основного языка
      inputHandler,
      selectHandler,
      // id для форматированного сообщения
      formattedMessageDomainOptionsId,
      formattedMessageDomainTypeFree,
      formattedMessageDomainTypePremium,
      formattedMessageDomainExtra,
      errorField,
      tooltipImage,
      domainSelection,
      setDomainSelection,
    },
    ref
  ) => {
    const validationEmpty = (element) => {
      if (element?.length < 1) {
        return `${s.inputErr}`;
      }
    };
    return (
      <>
        <div
          className={s.inputContainer}
          style={{ marginTop: 46 }}
          ref={errorField === "domain" ? ref : null}
        >
          <div className={s.titleInput}>
            <FormattedMessage
              id="createLanding.title.domainName"
              tagName="span"
            />
          </div>
          <div style={{ display: "flex" }}>
            <input
              className={validationEmpty(element)}
              type="text"
              name="domain"
              placeholder="domain name"
              value={element}
              onChange={(e) => inputHandler(e)}
            />
            <TooltipImg image={tooltipImage} />
          </div>

          {element && (
            <div className={s.choiceContainer}>
              <div className={s.titleInput}>
                <FormattedMessage
                  id={`${formattedMessageDomainOptionsId}`}
                  tagName="span"
                />
              </div>

              <div className={s.columnContainer}>
                <div
                  onClick={(e) => {
                    setDomainSelection("free");
                    selectHandler("free", "domainSelection");
                  }}
                  className={classNames({
                    [`${s.titleInput} ${s.active}`]: domainSelection === "free",
                    [s.titleInput]: domainSelection !== "free",
                    [s.hog]: isHOGDomain(),
                  })}
                >
                  <div>
                    passport.gg/<span>{element}</span>
                  </div>
                </div>
                <div className={s.titleInput} style={{ color: "green" }}>
                  <FormattedMessage
                    id={`${formattedMessageDomainTypeFree}`}
                    // "createLanding.domainName.typeFree"
                    tagName="span"
                  />
                </div>

                <div
                  onClick={() => {
                    setDomainSelection("premium");
                    selectHandler("premium", "domainSelection");
                  }}
                  className={
                    domainSelection === "premium"
                      ? `${s.titleInput} ${s.active}`
                      : `${s.titleInput}`
                  }
                >
                  <div>
                    <span>{element}</span>.passport.gg
                  </div>
                </div>
                <div className={s.titleInput} style={{ color: "red" }}>
                  <FormattedMessage
                    id={`${formattedMessageDomainTypePremium}`}
                    tagName="span"
                  />
                </div>
                {domainSelection === "premium" && (
                  <div className={s.titleInput}>
                    <FormattedMessage
                      id={`${formattedMessageDomainExtra}`}
                      tagName="span"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default DomenInput;
