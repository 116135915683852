import { FormattedMessage } from "react-intl";

export const pageInfo = {
  pageName: <FormattedMessage id="landings.title.pageName" tagName="span" />,
  filter: false,
  addBtn: <FormattedMessage id="landings.btn.addLandings" tagName="span" />,
  periodContainer: true,
  toROUTER: "createLanding",
  pro: false,
  live: false,
};
