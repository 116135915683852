import React, { useState } from "react";
import s from "./item.module.scss";

import { useHistory } from "react-router-dom";
import { isHOGDomain } from "../../../comon/isHOGDomain";

const Item = (props) => {
  const [statusPro, setStatusPro] = useState(props.pro);
  const [statusLive, setStatusLive] = useState(props.live);
  const [notifError, setNotifError] = useState(false);

  const history = useHistory();

  function goToUrl(url) {
    history.push(url);
  }

  function currentDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  /* const [status, setStatus] = useState(props.status); */
  const [activeMenu, setActiveMenu] = useState(false);
  const onChangeMenu = (value) => {
    console.log(value);
    setActiveMenu(!value);
  };
  const getDate = (d) => {
    if (d) {
      return d.slice(d.indexOf("T") + 1).slice(0, -6);
    } else return false;
  };

  const url = props.btnObj.topBtn.to1;

  /* const returnArr = (value) => {
      let arr = []
      value.map(el => {
         for (let key in el) {
            arr.push(el[key])
         }
      })
      return arr;
   } */

  const returnArr = (value) => {
    if (typeof props.name === "object") {
      let arr = [];
      value.map((el) => {
        for (let key in el) {
          arr.push(el[key]);
        }
      });
      return arr.map((el) => <div className={s.title}>{el}</div>);
    } else {
      return <div className={s.title}>{props.name}</div>;
    }
  };

  const onEditClick = () => {
    if (currentDate() > props.canEdit) {
      return setNotifError(true);
    }

    if (url) {
      return goToUrl(url + `${props.id}`);
    }

    return onChangeMenu(activeMenu);
  };

  const Notification = () => {
    return (
      <div
        style={{
          width: 500,
          fontSize: 14,
          marginTop: 15,
          marginBottom: "-10px",
          letterSpacing: "1px",
          color: "#9D90C7",
        }}
      >
        <p>Editing cannot be done as the registration date has expired</p>
        <p>
          Please, contact our{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://about.passport.gg/adminsupport"
          >
            support team
          </a>{" "}
          if you have any questions
        </p>
      </div>
    );
  };

  return (
    <div className={activeMenu ? s.showMenu : s.hiddenMenu}>
      <div
        className={s.itemContainer}
        style={{ background: `${isHOGDomain() && "#846414"}` }}
      >
        <div className={s.info}>
          {returnArr(props.name)}
          <div className={s.status}>
            {statusPro ? <div className={s.status__pro}>PRO</div> : false}
            {statusLive ? <div className={s.status__live}>Live</div> : false}
          </div>
          {props.domain && <div className={s.domain}>{props.domain}</div>}
        </div>
        {notifError && <Notification />}
        <div className={s.btnContainer}>
          <button
            className={s.edit}
            onClick={() => {
              onChangeMenu(activeMenu);
              onEditClick();
            }}
            style={{
              background: `${isHOGDomain() && "#d7bc33"}`,
              outline: `${notifError && "red"}`,
            }}
          >
            {props.btnObj.topBtn.edit}
            {!props.domain && (
              <div className={s.switch}>
                {activeMenu ? (
                  <div className={s.switchOff}></div>
                ) : (
                  <div className={s.switchOn}></div>
                )}
              </div>
            )}
          </button>
        </div>
        <div className={s.btnContainerHidden}>
          {props.hiddenBtn?.map((u) => (
            <>
              {!u.linkStart ? (
                <button
                  onClick={() =>
                    u.to1 ? history.push(`${u.to1}${props.id}`) : ""
                  }
                  className={s.statistics}
                  style={{ background: `${isHOGDomain() && "#d7bc33"}` }}
                >
                  {!u.to1 && <i className="iconLock" />}

                  {u.name}
                  <img src={u.img} alt="" />
                </button>
              ) : (
                <button
                  className={s.statistics}
                  style={{ background: `${isHOGDomain() && "#d7bc33"}` }}
                  onClick={() =>
                    window.open(`${u.linkStart}${props.id}${u.linkEnd}`)
                  }
                >
                  {!u.to1 && !u.linkStart && !u.linkEnd && (
                    <i className="iconLock" />
                  )}

                  {u.name}
                  <img src={u.img} alt="" />
                </button>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Item;
