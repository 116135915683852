import React, { useState, useEffect } from "react";
import s from "./checkbox.module.scss";
import preloaderImg from "../../../img/icons/preloaderIcon.svg";

const Checkbox = (props) => {
  console.log(props.isActiveBack);
  const [isActive, setIsActive] = useState(false);
  const [preloader, setPreloader] = useState(false);

  const handleClick = (e) => {
    if (props.access) {
      props.onChange(
        props.userId,
        e.target.checked,
        setIsActive,
        props.communityId,
        setPreloader
      );
    } else {
      props.showAccessPopup(true);
    }
  };
  const rolesManagementHandler = (e) => {
    if (props.access) {
      props.onChange(
        props.userId,
        props.roleId,
        props.roleGiven,
        props.currentRole,
        props.userName,
        setIsActive,
        setPreloader
      );
    } else {
      props.showAccessPopup(true);
    }
  };
  useEffect(() => {
    setIsActive(props.isActiveBack);
  }, [props.isActiveBack]);
  return (
    <div className={s.container}>
      {preloader ? (
        <img src={preloaderImg} className={s.preloaderImg} alt="" />
      ) : (
        <input
          style={{ cursor: props.owner ? "not-allowed" : "pointer" }}
          className={s.input}
          type="checkbox"
          checked={isActive}
          onClick={(e) =>
            props.rolesManagement ? rolesManagementHandler(e) : handleClick(e)
          }
        />
      )}
    </div>
  );
};
export default Checkbox;
