import InputComponent from "../components/input";
import * as images from "../images";

const registrationErrors = (
  ref,
  formFields,
  inputHandler,
  selectLanguages,
  changeLanguagesValue,
  errorField
) => {
  return (
    <>
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegisterError1}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegisterError1"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registerErrorName"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.errors1}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegisterError2}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegisterError2"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registerErrorEmail"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.errors2}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockRegisterError3}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockRegisterError3"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.registerErrorPassword"
        errorField={errorField}
        requiredValue={true}
        tooltipImage={images.errors3}
      />
    </>
  );
};

export default registrationErrors;
