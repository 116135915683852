import React, { useState, useEffect } from 'react';
import ContentContainer from '../../components/ContentContainer/ContentContainer';

import { API, API_ROUTER } from "../../api/index";
// import { FormattedMessage } from "react-intl";
// import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';


import s from '../../comon/ComonAdmin.module.scss';
//обнуляющие стили
import "../../comon/_null_style.css";

//компоненты, из которых состоит страница
import ProfilePanel from '../../components/AdminComponents/ProfilePanelComponent/index';
import HeaderAdmin from '../../components/AdminComponents/header-admin';
import Item from '../../components/AdminComponents/itemComponent/itemComponent';
// import InfoPanel from '../../components/AdminComponents/InfoPanel/index';
import Paginator from '../../comon/Paginator';
import { addSpinner, removeSpinner } from "../../redux/actions";

//const
import { pageInfo, btnEvensObj } from "./const";
import { profileInfo, pageMenu, setPaginatorDate } from "../../comon/comonConst";
import {FormattedMessage, useIntl} from "react-intl";
import {DateInput} from "../../components/UI";
import moment from "moment";
import {isHOGDomain} from "../../comon/isHOGDomain";

const styles = {
   control: (css) => ({ ...css, backgroundColor: 'transparent' }),
   menu: (css) => ({ ...css, backgroundColor: isHOGDomain() ? '#000' : '#3F317C' }),
   singleValue: (css) => ({
      ...css, color: 'white'
   }),
   input: (css) => ({ ...css, color: 'white' }),
   option: (styles, { data }) => { return { ...styles, backgroundColor: 'transparent', marginLeft: data.margin } },
};

const EvensPage = (props) => {

   const limit = 10;
   const [itemArray, setItemArray] = useState([]);
   const [pages, setPages] = useState([]);
   const [pagesCount, setPagesCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectedCommunity, setSelectedCommunity] = useState(null);
   const [communities, setCommunities] = useState([])

   const [eventNameFilter, setEventNameFilter] = useState();
   const [eventDateStartFilter, setEventDateStartFilter] = useState('');

   const userData = useSelector(state => state.userData)
   const dispatch = useDispatch()

   const getEvents = (page = 1, limit = 10) => {
      dispatch(addSpinner())

      const urlParams = {};
      if (eventNameFilter) {
         urlParams['name'] = eventNameFilter;
      }

      if (eventDateStartFilter) {
         urlParams["startedFromDate"] = moment(eventDateStartFilter).format('YYYY-MM-DD');
      }

      let params = {
         ...API_ROUTER.events.getEvents,
         pathKeys: {
            page: page,
            limit: limit,
         },
         urlParams
      };

      API.request(params)
         .then((res) => {
            setItemArray(res.events);

            setPaginatorDate(
               res.paginator.lastPage,
               res.paginator.currentPage,
               setPagesCount,
               setPages,
               setCurrentPage
            );
            dispatch(removeSpinner())

         })
         .catch((err) => {
            console.log(err)
            dispatch(removeSpinner())
         });
   }

   useEffect(() => {
      getEvents(sessionStorage.getItem('eventPage') || 1, limit);
   }, [eventDateStartFilter, eventNameFilter]);

   const getCommunityEvents = (page = 1, limit = 10, communityId) => {
      dispatch(addSpinner())
      const urlParams = {};
      if (eventNameFilter) {
         urlParams['name'] = eventNameFilter;
      }

      if (eventDateStartFilter) {
         urlParams["startedFromDate"] = eventDateStartFilter;
      }

      let params = {
         ...API_ROUTER.community.getCommunityEvents,
         pathKeys: {
            page: page,
            limit: limit,
            communityId: communityId
         }
      };

      API.request(params)
         .then((res) => {
            setItemArray(res.events);

            setPaginatorDate(
               res.paginator.lastPage,
               res.paginator.currentPage,
               setPagesCount,
               setPages,
               setCurrentPage
            );
            dispatch(removeSpinner())

         })
         .catch((err) => {
            console.log(err)
            dispatch(removeSpinner())
         });
   }


   useEffect(() => {
      if (selectedCommunity?.communityId) {
         getCommunityEvents(sessionStorage.getItem('eventPage') || 1, limit, selectedCommunity?.communityId)
      }

   }, [selectedCommunity?.communityId])

   useEffect(() => {
      if (userData?.id) {
         const params = {
            ...API_ROUTER.userAdmin.getAccessRights,
            pathKeys: {
               userId: userData.id
            }
         }
         API.request(params)
            .then((res) => {
               setCommunities(res.communities)
            })
            .catch((err) => {
               console.log(err)
            });
      }

   }, [userData?.id])

   return (
      <div className={s.evensPageContainer}>
         <ProfilePanel
            pageInfo={pageInfo}
            profileInfo={profileInfo}
         />
         <ContentContainer
            MainBlock={true}
            MainCreateBlock={null}
         >
            <HeaderAdmin
               pageName={pageInfo.pageName}
               toROUTER={pageInfo.toROUTER}
               addBtn={pageInfo.addBtn}
               // periodContainer={pageInfo.periodContainer}
               btnInfo={pageInfo?.btnInfo}
               // pageMenu={pageMenu}
               // filter={pageInfo.filter}
               select='event'
               setSelect={setSelectedCommunity}
               selectOptions={communities}
            />
            <div className={s.row} style={{display: selectedCommunity?.communityId ? "none" : "flex"}}>
               <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                     <FormattedMessage id="community.search.byName" />
                  </div>
                  <input
                      type="text"
                      placeholder={useIntl().formatMessage({id: "global.forms.labels.name"})}
                      value={eventNameFilter}
                      onChange={(e) => setEventNameFilter(e.target.value)}
                  />
               </div>
               <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                     <FormattedMessage id="search.ByDateStart" />
                  </div>
                  <DateInput selected={eventDateStartFilter} onChange={setEventDateStartFilter} />
                  {/*<input*/}
                  {/*    type="date"*/}
                  {/*    value={eventDateStartFilter}*/}
                  {/*    onChange={(e) => setEventDateStartFilter(e.target.value)}*/}
                  {/*/>*/}
               </div>
            </div>


            <div className={s.mainScrollContainer}>
               <div className={s.scrollContainer}>
                  {itemArray?.map((u, i) =>
                     <Item
                        key={i}
                        id={u.id}
                        name={u.name}
                        status={true}
                        startedAt={u.startedAt}
                        endedAt={u.endedAt}
                        btnObj={btnEvensObj}
                        hiddenBtn={btnEvensObj.hiddenBtn}
                        domain={u.domain}
                        canEdit={u.startDate}
                     />
                  )}
               </div>
               {!!pagesCount &&  (
                   <Paginator
                       limit={limit}
                       pages={pages}
                       getFn={selectedCommunity?.communityId ? getCommunityEvents : getEvents}
                       pagesCount={pagesCount}
                       currentPage={currentPage}
                       component='eventPage'
                   />
               )}
            </div>
            {!itemArray.length && (
                <div style={{ marginBottom: 200, marginTop: 60 }}>
                   <p
                       style={{
                         textAlign: 'center',
                         fontWeight: 500,
                         fontSize: 20
                      }}
                   >
                     No events belong to this community
                   </p>
                </div>
            )}
         </ContentContainer>
      </div>

   )
}
export default EvensPage;
