import { toast } from "react-toastify";

import { API, API_ROUTER } from "../../../../api/index";
import { ROUTER } from "../../../../config";
import checkErrors from "./errors";
const REQUIRED_LANGUIGE_NAME = "EN";
const handleSubmit = (
  editId,
  selectLanguages,
  formFields,
  landingData,
  photoArrSponsorlogo,
  photoArrOrganizerlogo,
  photoArrPartnerlogo,
  uploadData,
  goToUrl,
  dispatch,
  showNotificationModal,
  addSpinner,
  removeSpinner,
  ServerAnswerSuccess,
  domainSelection,
  setErrors,
  errors,
  setErrorField,
  scrollToErrorField
) => {
  const makeArray = (totalLang, requiredLanguageValue, param) => {
    let result = [
      {
        [totalLang]: requiredLanguageValue,
      },
    ];

    selectLanguages.forEach((el) => {
      result = [...result, { [el.name]: el[param] }];
    });
    return result;
  };

  let languages = ["en"];
  for (let key in selectLanguages) {
    languages.push(selectLanguages[key].name.toLowerCase());
  }

  const data = {
    textTitle: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textTitle,
      "textTitle"
    ),
    textDescription: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textDescription,
      "textDescription"
    ),
    country: formFields?.country?.value,
    domain: formFields?.domain,
    domainSelection: domainSelection || "free",
    landingType: "custom",
    embedStream: "",
    languages: languages,
    // added main landing language
    mainLandingLang: formFields?.mainLandingLang?.name || "EN",
    mainColor: "",
    textBlockRegisterError1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegisterError1,
      "textBlockRegisterError1"
    ),
    textBlockRegisterError2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegisterError2,
      "textBlockRegisterError2"
    ),
    textBlockRegisterError3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegisterError3,
      "textBlockRegisterError3"
    ),
    // textBlockSuccessMsgLink1: makeArray(REQUIRED_LANGUIGE_NAME, requiredLanguageValueSuccessMsgLink1, 'textBlockSuccessMsgLink1'),
    // textBlockSuccessMsgLink2: makeArray(REQUIRED_LANGUIGE_NAME, requiredLanguageValueSuccessMsgLink2, 'textBlockSuccessMsgLink2'),
    // textBlockSuccessMsgLink3: makeArray(REQUIRED_LANGUIGE_NAME, requiredLanguageValueSuccessMsgLink3, 'textBlockSuccessMsgLink3'),
    textBlockSuccessMsg: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockSuccessMsg,
      "textBlockSuccessMsg"
    ),
    textBlockTitle1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockTitle1,
      "textBlockTitle1"
    ),
    textBlockTitle2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockTitle2,
      "textBlockTitle2"
    ),
    textBlockTitle3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockTitle3,
      "textBlockTitle3"
    ),
    textBlockTitle4: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockTitle4,
      "textBlockTitle4"
    ),
    textBlockContent1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockContent1,
      "textBlockContent1"
    ),
    textBlockContent2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockContent2,
      "textBlockContent2"
    ),
    textBlockAbout1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout1,
      "textBlockAbout1"
    ),
    textBlockAbout2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout2,
      "textBlockAbout2"
    ),
    textBlockAbout3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout3,
      "textBlockAbout3"
    ),
    textBlockAbout4: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout4,
      "textBlockAbout4"
    ),
    textBlockAbout5: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout5,
      "textBlockAbout5"
    ),
    textBlockAbout6: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout6,
      "textBlockAbout6"
    ),
    textBlockAbout7: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout7,
      "textBlockAbout7"
    ),
    textBlockAbout8: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout8,
      "textBlockAbout8"
    ),
    textBlockAbout9: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout9,
      "textBlockAbout9"
    ),
    textBlockAbout10: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout10,
      "textBlockAbout10"
    ),
    textBlockAbout11: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout11,
      "textBlockAbout11"
    ),
    textBlockAbout12: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout12,
      "textBlockAbout12"
    ),
    textBlockAbout13: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout13,
      "textBlockAbout13"
    ),
    textBlockAbout14: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout14,
      "textBlockAbout14"
    ),
    textBlockAbout15: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout15,
      "textBlockAbout15"
    ),
    textBlockAbout16: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout16,
      "textBlockAbout16"
    ),
    textBlockAbout17: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout17,
      "textBlockAbout17"
    ),
    textBlockAbout18: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout18,
      "textBlockAbout18"
    ),
    textBlockAbout19: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout19,
      "textBlockAbout19"
    ),
    textBlockAbout20: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout20,
      "textBlockAbout20"
    ),
    textBlockAbout21: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout21,
      "textBlockAbout21"
    ),
    textBlockAbout22: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout22,
      "textBlockAbout22"
    ),
    textBlockAbout23: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout23,
      "textBlockAbout23"
    ),
    textBlockAbout24: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout24,
      "textBlockAbout24"
    ),
    textBlockAbout25: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout25,
      "textBlockAbout25"
    ),
    textBlockAbout26: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout26,
      "textBlockAbout26"
    ),
    textBlockAbout27: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout27,
      "textBlockAbout27"
    ),
    textBlockAbout28: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout28,
      "textBlockAbout28"
    ),
    textBlockAbout29: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout29,
      "textBlockAbout29"
    ),
    textBlockAbout30: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockAbout30,
      "textBlockAbout30"
    ),
    textBlockOrganizer1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockOrganizer1 || "",
      "textBlockOrganizer1"
    ),
    textBlockOrganizer2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockOrganizer2 || "",
      "textBlockOrganizer2"
    ),
    textBlockOrganizer3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockOrganizer3 || "",
      "textBlockOrganizer3"
    ),
    textBlockButtons1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockButtons1,
      "textBlockButtons1"
    ),
    textBlockButtons2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockButtons2,
      "textBlockButtons2"
    ),
    textBlockButtons3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockButtons3,
      "textBlockButtons3"
    ),
    textBlockButtons4: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockButtons4,
      "textBlockButtons4"
    ),
    textBlockButtons5: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockButtons5,
      "textBlockButtons5"
    ),
    textBlockRegistration1: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration1,
      "textBlockRegistration1"
    ),
    textBlockRegistration2: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration2,
      "textBlockRegistration2"
    ),
    textBlockRegistration3: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration3,
      "textBlockRegistration3"
    ),
    textBlockRegistration4: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration4,
      "textBlockRegistration4"
    ),
    textBlockRegistration5: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration5,
      "textBlockRegistration5"
    ),
    textBlockRegistration6: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration6,
      "textBlockRegistration6"
    ),
    textBlockRegistration7: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration7,
      "textBlockRegistration7"
    ),
    textBlockRegistration8: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration8,
      "textBlockRegistration8"
    ),
    textBlockRegistration9: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration9,
      "textBlockRegistration9"
    ),
    textBlockRegistration10: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration10,
      "textBlockRegistration10"
    ),
    textBlockRegistration11: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration11,
      "textBlockRegistration11"
    ),
    textBlockRegistration12: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration12,
      "textBlockRegistration12"
    ),
    textBlockRegistration13: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration13,
      "textBlockRegistration13"
    ),
    textBlockRegistration14: makeArray(
      REQUIRED_LANGUIGE_NAME,
      formFields?.textBlockRegistration14,
      "textBlockRegistration14"
    ),
  };
  // dispatch(addSpinner());
  if (
    checkErrors(
      formFields,
      setErrors,
      errors,
      selectLanguages,
      setErrorField
    ) === true
  ) {
    API.request(
      !editId
        ? { ...API_ROUTER.landing.createLanding, data }
        : {
            ...API_ROUTER.landing.upDataLanding,
            pathKeys: {
              landingId: editId,
            },
            data,
          }
    )
      .then((res) => {
        if (formFields?.landingPageHeaderBanner.length > 0 && !editId) {
          uploadData(
            formFields?.landingPageHeaderBanner,
            res.id,
            "landingPageHeaderBanner"
          );
        } else if (formFields?.landingPageHeaderBanner.length > 0 && editId) {
          if (
            landingData.landingPageHeaderBanner !==
            formFields?.landingPageHeaderBanner
          ) {
            uploadData(
              formFields?.landingPageHeaderBanner,
              res.id,
              "landingPageHeaderBanner"
            );
          }
        }
        if (formFields?.landingFavicon.length > 0 && !editId) {
          uploadData(formFields?.landingFavicon, res.id, "landingFavicon");
        } else if (formFields?.landingFavicon.length > 0 && editId) {
          if (landingData.landingFavicon !== formFields?.landingFavicon) {
            uploadData(formFields?.landingFavicon, res.id, "landingFavicon");
          }
        }
        if (formFields?.landingFlag.length > 0 && !editId) {
          uploadData(formFields?.landingFlag, res.id, "landingFlag");
        } else if (formFields?.landingFlag.length > 0 && editId) {
          if (landingData.landingFlag !== formFields?.landingFlag) {
            uploadData(formFields?.landingFlag, res.id, "landingFlag");
          }
        }
        if (formFields?.landingBanner.length > 0 && !editId) {
          uploadData(formFields?.landingBanner, res.id, "landingBanner");
        } else if (formFields?.landingBanner.length > 0 && editId) {
          if (landingData.landingBanner !== formFields?.landingBanner) {
            uploadData(formFields?.landingBanner, res.id, "landingBanner");
          }
        }
        if (formFields?.landingLogo.length > 0 && !editId) {
          uploadData(formFields?.landingLogo, res.id, "landingLogo");
        } else if (formFields?.landingLogo.length > 0 && editId) {
          if (landingData.landingLogo !== formFields?.landingLogo) {
            uploadData(formFields?.landingLogo, res.id, "landingLogo");
          }
        }
        setTimeout(() => {
          if (formFields?.landingGameLogo != "1" && !editId) {
            formFields?.landingGameLogo.map((el) =>
              uploadData(el, res.id, "landingGameLogo")
            );
          } else if (formFields?.landingGameLogo != "1" && editId) {
            filterImages(
              formFields?.landingGameLogo,
              landingData.landingGameLogo
            ).map((el) => uploadData(el, res.id, "landingGameLogo"));
          }
        }, 1000);
        function filterImages(images, backendImages) {
          const newArr = images.filter((item) => {
            return !backendImages.includes(item);
          });
          return newArr;
        }
        setTimeout(() => {
          if (photoArrOrganizerlogo != "1" && !editId) {
            photoArrOrganizerlogo.map((el) =>
              uploadData(el, res.id, "landingOrganizePartnerLogo")
            );
          } else if (photoArrOrganizerlogo != "1" && editId) {
            filterImages(
              photoArrOrganizerlogo,
              landingData.landingOrganizePartnerLogo
            ).map((el) => uploadData(el, res.id, "landingOrganizePartnerLogo"));
          }
        }, 2000);
        setTimeout(() => {
          if (photoArrSponsorlogo != "1" && !editId) {
            photoArrSponsorlogo.map((el) =>
              uploadData(el, res.id, "landingSponsorLogo")
            );
          } else if (photoArrSponsorlogo != "1" && editId) {
            const filteredImages = filterImages(
              photoArrSponsorlogo,
              landingData.landingSponsorLogo
            );
            filteredImages.map((el) =>
              uploadData(el, res.id, "landingSponsorLogo")
            );
          }
        }, 3000);
        setTimeout(() => {
          if (photoArrPartnerlogo != "1" && !editId) {
            photoArrPartnerlogo.map((el) =>
              uploadData(el, res.id, "landingPartnerLogo")
            );
          } else if (photoArrPartnerlogo != "1" && editId) {
            filterImages(
              photoArrPartnerlogo,
              landingData.landingPartnerLogo
            ).map((el) => uploadData(el, res.id, "landingPartnerLogo"));
          }
        }, 4000);
        setTimeout(() => {
          goToUrl(ROUTER.landings);
          dispatch(showNotificationModal(ServerAnswerSuccess));
          dispatch(removeSpinner());
        }, 5000);
      })
      .catch((err) => {
        dispatch(removeSpinner());
        const errors = [];
        for (let key in err.data.errors) {
          errors.push(`${key}: ${err.data.errors[key]}`);
        }
        dispatch(
          showNotificationModal(`Some errors occured: ${errors.join(", ")}`)
        );
      });
  } else {
    dispatch(removeSpinner());

    scrollToErrorField();
    toast.error("Fill all purple required fields");
  }
};

export default handleSubmit;
