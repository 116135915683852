import s from "../createLanding.module.scss";
import React from "react";
import { FormattedMessage } from "react-intl";

import TooltipImg from "./tooltip";

import { isHOGDomain } from "../../../../comon/isHOGDomain";

const InputComponent = React.forwardRef(
  (
    {
      // Элемент основного языка
      element,
      // Функция обработчик изменения элемента основного языка
      inputHandler,
      // Массив языков
      selectLanguages,
      // Элемент языка в виде строки
      selectLanguagesElement,
      // Функция обработчик изменения элемента языка
      changeLanguagesValue,
      // id для форматированного сообщения
      formattedMessageInputId,
      errorField,
      requiredValue,
      tooltipImage,
      tooltipPosition,
    },
    ref
  ) => {
    const validationEmpty = (element) => {
      if (element?.length < 1) {
        return `${s.inputErr}`;
      }
    };
    const textAreaElements = () => {
      if (
        selectLanguagesElement === "textBlockRegistration7" ||
        selectLanguagesElement === "textBlockRegistration12" ||
        selectLanguagesElement === "textBlockRegisterError1" ||
        selectLanguagesElement === "textBlockRegisterError2" ||
        selectLanguagesElement === "textBlockRegisterError3" ||
        selectLanguagesElement === "textBlockSuccessMsg"
      ) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <>
        {/* Основной блок текста */}

        <div
          className={s.languageContainer}
          style={{ marginTop: 15 }}
          ref={errorField === selectLanguagesElement ? ref : null}
        >
          <div>
            <div className={s.inputContainer}>
              <div className={s.titleInput}>
                <FormattedMessage
                  id={`${formattedMessageInputId}`}
                  tagName="span"
                />{" "}
                {" " + "EN*"}
              </div>
              {/* Исключения для textarea в блоких регистрации  */}

              {textAreaElements() ? (
                <textarea
                  style={{ width: 300, fontSize: 16 }}
                  className={validationEmpty(element)}
                  rows={5}
                  cols={30}
                  type="text"
                  value={element}
                  // НЕ ОБРАБАТЫВАТЬ ОСНОВНОЙ ЯЗЫК
                  onChange={(e) => !requiredValue && inputHandler(e)}
                  name={selectLanguagesElement}
                />
              ) : (
                <input
                  type="text"
                  value={element}
                  className={validationEmpty(element)}
                  // НЕ ОБРАБАТЫВАТЬ ОСНОВНОЙ ЯЗЫК

                  onChange={(e) => !requiredValue && inputHandler(e)}
                  name={selectLanguagesElement}
                />
              )}
              <TooltipImg image={tooltipImage} position={tooltipPosition} />
            </div>
          </div>

          {/* Цикл по selectLanguages в поисках selectLanguagesElement */}

          {selectLanguages?.map((el, i) => (
            <div key={i}>
              <div className={s.inputContainer}>
                <div className={s.titleInput}>
                  <FormattedMessage
                    id={`${formattedMessageInputId}`}
                    tagName="span"
                  />{" "}
                  {" " + el.name + "*"}
                </div>

                {/* Исключения для textarea в блоких регистрации  */}

                {textAreaElements() ? (
                  <textarea
                    style={{ width: 300, fontSize: 16 }}
                    className={validationEmpty(el[selectLanguagesElement])}
                    rows={5}
                    cols={30}
                    type="text"
                    defaultValue={el[selectLanguagesElement]}
                    onChange={(e) =>
                      changeLanguagesValue(
                        e.target,
                        el.id,
                        selectLanguagesElement
                      )
                    }
                    name={selectLanguagesElement}
                  />
                ) : (
                  <input
                    type="text"
                    className={validationEmpty(el[selectLanguagesElement])}
                    defaultValue={el[selectLanguagesElement]}
                    onChange={(e) =>
                      changeLanguagesValue(
                        e.target,
                        el.id,
                        selectLanguagesElement
                      )
                    }
                    name={selectLanguagesElement}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
);

export default InputComponent;
