export const constformFields = (
  landingData,
  setValueToSelect,
  getlanguageFromValue,
  getCountryFromValue
) => {
  return {
    ...landingData,
    country: landingData?.country
      ? getCountryFromValue(landingData.country)
      : "",
    countryFlag: landingData?.countryFlag || "",
    domain: landingData?.domain || "",
    domainSelection: landingData?.domainSelection || "",
    embedStream: landingData?.embedStream || "",
    id: landingData?.id || "",
    landingBanner: landingData?.landingBanner || "",
    landingFavicon: landingData?.landingFavicon || "",
    landingFlag: landingData?.landingFlag || "",
    landingGameLogo: landingData?.landingGameLogo || ["1"],
    landingImage: landingData?.landingImage || "",
    landingLogo: landingData?.landingLogo || "",
    landingOrganizePartnerLogo: landingData?.landingOrganizePartnerLogo || [
      "1",
    ],
    landingPartnerLogo: landingData?.landingPartnerLogo || ["1"],
    landingSponsorLogo: landingData?.landingSponsorLogo || ["1"],
    landingType: setValueToSelect(landingData?.landingType),
    landingPageHeaderBanner: landingData?.landingPageHeaderBanner || "",
    languages: landingData?.languages
      ? getlanguageFromValue(landingData?.languages)
      : [],
    mainLandingLang:
      getlanguageFromValue(landingData?.mainLandingLang?.toLowerCase()) || [],
    textBlockAbout1: landingData?.textBlockAbout1
      ? landingData?.textBlockAbout1[0].EN
      : "",
    textBlockAbout2: landingData?.textBlockAbout2
      ? landingData?.textBlockAbout2[0].EN
      : "",
    textBlockAbout3: landingData?.textBlockAbout3
      ? landingData?.textBlockAbout3[0].EN
      : "",
    textBlockAbout4: landingData?.textBlockAbout4
      ? landingData?.textBlockAbout4[0].EN
      : "",
    textBlockAbout5: landingData?.textBlockAbout5
      ? landingData?.textBlockAbout5[0].EN
      : "",
    textBlockAbout6: landingData?.textBlockAbout6
      ? landingData?.textBlockAbout6[0].EN
      : "",
    textBlockAbout7: landingData?.textBlockAbout7
      ? landingData?.textBlockAbout7[0].EN
      : "",
    textBlockAbout8: landingData?.textBlockAbout8
      ? landingData?.textBlockAbout8[0].EN
      : "",
    textBlockAbout9: landingData?.textBlockAbout9
      ? landingData?.textBlockAbout9[0].EN
      : "",
    textBlockAbout10: landingData?.textBlockAbout10
      ? landingData?.textBlockAbout10[0].EN
      : "",
    textBlockAbout11: landingData?.textBlockAbout11
      ? landingData?.textBlockAbout11[0].EN
      : "",
    textBlockAbout12: landingData?.textBlockAbout12
      ? landingData?.textBlockAbout12[0].EN
      : "",
    textBlockAbout13: landingData?.textBlockAbout13
      ? landingData?.textBlockAbout13[0].EN
      : "",
    textBlockAbout14: landingData?.textBlockAbout14
      ? landingData?.textBlockAbout14[0].EN
      : "",
    textBlockAbout15: landingData?.textBlockAbout15
      ? landingData?.textBlockAbout15[0].EN
      : "",
    textBlockAbout16: landingData?.textBlockAbout16
      ? landingData?.textBlockAbout16[0].EN
      : "",
    textBlockAbout17: landingData?.textBlockAbout17
      ? landingData?.textBlockAbout17[0].EN
      : "",
    textBlockAbout18: landingData?.textBlockAbout18
      ? landingData?.textBlockAbout18[0].EN
      : "",
    textBlockAbout19: landingData?.textBlockAbout19
      ? landingData?.textBlockAbout19[0].EN
      : "",
    textBlockAbout20: landingData?.textBlockAbout20
      ? landingData?.textBlockAbout20[0].EN
      : "",
    textBlockAbout21: landingData?.textBlockAbout21
      ? landingData?.textBlockAbout21[0].EN
      : "",
    textBlockAbout22: landingData?.textBlockAbout22
      ? landingData?.textBlockAbout22[0].EN
      : "",
    textBlockAbout23: landingData?.textBlockAbout23
      ? landingData?.textBlockAbout23[0].EN
      : "",
    textBlockAbout24: landingData?.textBlockAbout24
      ? landingData?.textBlockAbout24[0].EN
      : "",
    textBlockAbout25: landingData?.textBlockAbout25
      ? landingData?.textBlockAbout25[0].EN
      : "",
    textBlockAbout26: landingData?.textBlockAbout26
      ? landingData?.textBlockAbout26[0].EN
      : "",
    textBlockAbout27: landingData?.textBlockAbout27
      ? landingData?.textBlockAbout27[0].EN
      : "",
    textBlockAbout28: landingData?.textBlockAbout28
      ? landingData?.textBlockAbout28[0].EN
      : "",
    textBlockAbout29: landingData?.textBlockAbout29
      ? landingData?.textBlockAbout29[0].EN
      : "",
    textBlockAbout30: landingData?.textBlockAbout30
      ? landingData?.textBlockAbout30[0].EN
      : "",
    textBlockButtons1: landingData?.textBlockButtons1
      ? landingData?.textBlockButtons1[0].EN
      : "",
    textBlockButtons2: landingData?.textBlockButtons2
      ? landingData?.textBlockButtons2[0].EN
      : "",
    textBlockButtons3: landingData?.textBlockButtons3
      ? landingData?.textBlockButtons3[0].EN
      : "",
    textBlockButtons4: landingData?.textBlockButtons4
      ? landingData?.textBlockButtons4[0].EN
      : "",
    textBlockButtons5: landingData?.textBlockButtons5
      ? landingData?.textBlockButtons5[0].EN
      : "",
    textBlockContent1: landingData?.textBlockContent1
      ? landingData?.textBlockContent1[0].EN
      : "",
    textBlockContent2: landingData?.textBlockContent2
      ? landingData?.textBlockContent2[0].EN
      : "",
    textBlockOrganizer1: landingData?.textBlockOrganizer1
      ? landingData?.textBlockOrganizer1[0].EN
      : "",
    textBlockOrganizer2: landingData?.textBlockOrganizer2
      ? landingData?.textBlockOrganizer2[0].EN
      : "",
    textBlockOrganizer3: landingData?.textBlockOrganizer3
      ? landingData?.textBlockOrganizer3[0].EN
      : "",
    textBlockRegisterError1: landingData?.textBlockRegisterError1
      ? "Name should be at least 2 characters"
      : "Name should be at least 2 characters",
    textBlockRegisterError2: landingData?.textBlockRegisterError2
      ? "Incorrect email"
      : "Incorrect email",
    textBlockRegisterError3: landingData?.textBlockRegisterError3
      ? "Password should be at least 8 characters"
      : "Password should be at least 8 characters",
    textBlockRegistration1: landingData?.textBlockRegistration1
      ? "Registration"
      : "Registration",
    textBlockRegistration2: landingData?.textBlockRegistration2
      ? "Enter your details to continue"
      : "Enter your details to continue",
    textBlockRegistration3: landingData?.textBlockRegistration3
      ? "Full name (real name)"
      : "Full name (real name)",
    textBlockRegistration4: landingData?.textBlockRegistration4
      ? "Email"
      : "Email",
    textBlockRegistration5: landingData?.textBlockRegistration5
      ? "Country"
      : "Country",
    textBlockRegistration6: landingData?.textBlockRegistration6
      ? "Password"
      : "Password",
    textBlockRegistration7: landingData?.textBlockRegistration7
      ? "I certify that I am 12 years of age or older. I have read and agree with the Terms of Use , Privacy Policy, Cookie Policy and Rulebook."
      : "I certify that I am 12 years of age or older. I have read and agree with the Terms of Use , Privacy Policy, Cookie Policy and Rulebook.",
    textBlockRegistration8: landingData?.textBlockRegistration8
      ? "Terms of use"
      : "Terms of use",
    textBlockRegistration9: landingData?.textBlockRegistration9
      ? "Privacy Policy"
      : "Privacy Policy",
    textBlockRegistration10: landingData?.textBlockRegistration10
      ? "Cookie Policy"
      : "Cookie Policy",
    textBlockRegistration11: landingData?.textBlockRegistration11
      ? "Rulebook"
      : "Rulebook",
    textBlockRegistration12: landingData?.textBlockRegistration12
      ? `I hereby give my consent to Aurora Gaming OÜ to use my email to send me marketing and advertising materials related to Aurora Gaming OÜ and its partners' products and services`
      : `I hereby give my consent to Aurora Gaming OÜ to use my email to send me marketing and advertising materials related to Aurora Gaming OÜ and its partners' products and services`,
    textBlockRegistration13: landingData?.textBlockRegistration13
      ? "Register"
      : "Register",
    textBlockRegistration14: landingData?.textBlockRegistration14
      ? "Support"
      : "Support",
    textBlockSuccessMsg: landingData?.textBlockSuccessMsg
      ? "Thank you for joining the IeSF Big Bang Asia Open! To finish the registration, please confirm your email by pressing button 'Confirm' in welcoming letter. Then login to the Tournament hub, fill in personal your data and register for your favorite game. and Make sure to enter accurate data regarding you (name, age, country) – this information will be verified occasionally, participants that will provide false information, will be disqualified. In case of the team games, if one player will be disqualified, this will affect the whole team.For individual games (Real Cricket, eFootball) select the competition level that corresponds to your skills and register for the competition. For team games (Clash Of Clans, Dota 2 and PUBG Mobile) gather your team (invite friends via the internal form or just share this page) and register the full team to the competition.     Check rulebook, schedule and other information for detail. GLFH"
      : "Thank you for joining the IeSF Big Bang Asia Open! To finish the registration, please confirm your email by pressing button 'Confirm' in welcoming letter. Then login to the Tournament hub, fill in personal your data and register for your favorite game. and Make sure to enter accurate data regarding you (name, age, country) – this information will be verified occasionally, participants that will provide false information, will be disqualified. In case of the team games, if one player will be disqualified, this will affect the whole team.For individual games (Real Cricket, eFootball) select the competition level that corresponds to your skills and register for the competition. For team games (Clash Of Clans, Dota 2 and PUBG Mobile) gather your team (invite friends via the internal form or just share this page) and register the full team to the competition.     Check rulebook, schedule and other information for detail. GLFH",
    textBlockTitle1: landingData?.textBlockTitle1
      ? landingData?.textBlockTitle1[0].EN
      : "",
    textBlockTitle2: landingData?.textBlockTitle2
      ? landingData?.textBlockTitle2[0].EN
      : "",
    textBlockTitle3: landingData?.textBlockTitle3
      ? landingData?.textBlockTitle3[0].EN
      : "",
    textBlockTitle4: landingData?.textBlockTitle4
      ? landingData?.textBlockTitle4[0].EN
      : "",
    textDescription: landingData?.textDescription
      ? landingData?.textDescription[0].EN
      : "",
    textTitle: landingData?.textTitle ? landingData?.textTitle[0].EN : "",
  };
};

/// ошибкив порядке полей в форме
export const initialErrors = {
  textBlockSuccessMsg: "",
  textBlockRegisterError3: "",
  textBlockRegisterError2: "",
  textBlockRegisterError1: "",
  textBlockRegistration14: "",
  textBlockRegistration13: "",
  textBlockRegistration12: "",
  textBlockRegistration11: "",
  textBlockRegistration10: "",
  textBlockRegistration9: "",
  textBlockRegistration8: "",
  textBlockRegistration7: "",
  textBlockRegistration6: "",
  textBlockRegistration5: "",
  textBlockRegistration4: "",
  textBlockRegistration3: "",
  textBlockRegistration2: "",
  textBlockRegistration1: "",
  textBlockButtons5: "",
  textBlockButtons4: "",
  textBlockButtons3: "",
  textBlockButtons2: "",
  textBlockButtons1: "",
  textBlockAbout25: "",
  textBlockAbout24: "",
  textBlockAbout23: "",
  textBlockAbout22: "",
  textBlockAbout21: "",
  textBlockAbout20: "",
  textBlockAbout19: "",
  textBlockAbout18: "",
  textBlockAbout17: "",
  textBlockAbout16: "",
  textBlockAbout15: "",
  textBlockAbout14: "",
  textBlockAbout13: "",
  textBlockAbout12: "",
  textBlockAbout11: "",
  textBlockAbout10: "",
  textBlockAbout9: "",
  textBlockAbout8: "",
  textBlockAbout7: "",
  textBlockAbout6: "",
  textBlockAbout5: "",
  textBlockAbout4: "",
  textBlockAbout3: "",
  textBlockAbout2: "",
  textBlockAbout1: "",
  textBlockContent2: "",
  textBlockContent1: "",
  domain: "",
  country: "",
  // domainSelection: "",
  // embedStream: "",
  // landingBanner: "",
  // landingFavicon: "",
  // landingGameLogo: [],
  // landingImage: "",
  // landingLogo: "",
  // landingOrganizePartnerLogo: [],
  // landingPartnerLogo: [],
  // landingSponsorLogo: [],
  // landingType: "",
  // languages: [],
  // mainLandingLang: "",
  // textBlockOrganizer1: "",
  // textBlockOrganizer2: "",
  // textBlockOrganizer3: "",
  // textBlockSuccessMsgLink1: "",
  // textBlockSuccessMsgLink2: "",
  // textBlockSuccessMsgLink3: "",
  // textBlockTitle1: "",
  // textBlockTitle2: "",
  // textBlockTitle3: "",
  // textBlockTitle4: "",
  // textDescription: "",
  // textTitle: "",
};

export const MAX_LANGUAGES_COUND = 3;

export const LANDING_TYPES = [
  // { id: 1, title: 'community' },
  // { id: 2, title: 'event' },
  // { id: 3, title: 'tournament' },
  { id: 4, title: "custom" },
];
export const REQUIRED_LANGUIGE_NAME = "EN";

export const STREAM = [
  { id: 1, title: "Yes" },
  { id: 2, title: "No" },
];

export const FLAG = [
  { id: 1, title: "Yes" },
  { id: 2, title: "No" },
];
